.help-search-section{background-image: url(../../../../public/assets/img/helpsearch.jpg);padding: 80px 0;position: relative;margin: 80px 0 0;}
.help-search-section:before{    content: '';position: absolute;width: 100%;height: 100%;background: rgb(251 231 211 / 95%);top: 0;z-index: 0;}
.help-search-section .help-search{text-align: center;position: relative;}
.help-search-section .help-search .text{margin-bottom: 35px;font-size: 38px;font-weight: 500;text-transform: uppercase;}
.search-section{}
.help-search .search-input {width: 50%;display: flex;align-items: center;background: var(--shades01);border-radius: 10px;height: 55px;padding: 0 16px;justify-content: center;margin: 0 auto;}
.help-search .search-input .form-control {border: none;background: none;}
.help-search .search-input .icon {color: var(--color03);font-size: 20px;}

.help-feature-section{padding: 100px 0;background: var(--shades05);}
.help-feature-section .feature-list{}
.help-feature-section .feature-list .feature-item{ background: var(--shades01);margin:0 25px;box-shadow: 0px 4px 22px rgba(57, 57, 57, 0.18);border-radius: 15px;height: 100%;padding: 30px;}
.help-feature-section .feature-list .feature-item .feature-inner{display: flex;flex-direction: column;justify-content: center;align-items: center;text-align: center;transition: all 0.3s ease;}
.help-feature-section .feature-list .feature-item .feature-content{height: 100%;}
.help-feature-section .feature-list .feature-item:hover{background: var(--color01);cursor: pointer;}
.feature-item .icon{width: 120px;height: 120px;margin-bottom: 20px;overflow: hidden;color: var(--color03);font-size: 120px;}
.feature-item .icon img{width: 100%;height: 100%;}
.help-feature-section  .swiper-slide {text-align: center;font-size: 18px;height: unset !important;margin-top: 25px!important;margin-bottom: 20px;align-items: center;}
.help-feature-section  .swiper-grid-column>.swiper-wrapper{flex-direction: unset;}
.feature-item .feature-content .title{text-transform: capitalize;margin-bottom: 10px;font-size: 26px;font-weight: 500;}
.feature-item .feature-content .description{font-size: 16px;line-height: 1.4;font-weight: 400;}

.location-section{padding:100px 0}
.location-section .location-title{font-family:'Playfair Display',serif;color:var(--shades02);font-size:64px;font-weight:700;margin:0;line-height:1.5;margin:0}
.location-section .location-title span{color:var(--color02);text-transform:capitalize}

.location-section .location-part{position:relative;margin-top: 60px;}
.location-section .location-part .location-map{width:100%;height:900px;box-shadow:0 4px 10px 0 rgba(58,58,58,0.18);overflow:hidden;border-radius:30px}
.location-section .location-part .location-map img{width:100%;height:100%}
.location-section .location-part .location-details{position:absolute;top:-18px;right:38px;background:var(--color03);padding:30px;border-radius:10px}
.location-section .location-part .location-details .location-box{background:var(--color01);padding:20px;border-radius:10px}
.location-details .location-box .current-location{display:flex;align-items:center}
.location-details .location-box .current-location .icon{width:16px;background:var(--shades01);position:relative;height:16px;box-shadow:0 4px 4px 0 rgba(0,0,0,0.25);border-radius:50%;margin-right:10px}
.location-details .location-box .current-location .icon span{width:10px;background:var(--color03);height:10px;border-radius:50%;position:absolute;top:50%;transform:translateY(-50%);left:20%}
.location-details .location-box .current-location .text{font-size:18px;font-weight:500}
.location-details .location-box .current-location .cancel-btn{background:none;margin-left:auto}
.location-details .location-box .current-location .cancel-btn i{font-weight:500}
.location-details .location-box .main-location{display:flex;padding-top:10PX;align-items:center;margin-top:10px;position:relative}
.location-details .location-box .main-location:before{content:'';width:90%;position:absolute;height:1px;background:var(--shades07);top:0;right:0}
.location-details .location-box .main-location .icon{width:16px;background:var(--shades01);position:relative;height:16px;box-shadow:0 4px 4px 0 rgba(0,0,0,0.25);border-radius:50%;margin-right:10px}
.location-details .location-box .main-location .icon:before{content: '';position: absolute;width: 1px;height: 14px;background: var(--shades07);top: -18px;left: 7px;}
.location-details .location-box .main-location .icon span{width:10px;background:var(--color03);height:10px;border-radius:50%;position:absolute;top:50%;transform:translateY(-50%);left:20%}
.location-details .location-box .main-location .text{font-size:18px;font-weight:500}
.location-details .location-box .main-location .cancel-btn{background:none;margin-left:auto}
.location-details .location-box .main-location .cancel-btn i{font-weight:500}
.rating-star{position:relative;display:inline-flex;margin-left:3px}
.rating-star .backstar{font-size:16px;color:var(--ratingColor);cursor:default;display:flex;position:relative;border:1px solid transparent}
.rating-star .frontstar{font-size:18px;color:var(--thirdColor);cursor:default;display:flex;position:absolute;width:100%;height:100%;top:0;left:0;overflow:hidden}
.rating-star .frontstar i{font-weight:600;color:var(--ratingColor)}
.review-text{font-size:12px;line-height:14px;font-weight:500;color:var(--textColor)}
.review-text span{color:var(--secondaryColor);font-weight:600;margin-left:12px}
.company-review{padding:35px 0 20px}
.company-review .title{font-size:24px;color:var(--shades01);font-weight:600}
.company-review .sub-title{text-transform:uppercase;letter-spacing:6px;font-size:18px;font-weight:600;color:var(--shades03)}
.company-review .rating{display:flex;align-items:center}
.company-review .rating .rating-num{font-size:18px;font-weight:500;color:var(--shades01);margin-right:5px;line-height:1.2}
.company-review .rating-text{font-size:14px;font-weight:400;color:var(--shades01);margin-left:10px}
.location-search-result{margin-top:15px}
.location-search-result .search-item{display:flex;align-items:center;padding:10px;height:54px;border-radius:10px}
.location-search-result .search-item.active{position:relative;background:var(--color01)}
.location-search-result .search-item+ .search-item{margin-top:15px}
.location-search-result .search-item .icon{font-size:25px;margin-right:10px;color:var(--shades01)}
.location-search-result .search-item .text{font-weight:500;font-size:18px;color:var(--shades01)}
.location-search-result .search-item.active:before{content:'';position:absolute;height:54px;width:10px;background:var(--color01);left:-30px;border-radius:0 10px 10px 0}
.location-search-result .search-item.active .icon{color:var(--color03);font-size:30px;margin-right:10px}
.location-search-result .search-item.active .text{color:var(--shades02);font-weight:500;font-size:18px}

.location-track{display:flex;justify-content:space-between;align-items:center;background:var(--shades01);margin-top:35px;box-shadow:0 4px 17px rgba(0,0,0,0.15);border-radius:50px}
.location-track .logo{width:65px;height:65px;overflow:hidden;padding:10px}
.location-track .logo img{width:100%;height:100%}
.location-track .text{font-size:18px;font-weight:500;text-transform:uppercase}
.location-track .icon{width:45px;height:45px;color:var(--shades01);display:flex;align-items:center;justify-content:center;font-size:22px;transform:rotate(-48deg);border-radius:50%;margin:0 10px;background:linear-gradient(180deg,#F14E2D 0%,#C62606 100%);box-shadow:0 2px 18px rgba(241,78,45,0.6)}
.contact-us-from-section{background:var(--shades05);padding:60px 0;position:relative}
.contact-us-from-section .contact-title{font-family:'Playfair Display',serif;color:var(--shades02);font-size:64px;font-weight:700;margin:0;line-height:1.5;margin:0}
.contact-us-from-section .contact-title span{color:var(--color02);text-transform:capitalize}
.contact-form-part{box-shadow:0 2px 21px rgba(87,87,87,0.13);border-radius:25px;background:var(--color01);margin:30px 30px 30px 0;padding:35px 30px}
.contact-us-from-section:before{content:'';width:2px;height:66%;position:absolute;background:var(--shades06);right:43%;bottom:60px}
.contact-details{padding-left:22px}
.contact-form-part .form-title{font-size:34px;text-transform:capitalize;text-align:center;font-family:'Playfair Display',serif;font-weight:600;line-height:1.4;margin-bottom:32px}
.contact-form-part .form-step{display:flex;align-items:center;justify-content:center;margin:25px 0}
.contact-form-part .form-step .step{width:20%;height:6px;background:var(--shades05);border-radius:10px}
.contact-form-part .form-step .step+ .step{margin-left:15px}
.contact-form-part .form-step .step.active{background:var(--color02)}
.form .form-item{display:none}
.form .form-item.active{display:block}
.form .form-item .form-content .input-style+ .input-style{margin-top:15px}
.form .form-item .form-content .input-style .form-control{display:flex;align-items:center;background:var(--shades01);border-radius:10px;height:50px;border:none;padding:0 16px;justify-content:center;box-shadow:0 4px 7px rgba(0,0,0,0.1);margin:0 auto}
.form .form-action{margin-top:25px}
.form .form-action .btn-submit{background:var(--color03);color:var(--shades01);width:100%;border-radius:10px;height:54px;font-size:18px;font-weight:500}
.form .form-item .form-content .input-style .form-control.message{resize:none;height:145px;padding:12px}
.contact-details .contact-list .contact-item{display:flex;background:var(--color01);box-shadow:0 4px 18px rgba(0,0,0,0.1);align-items:center;padding:15px;border-radius:10px}
.contact-details .contact-list .contact-item+ .contact-item{margin-top:25px}
.contact-details .contact-list .contact-item .icon{font-size:23px;color:var(--shades01);margin-right:18px;width:40px;height:40px;background:var(--color03);display:flex;align-items:center;justify-content:center;border-radius:50%}
.contact-details .contact-list .contact-item .text{font-size:16px;font-weight:500}




@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {
  .location-section .location-part .location-map{height: 675px;}
}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .help-search-section{padding: 40px 0;}
  .help-search-section .help-search .text{font-size: 28px;margin-bottom: 10PX;}
  .help-search .search-input{width: 100%;}
  .help-feature-section{padding: 40px 0;}
  .help-feature-section .feature-list .feature-item{margin: 0 20px;}
  .help-feature-section .swiper-slide{padding-bottom: 35px;}
  .help-feature-section .swiper-button-prev{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.help-feature-section .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.help-feature-section .swiper-button-prev:after{display:none;}
.help-feature-section .swiper-button-next{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.help-feature-section .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.help-feature-section .swiper-button-next:after{display:none;}
.help-feature-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.help-feature-section .swiper-pagination{z-index: 4;}

.faqitem .faq-item-title .text{font-weight: 500;font-size: 15px;line-height: 1.4;}
.faqitem .faq-item-title{padding: 10px 45px 10px 15px;}
.contact-form-part{margin: 15px 0;}
.contact-us-from-section:before{display: none;}
.contact-us-from-section{padding: 40px 0;}
.contact-us-from-section .contact-title{font-size: 44px;text-align: center;}
.location-section .location-title{font-size: 40px;text-align: center}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .help-search-section{padding: 40px 0;background-position: top;margin: 0;margin-top: -50px;z-index: 0;}
  .help-search-section .help-search .text{font-size: 28px;margin-bottom: 10PX;}
  .help-search .search-input{width: 100%;}
  .help-feature-section{padding: 40px 0;}
  .help-feature-section .feature-list .feature-item{margin: 0 20px;}
  .help-feature-section .swiper-slide{padding-bottom: 35px;}
  .help-feature-section .swiper-button-prev{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.help-feature-section .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.help-feature-section .swiper-button-prev:after{display:none;}
.help-feature-section .swiper-button-next{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.help-feature-section .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.help-feature-section .swiper-button-next:after{display:none;}
.help-feature-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.help-feature-section .swiper-pagination{z-index: 4;}

.faqitem .faq-item-title .text{font-weight: 500;font-size: 15px;line-height: 1.4;}
.faqitem .faq-item-title{padding: 10px 45px 10px 15px;}
.contact-form-part{margin: 15px 0;}
.contact-us-from-section:before{display: none;}
.contact-us-from-section{padding: 40px 0;}
.contact-us-from-section .contact-title{font-size: 44px;text-align: center;}	
.location-section{padding: 30px 0;}
.location-section .location-part .location-details{left: 0;right: 0;position: relative;margin-top: -50px;padding: 20px;}
.location-section .location-part .location-map{height: 530px;}
.location-section .location-part{margin-top: 30px;}
.banner-section .page-title .title{font-size: 40px;}

}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}
