.menu-section{padding:30px 0;position:relative}
.menu-category{display:flex;align-items:center}
.menu-category .category-item{display:flex;align-items:center;box-shadow:0 3px 6px rgba(251,231,211,0.83);background:var(--color01);width:50%;padding:15px;border-radius:10px;justify-content:center;height: 60px;}
.menu-category .category-item+.category-item{margin-left:15px}
.menu-category .category-item .icon{font-size:30px;margin-right:10px;color:var(--color03); width:25px; height: 25px;}
.menu-category .category-item .icon img{display:block;width:100%;height:100%;object-fit:contain}
.menu-category .category-item .text{font-size:18px;font-weight:400}
.menu-category .category-item.reciept-item{display: none;}
.category-wrap{margin:25px 0}
.category-wrap .category-slider{padding:20px 28px}
.category-wrap .category-slider .slider-item{display:flex;flex-direction:column;opacity:.5;justify-content:center;align-items:center;margin:25px 0; transition: all 0.3s ease;}
.category-wrap .category-slider .slider-item:hover{opacity:1;cursor:pointer}
.category-wrap .category-slider .slider-item.active{opacity:1}
.category-wrap .category-slider .slider-item .icon{font-size:50px;width:100px;height:100px;background:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;box-shadow:0 6px 16px 0px rgb(0 0 0 / 20%);transition: all 0.3s ease; padding:25px}

.category-wrap .category-slider .slider-item .icon img{width:100%;height:100%;object-fit:contain;display:block}

.category-wrap .category-slider .slider-item:hover .icon,.category-wrap .category-slider .slider-item.active .icon{background:var(--color01)}
/* .category-wrap .category-slider .slider-item:hover .text,.category-wrap .category-slider .slider-item.active .text{font-weight:500} */
.category-wrap .category-slider .slider-item .text{font-size:14px;margin-top:15px;font-weight:500; text-align: center; line-height: 1;}
.category-slider .swiper-button-prev{font-size:18px;color:var(--color03); left: 0;}
.category-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon'}
.category-slider .swiper-button-prev:after{display:none}
.category-slider .swiper-button-next{font-size:18px;color:var(--color03); right: 0;}
.category-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon'}
.category-slider .swiper-button-next:after{display:none}
.category-slider .swiper-pagination.swiper-pagination-bullets{bottom:0;display:none}
.category-slider .swiper-pagination .swiper-pagination-bullet{width:12px;height:12px;margin:0 3px!important;background-color:var(--color02)}
.category-slider .swiper-button-next,.category-slider .swiper-button-prev{top:45%}
.menu-section .bill-section{background:var(--color01);position:absolute;width:100%;top:-150px;left:5px;padding:20px 25px;max-width:calc(100% - 30px)}
.menu-section .bill-section:before{background:linear-gradient(-45deg,#fbe7d3 16px,red 16px,blue 16px,transparent 0),linear-gradient(45deg,#fbe7d3 16px,transparent 0);background-position:left top;background-repeat:repeat-x;background-size:14px 32px;content:" ";display:block;height:32px;width:100%;position:absolute;top:-30px;left:0}
.menu-section .bill-section:after{background:linear-gradient(-45deg,transparent 16px,#fbe7d3 0),linear-gradient(45deg,transparent 16px,#fbe7d3 0);background-repeat:repeat-x;background-position:left bottom;background-size:14px 32px;content:"";display:block;width:100%;height:32px;position:absolute;bottom:-30px;left:0}
.menu-section .bill-section .bill-title{text-align:center;font-size:60px;font-weight:700;line-height:.8;margin:0 25px 22px;color:var(--color03);text-transform:uppercase;font-family:'Playfair Display',serif}
.menu-section .bill-section .bill-date{text-align:center;color:var(--shades07);font-size:20px;font-family:'Playfair Display',serif;text-transform:uppercase;font-weight:500}


.menu-section .bill-section .bill-date span+span{margin-left:8px}
.menu-section .bill-list{margin:34px 0 0;border-top:2px solid var(--shades02);border-bottom:2px solid var(--shades02);padding:30px 0}
.menu-section .bill-list .empty-cart{display : block;text-align: center; font-size: 16px;font-weight: 600;text-transform: capitalize;}
  

  
.menu-section .delivery-section{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.menu-section .delivery-section .icon{font-size:30px;color:var(--color03)}


.menu-section .delivery-section .text-part .text{font-size:16px;font-weight:600;text-transform:capitalize}
.menu-section .delivery-section .text-part .sub-text{font-size:16px;font-family:'Playfair Display',serif;color:var(--shades07);font-weight:300}
.menu-section .delivery-section .charges{font-size:20px;font-weight:600}
.menu-section .total-price{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.menu-section .total-price .text{font-size:22px;font-weight:600;font-family:'Playfair Display',serif;margin-bottom:5px}
.menu-section .total-price .items{font-size:16px;color:var(--shades07);font-weight:400}
.menu-section .total-price .price{font-size:20px;font-weight:600}
.menu-section .payment-action{margin-top:35px;text-align:center;display:flex;justify-content:center}
.menu-section .payment-action .pay-btn{height:40px;background:var(--color03);border-radius:50px;font-size:20px;font-family:'Playfair Display',serif;color:var(--shades01);font-weight:600;letter-spacing:1px;padding:25px;text-transform:uppercase;display:flex;align-items:center;justify-content:center;width:70%}
.menu-section .bill-list .bill-item{display:flex;align-items:center; justify-content: space-between;}
.menu-section .bill-list .bill-item .menu-name{width: 100%;}
.menu-section .bill-list .bill-item .menu-price{font-size: 18px;font-weight: 500;width: 90px;min-width: 90px;display: flex;align-items: center;justify-content: flex-end;}
.menu-section .bill-list .bill-item .menu-item-number{display: flex;align-items: center;justify-content: center;width: 80px;min-width: 80px;}
.menu-section .bill-list .bill-item .remove-btn{background:transparent;color:var(--color03);font-size:10px;width:18px;height:18px;display:flex;align-items:center;justify-content:center;margin-left:4px}





.menu-section .bill-list .menu-item-number .btn-action{background:var(--color03);color:var(--shades01);font-size:10px;width:22px;height:22px;border-radius:5px;display:flex;align-items:center;justify-content:center}
.menu-section .bill-list .menu-item-number .input-style{width:30px;height:25px;text-align:center;border:none;background:none;font-size:18px;font-weight:500}
.menu-section .bill-list .bill-item .menu-name .text{font-size:16px;font-weight:600;font-family:'Playfair Display',serif}
.menu-section .bill-list .bill-item .menu-name .sub-text{font-size:12px;font-weight:400;font-family:'Playfair Display',serif;color:var(--secondary);text-transform:capitalize}
.menu-section .bill-list .bill-item+.bill-item{margin-top:20px}



.popular-section{position:relative}
.menu-item-section{background:var(--color06);padding:60px 0}
.menu-item-section .section-header{justify-content:flex-start}
.menu-item-section .section-text-description {font-size: 14px;font-weight: 400;line-height: 1.3;color: #000;-webkit-line-clamp: 4;-webkit-box-orient: vertical;display: -webkit-box;overflow: hidden;text-overflow: ellipsis;}
.menu-item-section .menu-part{margin: 0 0 60px;}
.menu-item-section .menu-list{display:flex;position:relative;flex-wrap:wrap;margin:0 -30px}
.menu-item-section .menu-inner{transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%;}
.menu-item-section .menu-inner.popular-item{ transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%; border: 3px solid;z-index: 2;}
.menu-item-section .menu-inner.popular-item.out-of-stock{ transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%; border: 3px solid;filter: grayscale(0.8);}
.menu-item-section .menu-inner.out-of-stock{ transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%; filter: grayscale(0.8);}
.menu-item-section .menu-list .menu-item .btn-product-info{display: none;}
.menu-item-section .menu-list .menu-item{margin:30px;width:50%;position:relative;max-width:calc(50% - 60px)}
/* .menu-item-section .menu-list .menu-item.popular-item{margin:30px;width:50%;position:relative;max-width:calc(50% - 60px); border: 3px solid;border-radius: 32px;} */
.menu-item-section .menu-list .menu-item .menu-inner:hover{background:var(--color03);cursor:pointer}

.menu-item-section .menu-inner .popular-tag{display: block;position: absolute;top: -14px;left: 50%;transform: translateX(-50%);background-color: #070707;color: #FDBE1B;text-transform: uppercase;line-height: 1;font-weight: 800;letter-spacing: 1px;font-size: 14px;padding: 6px 10px;border-radius: 5px;
}



.menu-item-section .menu-list .menu-item .menu-inner .ingredients{position:relative;right:0}
.menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains{right:-40px;position:absolute}
.menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon{width:35px;height:35px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;border:2px solid var(--shades01);font-size:14px; padding: 5px;}
.menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon+.icon{margin-top:4px}
.menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon img{display: block; width: 100%; object-fit: contain; filter: brightness(0) invert(1);}
.menu-item-section .menu-list .menu-item .menu-inner .name{font-family:'Nunito',sans-serif;font-size:30px;font-weight:700;line-height: 1;}
.menu-item-section .menu-list .menu-item .menu-inner .menu-image{transition: all ease .3s;pointer-events: none;transform: translate(-12%,0) scale(1);pointer-events: none;width: 400px;height: 400px; }
.menu-item-section .menu-list .menu-item .menu-inner:hover .menu-image{transition: all ease .3s;margin-left: 0;transform: translate(-20%,-20%) scale(0.6);pointer-events: none;
}
.menu-item-section .menu-list .menu-item .menu-inner .menu-image img{width:100%;height:100%;object-fit:contain}

.menu-item-section .menu-item .menu-inner .description{position: absolute;opacity: 0;padding-right: 120px;color: var(--shades01);line-height: 2;font-size: 15px;bottom: 15px;z-index: 1;height: 150px;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 5;-webkit-box-orient: vertical;display: -webkit-box;transform: translateY(150px);visibility: hidden;transition: all ease 0.3s;}
.menu-item-section .menu-item .menu-inner:hover .description{opacity: 1;transform: translateY(0);visibility: visible;}
.menu-item-section .menu-item .menu-inner:hover .name{color:var(--color01)}



.menu-item-section .menu-order-action .add-to-cart{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:0;right:0;}
.menu-item-section .menu-order-action .add-to-cart.popular-item{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:0;right:0; border: 3px solid transparent;  border-bottom-color: #000; border-right-color: #000;}
.menu-item-section .menu-order-action .add-to-cart.popular-item.out-of-stock{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:0;right:0; border: 0px;}
.menu-item-section .menu-order-action .add-to-cart .icon{font-size:24px;margin-bottom:6px}
.menu-item-section .menu-order-action .add-to-cart .price{font-size:24px;font-weight:300; line-height: 1;}
.menu-item-section .menu-order-action .add-to-cart .text{font-size:14px;text-transform:uppercase;margin-top:5px}
.menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart{background:var(--color01); color:var(--color03);}
.menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .price{color:var(--color03);}
.menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .text{color:var(--color03);font-weight:400}
.menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .icon{color:var(--color03)}



/* / .modal-addons .addons-inner{padding: 40px 20px;} / */
.modal-addons .modal-header{background-color: var(--color01);align-items: center;border-bottom: none;}
.modal-addons .modal-title{color: #000;font-weight: 600;font-size: 36px;line-height: 1;}
.modal-addons .modal-close{background-color: transparent;color: #000;font-size: 40px;}
.modal-addons .addons-title{font-size: 28px;font-weight: 600;color: var(--color03);margin: 40px 0;line-height: 1.4;}
.modal-addons .addons-inner{display: flex;width: 100%;}
.modal-addons .addons-inner .addons-item .addons-text{font-size:17px;font-weight:400;line-height:1.5;color:var(--shades04);width:50%;max-width:calc(50% - 10px);padding:42px 0 0;-webkit-line-clamp:4;-webkit-box-orient:vertical;display:-webkit-box;overflow:hidden;text-overflow:ellipsis}
.modal-addons .addons-inner .addons-item{display: flex;align-items: flex-end;box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);border-radius: 20px;padding: 20px 20px 30px 20px;position: relative;width: 100%;}
.modal-addons .addons-inner .addons-item .addons-img{margin-top: -42px;}

.modal-addons .coke-inner{position: relative;}
.modal-addons .coke-inner .coke-item .coke-text{font-size:14px;font-weight:400;line-height:1.3;color:#000;-webkit-line-clamp:4;-webkit-box-orient:vertical;display:-webkit-box;overflow:hidden;text-overflow:ellipsis}
.modal-addons .coke-inner .coke-velu{font-size: 16px;font-weight: 400;line-height: 1.3;color: #000;margin: 15px 0 0;}
.modal-addons .coke-inner .coke-item .coke-content{width: 60%;max-width: calc(60% - 10px);}
.modal-addons .coke-inner .coke-item .coke-content .coke-title{color: var(--color03);font-size: 22px;font-weight: 600;margin: 0 0 10px;}
.modal-addons .coke-inner .coke-item .coke-img{height: 180px;width: 130px;display: flex;align-items: flex-end;}
.modal-addons .coke-inner .coke-item .coke-img img{height: 100%;width: 100%;object-fit: contain;}
.modal-addons .coke-inner .coke-item{display: flex;align-items: center;padding-top: 30px;}
.modal-addons .coke-inner .coke-option{box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);border-radius: 20px;padding:20px;position: relative;width: 100%;}
.modal-addons .coke-inner .coke-btn{display:flex;align-items:center;justify-content:center;border-radius:50%;height:35px;width:35px;background-color:var(--shades11);position:absolute;top:20px;right:25px;z-index:10;font-size:16px}
.modal-addons .coke-inner .coke-price{color:var(--color03);font-size:24px;font-weight:600;position:absolute;top:33px;left:70px}

.modal-addons .coke-inner .ingredients{position:relative;right:0}
.modal-addons .coke-inner .ingredients .list-contains{right:-16px;position:absolute; top:10px;}
.modal-addons .coke-inner .ingredients .list-contains .icon{width:32px;height:32px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;border:2px solid var(--shades01);font-size:14px; padding: 5px;box-shadow: rgb(0 0 0 / 20%) 0 1px 3px;}
.modal-addons .coke-inner .ingredients .list-contains .icon+.icon{margin-top:4px}
.modal-addons .coke-inner .ingredients .list-contains .icon img{display: block; width: 100%; object-fit: contain; filter: brightness(0) invert(1);}

.modal-addons .custom-checkbox .custom-checkbox-label:before{left: 26px;top: 45px;width: 30px;height: 30px;border-radius: 7px;}
.modal-addons .custom-checkbox .custom-checkbox-label:after{left: 32px;top: 45px;color: var(--shades01);font-size: 20px;}
.modal-addons .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: var(--color03);}
.modal-addons .modal-backdrop.show{opacity: .82;}
.modal-addons .modal-body{height: 560px;overflow: auto;padding:30px;}
.modal-addons .modal-content{border-radius: 23px;overflow: hidden;border: none;}
.modal-addons .modal-dialog{max-width: 1255px;}
.modal-addons .custom-checkbox{padding: 0;display: flex;width: 100%;}
.modal-addons .modal-footer{display: block;border-top: none;box-shadow: 0px -3px 38px rgba(66, 65, 65, 0.18);}
.modal-addons .modal-footer .cart-action{display: flex;align-items: center;justify-content: flex-start;}
.modal-addons .modal-footer .cart-action .cart-price{color: #000;font-size: 18px;font-weight: 600;}
.modal-addons .modal-footer .cart-action{width: 100%;max-width: calc(100% - 20px);}
.modal-addons .modal-footer .cart-action .add-btn{height:40px;background:var(--color03);border-radius:50px;font-size:20px;color:var(--shades01);font-weight:500;letter-spacing:1px;padding:25px;display:flex;align-items:center;justify-content:space-between;width:100%;margin-left:30px}
.modal-addons .modal-content ::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.modal-addons .modal-content ::-webkit-scrollbar{width: 10px;height: 5px;background-color: #fff;}
.modal-addons .modal-content ::-webkit-scrollbar-thumb{background-color: var(--color03);border-radius: 10px;}
/* / .modal-addons .modal-backdrop.show{opacity: .82 !important;} / */
.modal-backdrop:last-child{z-index: 1049;}


.modal-coca .modal-header{background-color: var(--color01);align-items: center;border-bottom: none;}
.modal-coca .modal-title{color: #000;font-weight: 600;font-size: 36px;line-height: 1;}
.modal-coca .modal-content{border-radius: 23px;overflow: hidden;border: none;box-shadow: rgb(0 0 0 / 40%) 0 10px 80px;}
.modal-coca .modal-close{background-color: transparent;color: #000;font-size: 35px;}
.modal-coca .modal-content .coca-content .coca-text{font-size:16px;font-weight:300;line-height:1.6;color:#000;-webkit-line-clamp:4;-webkit-box-orient:vertical;display:-webkit-box;overflow:hidden;text-overflow:ellipsis}
.modal-coca .modal-content .coca-content .coca-sub-text{color: #000;font-size: 25px;font-weight: 600;margin: 30px 0 0;}
.modal-coca .modal-content .coca-content .list-contains{display: flex;align-items: center;}
.modal-coca .modal-content .coca-content .coca-ingredients{margin: 30px 0;}
.modal-coca .modal-content .coca-content .coca-ingredients .ingredients-title{color: var(--color03);line-height: 1.3;font-weight: 600;font-size: 30px;margin: 0 0 15px;}
.modal-coca .modal-content .coca-content .list-contains .icon{width:30px;height:30px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;font-size:14px}
.modal-coca .modal-content .coca-content .list-contains .icon+.icon{margin-left: 20px;}
.modal-coca .modal-content .coca-content{display: flex;}
.modal-coca .modal-content .coca-content .coca-item{width:60%;max-width:calc(60% - 10px);}
.modal-coca .modal-footer .coca-action{width: 100%;}
.modal-coca .modal-footer .coca-action .coca-btn{text-align: center;height:40px;background:var(--color03);border-radius:50px;font-size:20px;color:var(--shades01);font-weight:500;letter-spacing:1px;padding:25px;display:flex;align-items:center;justify-content:center;width:100%;}
.modal-coca .modal-footer{justify-content: center;border-top: none;}

.modal-diest .modal-dialog{max-width: 900px;}
.modal-diest .modal-header{background-color: var(--color01);align-items: center;border-bottom: none;}
.modal-diest .modal-title{color: #000;font-weight: 600;font-size: 36px;line-height: 1;font-family: 'Playfair Display', serif;}
.modal-diest .modal-content{border-radius: 23px;overflow: hidden;border: none;}
.modal-diest .modal-close{background-color: transparent;color: #000;font-size: 35px;}
.modal-diest .diest-inner{ box-shadow: 0px 4px 20px rgb(0 0 0 / 13%);border-radius: 15px;padding: 20px 20px 30px 20px;margin: 35px 15px;}
.modal-diest .diest-inner .custom-checkbox{padding: 8px 30px 8px 8px;margin: 0 0 10px;}
.modal-diest .custom-checkbox .custom-checkbox-label{width: 100%;}
.modal-diest .custom-checkbox .custom-checkbox-label:before{right: 0;left: auto;}
.modal-diest .custom-checkbox-input:checked ~ .custom-checkbox-label:after{right: 3px;left: auto;}
.modal-diest .diest-item{display: flex;align-items: center;}
.modal-diest .diest-item .diest-icon{display: flex;align-items: center;justify-content: center;background-color: var(--color03);width: 40px;height: 40px;border-radius: 50%;color: #fff;font-size: 22px;margin-right: 15px; padding:5px}
.modal-diest .diest-item .diest-icon img{display:block;width:100%;object-fit:contain;filter:brightness(0) invert(1)}
.modal-diest .diest-item .diest-text{font-size: 21px;font-weight: 400;color: var(--shades09);}
.modal-diest .diest-action{display: flex;align-items: center;justify-content: center;margin-top: 50px;}
.modal-diest .diest-action .diest-btn{text-align: center;height:45px;background:var(--color03);border-radius:50px;font-size:23px;color:var(--shades01);font-weight:400;letter-spacing:1px;display:flex;align-items:center;justify-content:center;width:55%;box-shadow: 0px 8px 11px rgba(255, 85, 51, 0.22);}
.modal-diest .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background:var(--color03);border:1px solid var(--color03)}
.modal-diest .custom-checkbox .custom-checkbox-label:before{width:23px;height:23px;border-radius:0}
.modal-diest .custom-checkbox .custom-checkbox-label:after{color:var(--shades01)}

.custom-checkbox{position: relative;padding:8px 0 8px 30px;margin:0;display: flex; line-height:1;}
.custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-checkbox .custom-checkbox-label{position: relative;margin: 0;font-size: 14px;font-weight: 500;color: var(--color02);line-height: 1; display: flex; width: 100%;}
.custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width: 18px;height: 18px;background: var(--shades01);border: 1px solid var(--shades04);top: 50%;left: -30px; transform: translateY(-50%);border-radius: 2px;}
.custom-checkbox .custom-checkbox-label:after{content: "\f00c";position: absolute;font-family: "Font Awesome 5 Pro";width: 18px;height: 18px;top: 50%;left: -30px;transform: translateY(-50%); font-weight: 500;font-size: 14px;display: none;align-items:center; justify-content:center; color: var(--shades01);text-align: center;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:flex;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: var(--color01);border: 1px solid var(--color01);}

.recieptModal .modal-body{padding: 0;}

.recieptModal .bill-section{background:var(--color01);padding:20px 25px;}
.recieptModal .bill-section:before{background:linear-gradient(-45deg,#fbe7d3 16px,red 16px,blue 16px,transparent 0),linear-gradient(45deg,#fbe7d3 16px,transparent 0);background-position:left top;background-repeat:repeat-x;background-size:14px 32px;content:" ";display:block;height:32px;width:100%;position:absolute;top:-30px;left:0}
.recieptModal .bill-section:after{background:linear-gradient(-45deg,transparent 16px,#fbe7d3 0),linear-gradient(45deg,transparent 16px,#fbe7d3 0);background-repeat:repeat-x;background-position:left bottom;background-size:14px 32px;content:"";display:block;width:100%;height:32px;position:absolute;bottom:-30px;left:0}
.recieptModal .bill-section .bill-title{text-align:center;font-size:60px;font-weight:700;line-height:.8;margin:0 25px 22px;color:var(--color03);text-transform:uppercase;font-family:'Playfair Display',serif}
.recieptModal .bill-section .bill-date{text-align:center;color:var(--shades07);font-size:20px;font-family:'Playfair Display',serif;text-transform:uppercase;font-weight:500}
.recieptModal .bill-section .bill-date span+span{margin-left:8px}

.recieptModal .bill-list{margin:34px 0 0;border-top:2px solid var(--shades02);border-bottom:2px solid var(--shades02);padding:30px 0}
.recieptModal .bill-list .bill-item{display:flex;align-items:center; justify-content: space-between;}
.recieptModal .bill-list .bill-item .menu-name{width: 100%;}
.recieptModal .bill-list .bill-item .menu-price{font-size: 18px;font-weight: 500;width: 100px;min-width: 100px;display: flex;align-items: center;justify-content: flex-end;}
.recieptModal .bill-list .bill-item .menu-item-number{display: flex;align-items: center;justify-content: center;width: 80px;min-width: 80px;}
.recieptModal .bill-list .menu-item-number .btn-action{background:var(--color03);color:var(--shades01);font-size:10px;width:22px;height:22px;border-radius:5px;display:flex;align-items:center;justify-content:center}
.recieptModal .bill-list .menu-item-number .input-style{width:30px;height:25px;text-align:center;border:none;background:none;font-size:18px;font-weight:500}
.recieptModal .bill-list .bill-item .menu-name .text{font-size:16px;font-weight:600;font-family:'Playfair Display',serif}
.recieptModal .bill-list .bill-item .menu-name .sub-text{font-size:12px;font-weight:400;font-family:'Playfair Display',serif;color:var(--secondary);text-transform:capitalize}
.recieptModal .bill-list .bill-item+.bill-item{margin-top:20px}

.recieptModal .delivery-section{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.recieptModal .delivery-section .icon{font-size:30px;color:var(--color03)}
.recieptModal .delivery-section .text-part .text{font-size:16px;font-weight:600;text-transform:capitalize}
.recieptModal .delivery-section .text-part .sub-text{font-size:16px;font-family:'Playfair Display',serif;color:var(--shades07);font-weight:300}
.recieptModal .delivery-section .charges{font-size:20px;font-weight:600}

.recieptModal .total-price{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.recieptModal .total-price .text{font-size:22px;font-weight:600;font-family:'Playfair Display',serif;margin-bottom:5px}
.recieptModal .total-price .items{font-size:16px;color:var(--shades07);font-weight:400}
.recieptModal .total-price .price{font-size:20px;font-weight:600}



.recieptModal .payment-action{margin-top:35px;text-align:center;display:flex;justify-content:center}
.recieptModal .payment-action .pay-btn{height:40px;background:var(--color03);border-radius:50px;font-size:20px;font-family:'Playfair Display',serif;color:var(--shades01);font-weight:600;letter-spacing:1px;padding:25px;text-transform:uppercase;display:flex;align-items:center;justify-content:center;width:70%}


@media screen and (max-width:1366px){
  .menu-item-section .menu-list .menu-item .menu-inner .name{font-size: 24px;font-weight: 800;}
  .menu-item-section .menu-list .menu-item .menu-inner .menu-image{width: 260px;height: 320px;transform: translate(-6%,0) scale(1);}
  .menu-item-section .menu-order-action .add-to-cart .icon{font-size: 20px;}
  .menu-item-section .menu-order-action .add-to-cart .icon{font-size: 20px;}
  .menu-item-section .menu-order-action .add-to-cart .price{font-size: 20px;}
  .menu-item-section .menu-order-action .add-to-cart .text{font-size: 12px;}
}
@media screen and (max-width:1024px){
  .menu-item-section .section-header{margin: 0;}
  .menu-section .bill-section{display:none;}
  .menu-category .category-item.reciept-item{display: flex;}
  .menu-item-section .menu-item .menu-inner .description{font-size: 14px; line-height: 1.8;-webkit-line-clamp: 4;height: 101px;}
  
}
@media screen and (max-width:990px){
  .category-wrap{margin: 0 -28px;}
  .menu-item-section .menu-list{margin: 0 -15px;}
  .menu-item-section .menu-list .menu-item{max-width: calc(50% - 30px);margin: 15px;}

  .comment-add{width: 90%;}

}
@media screen and (max-width:480px){
  .menu-section{padding:0;}
  .category-wrap{margin: 0 -15px;}
  .category-wrap .category-slider{padding: 0 15px;}
  .category-slider .swiper-button-next, .category-slider .swiper-button-prev{display: none;}
  .menu-category .category-item{height: 40px; width: 100%; padding: 10px;border-radius: 5px;}
  .menu-category .category-item+.category-item{margin-left: 8px;}
  .menu-category .category-item .icon{font-size: 20px;margin-right: 8px; width:20px; height:20px;}
  
  .menu-item-section .section-header{justify-content: center;}
  .menu-item-section .section-text-description{text-align: center;}

  .modal-addons .coke-inner{position: relative;margin-top: 10px;}
  .menu-category{overflow: auto;}
  .menu-category .category-item .text{font-size: 14px;font-weight: 500;}
  .category-wrap .category-slider{padding: 0 0;}
  .category-wrap .category-slider .slider-item .icon{font-size: 24px;width: 50px;height: 50px;padding: 12px;}
  .category-wrap .category-slider .slider-item .text{font-size: 11px;margin-top: 10px;font-weight: 500;text-align: center;letter-spacing: -0.5px;line-height: 1;}
  .menu-item-section .menu-list{margin: 20px 0; padding: 0 15px;}
  .menu-item-section .menu-list .menu-item{max-width: 100%; width: 100%; margin: 0 0 60px;}
  .menu-item-section .menu-list .menu-item .menu-inner{display: flex; flex-direction: column;}
  .menu-item-section .menu-list .menu-item .menu-inner .name{font-size: 24px;font-weight: 600;padding-right: 40px;}
  .menu-item-section .menu-list .menu-item .menu-inner .menu-image{width: 240px;height: 240px; order: 2; transform: translate(-25px, 0);margin:0;}
  .menu-item-section .menu-list .menu-item .menu-inner .description{order: 1;position: absolute;opacity: 1;bottom: auto;padding: 0;visibility: visible;transform: none;margin: 0; height: 0;top: 60px;left: 25px;width: calc(100% - 80px);}
  .menu-item-section .menu-order-action .add-to-cart .price{font-weight: 500;}
  .menu-item-section .menu-order-action .add-to-cart .text{font-size: 10px;margin: 0;}
  .menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon{width: 30px; height: 30px; font-size: 12px;}
  .menu-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon+.icon{margin-top: 2px;}
  .menu-item-section .menu-list .menu-item .menu-inner:hover{background: var(--color01);}
  .menu-item-section .menu-item .menu-inner:hover .name{color: var(--shades02);}
  .menu-item-section .menu-list .menu-item .menu-inner:hover .menu-image{transform: translate(-25px, 0);margin:0;}
  .menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart{background:var(--color03); color:var(--shades01);}
  .menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .price{color:var(--shades01);}
  .menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .text{color:var(--shades01);font-weight:400}
  .menu-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .icon{color:var(--shades01)}
  .menu-item-section .menu-list .menu-item .btn-product-info{display: flex;justify-content: center;align-items: center;width: 28px;height: 28px;z-index: 2;position: absolute;top: 24px;right: 28px;font-size: 20px;padding: 0;background-color: transparent;color: var(--color03);}
  .menu-item-section .menu-list .menu-item.active .btn-product-info{color:var(--shades01);}
  .menu-item-section .menu-list .menu-item.active .menu-inner{background-color:var(--color03);}
  .menu-item-section .menu-list .menu-item.active .menu-inner .name{color:var(--shades01);}
  .menu-item-section .menu-list .menu-item.active .menu-inner .menu-order-action .add-to-cart{background:var(--color01);color:var(--color03);}
  .menu-item-section .menu-list .menu-item.active .menu-inner .description{max-height: 101px; height: auto;}
  .menu-item-section .menu-list .menu-item.active .menu-inner .menu-image{transform: translate(-25px, 101px);}

  .modal-addons .modal-title{font-size: 24px;}
  .modal-addons .modal-close{font-size: 30px; padding: 0;}
  .modal-addons .modal-body{padding:15px;}
  .modal-addons .modal-body .row{margin: 0 -5px;}
  .modal-addons .modal-body .row > [class*=col-]{padding:0 5px;}
  .modal-addons .addons-inner{margin: 0 0 40px;}
  .modal-addons .addons-inner .addons-item{border-radius: 12px;padding: 10px; display: flex; flex-direction: column-reverse;align-items: unset;justify-content: flex-end;}
  .modal-addons .custom-checkbox .custom-checkbox-label:before{left: 10px;top: 20px;width: 16px;height: 16px;border-radius: 4px;}
  .modal-addons .custom-checkbox .custom-checkbox-label:after{left: 10px;top: 20px;font-size: 12px;width: 16px;height: 16px;}
  .modal-addons .addons-inner .addons-item .addons-img{max-width: 100%;margin: -30px 0 0;transform: translate(10px, 0);width: 100%;}
  .modal-addons .addons-inner .addons-item .addons-img img{width: 100%;}
  .modal-addons .addons-inner .addons-item .addons-text{width: 100%;max-width: 100%;padding: 10px 0 0;font-size: 12px;}
  .modal-addons .addons-title{font-size: 20px;margin: 0 0 20px;}
  .modal-addons .coke-inner .coke-option{border-radius: 12px;padding: 10px; display: flex;}
  .modal-addons .coke-inner .coke-price{top: 13px; left: 36px;font-size: 16px;}
  .modal-addons .coke-inner .coke-item{position: relative;width: 100%;}
  .modal-addons .coke-inner .coke-item .coke-img{height: 100%;width: 50px;position: absolute;top: 0;right: 0;z-index: 0;}
  .modal-addons .coke-inner .coke-item .coke-img img{width: 100%;height: 100%;object-fit: cover;}
  .modal-addons .coke-inner .coke-btn{height: 20px;width: 20px;top: 10px;right: 50px;font-size: 12px;}
  .modal-addons .coke-inner .coke-item .coke-content{width: 100%;max-width: calc(100% - 50px);display: flex;flex-direction: column-reverse;padding: 10px 0;}
  .modal-addons .coke-inner .coke-item .coke-content .coke-title{font-size: 16px;}
  .modal-addons .coke-inner .coke-item .coke-text{font-size: 12px; line-height: 14px; max-height: 42px;-webkit-line-clamp: 4;}
  .modal-addons .modal-footer .cart-action .add-btn{padding: 0 20px;font-size: 16px;}

  .modal-coca .modal-title{font-size: 24px;}
  .modal-coca .modal-close{font-size: 30px; padding: 0;}
  .modal-coca .modal-content .coca-content .coca-text{line-height: 1.2;font-size: 14px;}
  .modal-coca .modal-content .coca-content .coca-sub-text{font-size: 20px;}
  .modal-coca .modal-content .coca-content .coca-ingredients .ingredients-title{font-size: 24px;}
  .modal-coca .modal-content .coca-content .list-contains .icon+.icon{margin-left: 8px;}
  .modal-coca .modal-content .coca-content .coca-img{width: 100%; max-width: 40%; margin-left: 10px;}
  .modal-coca .modal-content .coca-content .coca-img img{width: 100%;height: 100%;object-fit: cover;}
  .modal-coca .modal-footer .coca-action .coca-btn{padding: 0 20px;font-size: 16px;}
  
  .modal-diest .modal-title{font-size: 24px;}
  .modal-diest .modal-close{font-size: 30px; padding: 0;}
  .modal-diest .diest-inner{box-shadow: none;border-radius: 0;padding: 0;margin: 0;}
  .modal-diest .diest-inner .custom-checkbox{padding: 10px 0; margin: 0;}
  .modal-diest .custom-checkbox .custom-checkbox-label:before{width: 20px;height: 20px;}
  .modal-diest .custom-checkbox .custom-checkbox-label:after{width: 20px;height: 20px; right: 0 !important;}
  .modal-diest .diest-item .diest-icon{width: 30px; height: 30px;font-size: 15px;}
  .modal-diest .diest-item .diest-text{font-size: 16px;}
  .modal-diest .diest-action{margin-top: 20px;}
  .modal-diest .diest-action .diest-btn{height: 40px;font-size: 16px;}

  .recieptModal .total-price .text{font-size: 16px;}
  .recieptModal .total-price .items{font-size: 16px;}
  .recieptModal .total-price .price{font-size: 16px;}
  .recieptModal .delivery-section{justify-content: flex-start;}
  .recieptModal .delivery-section .text-part{margin-left: 10px;}
  .recieptModal .delivery-section .text-part .text{font-size: 14px;}
  .recieptModal .delivery-section .text-part .sub-text{font-size: 14px;}
  .recieptModal .delivery-section .charges{font-size: 16px; margin-left: auto;}
  .recieptModal .delivery-section .icon{font-size: 24px;}
  .recieptModal .bill-section .bill-date{font-size: 16px;font-family: 'Poppins', sans-serif;}
  .recieptModal .bill-section .bill-title{font-size: 40px;margin: 0 0 10px;}
  .recieptModal .payment-action .pay-btn{font-size: 16px;height: 44px; padding: 0 10px;}
  .recieptModal .bill-list .bill-item .menu-price{font-size: 14px;font-weight: 600;width: 80px;min-width: 80px;}
  .recieptModal .bill-list .menu-item-number .input-style{font-size: 14px;font-weight: 600;}
  .recieptModal .bill-list .bill-item .menu-name .text{font-size: 14px;}
}