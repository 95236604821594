/*
Version: 1.0
Author: Symlink Technology
Author URI: https://www.symlinktech.com/.
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*
font-family: 'Inter', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
*/
:root{
	--color01:#FBE7D3;
	--color02:#D7592F;
  --color03:#F14E2D;
	--color04:#FFF5E0;
	--color05:#faeee9;
	--color06:#FEEEE7;
  --color07:#2392D7;
  --shades01:#ffffff;
  --shades02:#1F1F1F;
  --shades03:#3A3A3A;
  --shades04:#4D4C4C;
  --shades05:#F7F7F7;
  --shades06:#EBEBEB;
  --shades07:#979797;
  --shades08:#DEDEDE;
  --shades09:#5A5A5A;
  --shades10:#B8B8B8;
  --shades11:#C9C9C9;
  --shades12:#ECECEC;
  --shades13:#2B2C34;
  --ratingColor:#FFD06A;
  --veiwWidth: calc(100vw - 17px);
  --padding: calc(var(--veiwWidth)/2 - 785px);
}
.toast-message{
  color:var(--color02);
  font-size: 16px;
  font-weight: 600;
}

/* ********|| INITIALIZATION STARTS ||******** */
body, html{ width: 100%; line-height:18px; margin:0 auto !important;padding:0 !important;font-family: 'Poppins', sans-serif; font-weight: 300; -webkit-font-smoothing: subpixel-antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);font-size: 14px;  color: var(--shades02); background:var(--shades01);position: relative; z-index: 0;}
*:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
a {text-decoration: none ;}
a:hover{text-decoration: none;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease;}
a:focus{ outline: none;text-decoration: none;}
button{cursor: pointer; outline: none; border: 0;}
input:focus, label:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important; box-shadow: none !important;}
/* ********|| INITIALIZATION ENDS ||******** */

/* ********|| PSEUDO ELEMENTS STARTS ||******** */
::selection{ background-color:var(--shades01); color:var(--shades01)}
::-moz-selection{ background-color:var(--shades01); color:var(--shades01)}
::-webkit-selection{ background-color:var(--shades01); color:var(--shades01)}

:-webkit-focus { outline: none !important; }
:-moz-focus { outline: none !important; }
:focus { outline: none !important; }

select:-webkit-focusring { color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades02);}
select:-moz-focusring {color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades02);}
select:focusring {color: var(--shades01) !important; text-shadow: 0 0 0 var(--shades02);}

::input-placeholder{ color:var(--shades02) !important;}
::-moz-input-placeholder{ color:var(--shades02) !important;}
::-webkit-input-placeholder{ color:var(--shades02) !important;}


input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{-webkit-appearance: none; margin: 0;}
input[type=number]{-moz-appearance: textfield;}

/*::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textMedColor);}
::-webkit-scrollbar-thumb{background-color: var(--secondaryColorAltLight);}*/
/* ********|| PSEUDO ELEMENTS ENDS ||******** */

/* ********|| ADJUSTMENT CLASSES STARTS ||******** */
*,::after,::before{box-sizing: border-box;}
a, button{color:var(--shades02); border:0; transition: all 0.3s ease;}
a:hover, a:focus{color:var(--shades02);}
p { margin: 0 0 10px; }
hr{margin-top: 20px; margin-bottom: 20px; border: 0; border-top: 1px solid var(--color04);}
strong{font-weight:600;}

.page-banner-section{margin: 25px 0;}
.page-banner-section .banner-section{border-radius: 30px;height: 240px;background: var(--color03);display: flex;align-items: center;justify-content: center;flex-direction: column;}
.banner-section .breadcrumb-section{display: flex;}
.banner-section .breadcrumb-section .link{font-size: 14px;font-weight: 300;color: var(--shades01);}
.banner-section .breadcrumb-section .seperator{color: var(--shades01);margin: 0 5px;}
.banner-section .breadcrumb-section .link .breadcrumb-link{color: var(--shades01);}
.banner-section .page-title{}
.banner-section .page-title .title{position: relative;font-family: 'Playfair Display', serif;color: var(--shades01);font-size: 64px;font-weight: 700;line-height: 1.5;margin: 0;}

.page-banner-section.blog-details{}
.page-banner-section.blog-details .banner-section{position: relative;}
.banner-back{}
.banner-back .back{position: absolute;left: 20px;top: 50%;transform: translateY(-50%);}
.banner-back .back .icon{font-size: 35px;color: var(--shades01);}


/* ********|| HEADER STARTS ||******** */
header{position: relative; z-index: 99; width: 100%; padding:0; height: 140px; display: flex; align-items: center;}
header .header-content{ position: relative; width: calc(100% + 60px); height: 100px; display: flex; align-items: center; border-radius: 100px; background-color: var(--color01);box-shadow: 0px 4px 123px rgba(0, 0, 0, 0.25); padding: 15px 30px; margin:0 -30px;}
header .container{display: flex; align-items: center;}
header .brand{position: relative; height: 80px; width: 94px;order: 1;}
header .brand .logo{display: block; width: 100%; height: 100%;}
header .brand .logo img{width: 100%; height: 100%; object-fit: contain; object-position: left center;}
header .navigation{position: relative; display: flex; margin: auto; align-items: center;order: 2;}
header .navigation .btn-menu-toggle{display: none;}
header .navigation .btn-menu-toggle.alt{display: none;}
header .navigation.active .btn-menu-toggle.alt{display: block;font-size: 24px;}
header .navigation.active .btn-menu-toggle{display: none;}
header .navigation .navigation-header{display: none; align-items: center; justify-content: space-between;padding:30px 10px; margin:0;}
header .navigation .navigation-header .brand{position: relative; height: 80px; width: 94px;order: 2;}
header .navigation .navigation-header .brand .logo{display: block; width: 100%; height: 100%;}
header .navigation .navigation-header .brand .logo img{width: 100%; height: 100%; object-fit: contain; object-position:center;}
header .navigation .navigation-header .btn-menu-toggle{width:44px; height: 44px; display: flex; justify-content: center; align-items: center;order: 1;background-color: var(--color03); color: var(--shades01); border-radius: 50%; font-size: 18px;}
header .navigation .navigation-header .user-account{width:44px; height: 44px; display: flex; justify-content: center; align-items: center;order: 3;background-color: var(--color03); color: var(--shades01); border-radius: 50%; font-size: 20px;}
header .navigation .navigation-content{position: relative;}
header .navigation .navigation-menu{display: flex; align-items: center;}
header .navigation .navigation-menu .menu-item{padding: 10px 20px;}
header .navigation .navigation-menu .menu-link{font-family: 'Nunito', sans-serif; font-size: 16px; font-weight: 500; color: var(--shades04);}
header .navigation .navigation-menu .menu-link:hover, header .navigation .navigation-menu .menu-link.active{color: var(--color02);}
header .navigation .header-social{display:none;}
header .navigation .header-links{display:none;}

header .navigation-options{display: flex; justify-content: space-between; align-items: center; width: 94px;order: 3;}
header .cart{position:relative; width: 44px;height: 44px;display: flex;align-items: center;justify-content: center;font-size: 1.8rem;color: var(--color02);}
header .cart .indication{position:absolute;width:20px;height:20px;background-color:var(--color02);border-radius:50%;top:2px;right:0;font-size:10px;display:flex;align-items:center;justify-content:center;color:var(--color06)}
header .user-account{position:relative; width: 44px;height: 44px;display: flex;align-items: center;justify-content: center;font-size: 1.8rem;color: var(--color02);}
/* ********|| HEADER ENDS ||******** */
.section-header{position: relative; display: flex; justify-content: center; margin: 0 0 30px;}
.section-header .section-title{font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5;text-transform: capitalize;}
.section-header .section-title span{ color: var(--color02);}
/* ********|| BANNER SLIDER START ||******** */

@media screen and (max-width: 1024px) {
  .section-header .section-title{font-size: 48px;}
  .banner-section .page-title .title{font-size: 54px;}
}

@media screen and (max-width: 480px) {
  .banner-section .breadcrumb-section{display: none;}
  .banner-section .page-title .title{font-size: 30px;}
  .page-banner-section .container{padding: 0;}
  .page-banner-section .banner-section{margin-top: -150px;border-radius: 0 0 30px 30px;}
  .banner-section .page-title {padding-top: 65px;}
}

@media screen and (max-width: 990px) {
  .section-header .section-title{font-size: 40px;text-align: center;} 
  .section-header{justify-content: center;}
}



/* /////////////////////////////////////////////////////////////// */
/*             			   	 	BOOTSTRAP FIX            			   				 */
/* /////////////////////////////////////////////////////////////// */
@media (min-width: 576px){
	.container {max-width: none;}
	.modal-dialog {max-width: 700px;}
	.modal-sm {max-width: 400px;}
}
@media (min-width: 768px){
	.container {max-width: 740px;}
}
@media (min-width: 992px){
	.container {max-width: 960px;}
}
@media (min-width: 1200px){
	.container {max-width: 1170px;}
}
@media (min-width: 1400px){
	.container {max-width: 1280px;}
}
@media (min-width: 1600px){
	.container {max-width: 1400px;}
}
@media (min-width: 1900px){
	.container {max-width: 1540px;}
}
@media (min-width: 576px){
  :root{
    --padding:15px;
  }
	.container{max-width: none;}
	.modal-dialog{max-width: 700px;}
	.modal-sm{max-width: 400px;}
}
@media (min-width: 768px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 710px) / 2);
  }
	.container{max-width: 740px;}
}
@media (min-width: 992px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 930px) / 2);
  }
	.container{max-width: 960px;}
}
@media (min-width: 1200px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1140px) / 2);
  }
	.container{max-width: 1170px;}
}
@media (min-width: 1440px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1310px) / 2);
  }
	.container{max-width: 1340px;}
}
@media (min-width: 1600px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1370px) / 2);
  }
	.container{max-width: 1400px;}
}
@media (min-width: 1900px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1570px) / 2);
  }
	.container{max-width: 1600px;}
}
/*
font-family: 'Inter', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
*/
/*////////////////////////////////////////////////////////////////*/
/*             		          	 HOME PAGE      	      				  	*/
/*////////////////////////////////////////////////////////////////*/




/* ********|| BANNER SLIDER ENDS ||******** */

/* ********|| BANNER HIGHLIGHTS START ||******** */

/* ********|| BANNER HIGHLIGHTS ENDS ||******** */

/* ********|| CONTENT START ||******** */

/* ********|| CONTENT ENDS ||******** */

/* ********|| FOOTER STARTS ||******** */
footer{position: relative;}
footer .footer-top{position: relative; padding:60px 0;}
footer .footer-bottom{position: relative;background-color: var(--color03); height: 80px; display: flex; align-items: center;padding:0;}
footer .footer-bottom .row{justify-content: flex-end;}
footer .footer-content{position: relative;}
footer .brand{width: 100px; height: 100px; overflow: hidden; }
footer .brand .logo{display: block; width: 100%; height: 100%;}
footer .brand .logo img{width: 100%; height: 100%; object-fit: contain; object-position: left center;}
footer .footer-text{font-size: 16px; font-weight: 400; font-family: 'Nunito', sans-serif; line-height: 1.5;  color: var(--shades04); margin: 20px 0 0;}
footer .footer-social{position: relative; display: flex; align-items: center; margin: 20px -10px 0;}
footer .footer-social .social-item{display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; background-color: var(--color03); border-radius: 50%; margin: 0 10px; font-size: 20px; color: var(--shades01);}
footer .footer-social .social-item:hover{background-color: var(--color06); color: var(--color03);}
footer .footer-title{position: relative; font-family: 'Playfair Display', serif; font-size: 28px; font-weight: 600; margin: 0 0 50px; color: var(--shades02);}
footer .footer-title span{display: block; position: absolute; width: 80px; height: 8px; background-color: var(--color03); border-radius: 10px; bottom: -20px;}
footer .footer-title span:before{content:''; position:absolute; top:0; right:-20px; width: 8px; height:8px; border-radius:50%; background-color:var(--color03);}
footer .footer-title span:after{content:''; position:absolute; top:0; right:-40px; width: 8px; height:8px; border-radius:50%; background-color:var(--color03);}
footer .footer-contact{position: relative; margin: 0 0 20px;}
footer .footer-contact .item{display: flex; align-items: center; cursor: pointer;}
footer .footer-contact .item+.item{margin:20px 0 0;}
footer .footer-contact .item .icon{width: 44px;height: 44px;background-color: var(--color06);display: flex;align-items: center;justify-content: center;border-radius: 8px;font-size: 18px;color: var(--color03);margin: 0 20px 0 0;transition: all 0.3s ease;}
footer .footer-contact .item .title{width: 100%; max-width: calc(100% - 64px);font-size: 14px; font-weight: 400; color: var(--shades04); transition: all 0.3s ease;}
footer .footer-contact .item:hover .icon{background-color: var(--color03);color: var(--shades01);}
footer .footer-contact .item:hover .title{color: var(--color03);}
footer .footer-links{position: relative; margin: 0 0 20px;}
footer .footer-links .link-item{font-size: 14px; font-weight: 400; color: var(--shades04);display: flex; align-items: center;}
footer .footer-links .link-item:hover{ color: var(--color03);}
footer .footer-links .link-item+.link-item{margin:10px 0 0;}
footer .footer-time{position: relative; margin: 0 0 20px;}
footer .footer-time .item{display: flex; align-items: center;}
footer .footer-time .item+.item{margin:10px 0 0;}
footer .footer-time .item .item-label{font-size: 14px; font-weight: 400; color: var(--shades04);width: 90px;}
footer .footer-time .item .item-separator{width: 100%; max-width: calc(100% - 240px); margin:auto;border-top: 2px dashed var(--shades03);}
footer .footer-time .item .item-data{font-size: 14px; font-weight: 400; color: var(--shades04);width: 124px;}
footer .footer-time .item .text-highlight{color: var(--color03);}
footer .footer-time .item.active .item-label{font-weight: 600;color: var(--color03);}
footer .footer-time .item.active .item-separator{border-color: var(--color03);}
footer .footer-time .item.active .item-data{font-weight: 600;color: var(--color03);}
footer .copyright{font-size: 16px;font-weight: 400;color: var(--shades01);text-align: center;}
footer .links{display: flex; justify-content: flex-end; align-items: center; margin: 0 -15px;}
footer .links .link-item{padding: 0 15px;}
footer .links .link-item+.link-item{border-left:1px solid var(--shades01);}
footer .links .link-item .link{font-size: 16px;font-weight: 400;color: var(--shades01);}

/* ********|| FOOTER ENDS ||******** */

/* ********|| OTHER PAGES STARTS ||******** */

/* ********|| OTHER PAGES ENDS ||******** */

/* ********|| MODAL STARTS ||******** */

/* ********|| MODAL ENDS ||******** */

/* ********|| RESPONSIVE STARTS ||******** */
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1540px) {
  
}
@media screen and (max-width: 1440px) {
 
}
@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1366px) {
  footer .footer-title{font-size: 24px;}
  footer .copyright{font-size: 14px;}
  footer .links .link-item .link{font-size: 14px;}
}
@media screen and (max-width: 1280px) {
 
}
@media screen and (max-width: 1024px) {
  header .header-content{justify-content: space-between;}
  header .brand{order:2; margin: 0 auto;}
  header .brand .logo img{object-position: center;}
  header .navigation{order:1;margin: 0;}
  header .navigation .btn-menu-toggle{width:44px; height: 44px; display: flex; justify-content: center; align-items: center;background-color: var(--color03); color: var(--shades01); border-radius: 50%; font-size: 18px;}
  header .navigation .navigation-header{display: flex;}
  header .navigation .navigation-content{position: fixed;top: 0;left: 0;width: 300px;height: 100%;background-color: var(--color01);box-shadow: 4px 0px 123px rgb(0 0 0 / 25%);z-index: 101;transform: translateX(-150%);transition: all 0.3s ease;}
  header .navigation.active .navigation-content{transform: translateX(0%);}
  header .navigation .navigation-menu{flex-direction: column;overflow: auto;max-height: calc(100vh - 140px);padding: 0 0 20px;}
  header .navigation .navigation-menu .menu-item{width: 100%; padding:10px;}
  header .navigation .navigation-menu .menu-link{padding: 15px 20px;display: flex; justify-content: center; background-color:var(--color03); border-radius: 30px; color: var(--shades01); text-transform: uppercase; font-size: 14px;}
  header .navigation .navigation-menu .menu-link:hover, header .navigation .navigation-menu .menu-link.active{color: var(--shades01);}
  header .navigation-options{width:auto;}
  header .navigation-options .user-account{display:none;}
  header .navigation-options .cart{width:44px; height: 44px; display: flex; justify-content: center; align-items: center;background-color: var(--color03); color: var(--shades01); border-radius: 50%; font-size: 20px;}
  header .cart .indication{background-color: var(--shades01);color: var(--color02);}
  footer .footer-top{padding: 40px 0 0;}
  footer .footer-content{margin: 0 0 40px;}
  footer .brand{margin: 0 auto;}
  footer .footer-text{text-align:center; margin: 30px 0 0;}
  footer .footer-social{ justify-content: center;margin: 30px -10px 0;}
  footer .links .link-item .link{font-size: 12px;}
}
@media screen and (max-width: 990px) {
  header .header-content{width: calc(100% + 30px);padding: 15px; margin: 0 -15px;}
  footer .container{max-width: 100%;}
  footer .footer-bottom .row{justify-content: space-between;}
  footer .footer-contact .item .icon{margin: 0 10px 0 0;}
  footer .footer-contact .item .title{max-width: calc(100% - 54px);}
  footer .copyright{text-align: left;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 767px)  and (orientation: landscape){
  
}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  header{height: 120px;}
  header .header-content{width: 100%;margin: 0;height: 80px;}
  header .brand{height: 60px; width: 74px;}
  header .navigation .navigation-header{padding: 30px;}
  header .navigation .navigation-header .brand{height: 60px; width: 74px;}
  header .navigation .navigation-menu{max-height: calc(100vh - 200px);}
  header .navigation .navigation-content{box-shadow: none; width: 100%; transform: none; opacity: 0; visibility: hidden;display: flex; flex-direction: column;}
  header .navigation.active .navigation-content{opacity: 1; visibility: visible;}
  header .navigation .header-social{position: relative;display: flex;align-items: center;justify-content: center;margin: auto 0 0;}
  header .navigation .header-social .social-item{display: flex; justify-content: center; align-items: center; width: 40px; height: 40px; background-color: var(--color03); border-radius: 50%; margin: 0 10px; font-size: 16px; color: var(--shades01);}
  header .navigation .header-links{display: flex;justify-content: center;align-items: center;margin: 10px 0;}
  header .navigation .header-links .link-item{padding: 0 15px;}
  header .navigation .header-links .link-item+.link-item{border-left:1px solid var(--color03);}
  header .navigation .header-links .link-item .link{font-size: 12px;font-weight: 400;color: var(--color03);}
  footer .footer-text{font-size: 14px;}
  footer .footer-title{font-size:20px;}
  footer .footer-title span{height: 6px;}
  footer .footer-title span:before{width:6px; height: 6px; right: -15px;}
  footer .footer-title span:after{width:6px; height: 6px;right: -30px;}
  footer .footer-contact .item+.item{margin: 30px 0 0;}
  footer .footer-contact .item .icon{width: 30px;height: 30px;font-size: 14px;}
  footer .footer-contact .item .title{font-size: 12px;}
  footer .footer-links .link-item{font-size: 12px;}
  footer .footer-time .item .item-label{font-size: 12px;}
  footer .footer-time .item .item-data{font-size: 12px;}
  footer .footer-bottom{height: 60px;}
  footer .copyright{text-align: center;font-size: 12px; font-weight: 300;}
  footer .links{display:none;}
}
@media screen and (max-width: 360px) {
 
}
@media screen and (max-width: 320px) {
 
}
/* ********|| RESPONSIVE ENDS ||******** */

.pagenation-section{padding: 30px 0 60px;}
.pagenation-section .pagination-number{display: flex;justify-content: center;}
.pagenation-section .pagination-number .number{font-size: 16px;font-weight: 500;color: var(--shades02);cursor: pointer;}
.pagenation-section .pagination-number .number+ .number{margin-left: 15px;}
.pagenation-section .pagination-number .number.active{color: var(--color03);text-decoration: underline;}

.section-newsletter{position: relative; background-color: var(--color01); padding: 50px 0; z-index: 0;margin-top: 100px;}
.section-newsletter .newsletter-content{position: relative;}
.section-newsletter .newsletter-content .newsletter-title{position: relative;font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5; margin: 0;}
.section-newsletter .newsletter-content .newsletter-title span{color: var(--color02);}
.section-newsletter .newsletter-content .newsletter-text{font-family: 'Inter', sans-serif;font-size: 18px; font-weight: 300;color: var(--shades04); margin: 20px 0 0; line-height: 1.4;}
.section-newsletter .newsletter-content .form-content{position: relative; margin: 30px 0 0; display: flex; align-items: center; flex-wrap: wrap;}
.section-newsletter .newsletter-content .form-content .input-style{width: 100%; max-width: calc(100% - 96px);display: flex; height: 76px;}
.section-newsletter .newsletter-content .form-content .form-control{height: auto;border-radius: 20px;border:2px solid var(--color03);padding: 0 20px;font-size: 18px;background: transparent;}
.section-newsletter .newsletter-content .form-content .input-action{width: 76px; margin-left: 20px;}
.section-newsletter .newsletter-content .form-content .btn-form-submit{display: flex; align-items: center; background-color: var(--color03); border-radius: 20px; padding: 6px;}
.section-newsletter .newsletter-content .form-content .btn-form-submit .icon{display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 50%; background-color: var(--color03);color: var(--shades01); font-size: 36px;}
.section-newsletter .newsletter-content .form-content .btn-form-submit .title{padding: 0 16px; font-size: 18px; font-weight: 600; color: var(--shades01); display: none;}
.section-newsletter .newsletter-image{position: absolute;z-index: -1;width: 25%;bottom: 0;right: 10%;}
.section-newsletter .newsletter-image img{width: 100%; height: 100%; object-fit: contain;}


.cookies-section{background: rgb(16 0 0 / 80%);height: 100vh;position: fixed;width: 100%;z-index: 100;top: 0;left: 0;display: flex;flex-direction: column;justify-content: flex-end;padding: 45px 0;
}
.cookies-section .cookie-part{background: var(--shades01);border-radius: 20px;display: flex;align-items: center;padding: 35px 45px;justify-content: space-between;}
.cookie-part .cookie-content{display: flex;align-items: center;width: 60%;}
.cookie-part .cookie-action{display: flex;align-items: center;}
.cookies-section .cookie-part .cookie-icon{width: 70px;height: 70px;margin-right: 25px;}
.cookies-section .cookie-part .cookie-icon img{ }
.cookies-section .cookie-part .text{font-size: 18px;font-weight: 500;line-height: 1.5;color: var(--shades04);}
.cookies-section .cookie-part .text .link{color: var(--color03); }
.cookies-section .cookie-part .action-buttons{ display: flex;align-items: center;}
.cookies-section .cookie-part .action-buttons .button{ }
.cookies-section .cookie-part .action-buttons .button.accept{display: flex;align-items: center;box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);background: var(--color03);margin-right: 20px;justify-content: center;height: 55px;padding: 0 22px;border-radius: 50px;}
.cookies-section .cookie-part .action-buttons .button.decline{display: flex;align-items: center;box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);background: var(--shades01);margin-right: 20px;justify-content: center;height: 55px;padding: 0 30px;font-size: 16px;border-radius: 50px; }
.cookies-section .cookie-part .action-buttons .button.accept .icon{color: var(--shades01);margin-right: 8px;font-size: 20px; }
.cookies-section .cookie-part .action-buttons .button.accept .text{color: var(--shades01);font-size: 16px; }
.cookie-close{font-size: 24px;color: var(--shades04);margin-left: 15px;background: none;}
.cookie-close i{}






.error-page-section{display: flex;height: 100vh;align-items: center;justify-content: center;}
.error-page-section .error-image{}
.error-page-section .error-image img{width: 100%;}
.error-page-section .error-content .text-content{}
.error-page-section .error-content{display: flex;flex-direction: column;}
.error-page-section .error-content .description{font-size: 18px;line-height: 1.5;font-weight: 500;}
.error-page-section .error-content .text{font-size: 60px;font-weight: 600;}
.error-page-section .error-content .back-action{margin-bottom: 15px;}
.error-page-section .error-content .back-action .btn-back{background: var(--color03);color: var(--shades01);padding: 20px;border-radius: 10px;font-size: 20px;font-weight: 400;text-transform: capitalize;}
.error-page-section .error-content .image-part{margin-top: 20px;text-align: center;}
.error-page-section .text.responsive{display: none;}

.splash-section{background: var(--color03);width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;}
.splash-section .splash-inner{background: var(--color01);width: calc(100vw - 12px);height: calc(100vh - 12px);display: flex;align-items: center;justify-content: center;border-radius: 8px;}
.splash-section .logo{width: 400px;height:400px;}
.splash-section .logo img{width: 100%;height: 100%;}


/* / New / */

.sec-authentication{position:relative;display:flex;width:100%;min-height: 100vh;}
.sec-authentication .authentication-wrap{width: 100%;margin-right: 40%;background: var(--color01);}
.sec-authentication .authentication-wrap.signup-wrap{width: 100%;margin-left: 40%;margin-right: 0;}
.sec-authentication .authentication-img{height: 100%;width: 40%;position: absolute;top: 0;right: 0;}
.sec-authentication .authentication-img.signup-image{height: 100%;width: 40%;position: absolute;top: 0;left: 0;}
.sec-authentication .authentication-img img{width: 100%;height: 100%;object-fit: cover;}
.sec-authentication .authentication-hader{display: flex;align-items: center;justify-content: space-between;padding: 25px 40px 25px;}
.sec-authentication .authentication-hader .authentication-logo{width: 130px;height: 130px;}
.sec-authentication .authentication-hader .authentication-logo img{width: 100%;height: 100%;}
.sec-authentication .authentication-hader .became-title{display: flex;align-items: center;}
.sec-authentication .authentication-hader .became-title .text{color:#000;font-weight:400;font-size:16px;line-height:1.4;margin-right:5px}
.sec-authentication .authentication-hader .became-title .click-btn{color:var(--color03);font-weight:500;font-size:16px;line-height:1.4}
.sec-authentication .authentication-container{border-radius:10px;overflow:hidden;display:flex;width:45%;margin:0 auto;}
.sec-authentication .authentication-container .image-container .tag{color:var(--secondaryColor);font-size:18px;text-align:center;line-height:1.1;font-weight:500;position:absolute;bottom:35px;left:50%;transform:translateX(-50%)}
.sec-authentication .authentication-container .authentication-box{width:100%;display:flex;flex-direction:column;justify-content:center}
.sec-authentication .authentication-container .authentication-box .title{color:var(--color03);font-size:36px;text-align:center;line-height:1;font-weight:600;margin-bottom:10px;font-family: 'Playfair Display', serif;}
.sec-authentication .authentication-container .authentication-box .title.signup{color: #000;}
.sec-authentication .authentication-container .authentication-box .title span{color: var(--color03);}
.sec-authentication .authentication-container .authentication-box .sub-title{color:var(--shades07);font-size:18px;text-align:center;line-height:1;font-weight:400;margin: 30px 0;}
.sec-authentication .authentication-container .authentication-box .authentication-action{display: flex;align-items: center;justify-content: center;}
.sec-authentication .authentication-container .authentication-box .authentication-action .authentication-btn+.authentication-btn{margin-left: 20px;}
.sec-authentication .authentication-container .authentication-box .authentication-action .authentication-btn{width: 150px;height: 50px;border-radius: 5px;display: flex;align-items: center;justify-content: center;background-color: #fff;border: 1px solid var(--shades08);}
.sec-authentication .authentication-container .authentication-box .authentication-action .btn-text{font-size: 14px;font-weight: 500;color: #000;}
.sec-authentication .authentication-container .authentication-box .authentication-action .btn-icon{padding-right: 10px;}
.sec-authentication .authentication-container .authentication-box .custom-form-group{width:100%;position:relative;margin-bottom:20px}
.sec-authentication .authentication-container .authentication-box .custom-form-group .icon{width:40px;height:22px;display:flex;align-items:center;justify-content:center;font-size:16px;position:absolute;top:50%;transform:translateY(-50%);right:8px;color: var(--shades10);}
.sec-authentication .authentication-container .authentication-box .custom-form-group .form-control{width:100%;min-height:70px;background-color:#fff;color:var(--shades09);border:none;outline:none;padding:6px 55px 6px 12px;border-radius:10px;border: 1px solid var(--shades08);}
.sec-authentication .authentication-container .authentication-box .custom-form-group .form-control::placeholder{color:var(--shades09)!important}
.sec-authentication .authentication-container .authentication-box .continue-inner{position: relative;text-align: center;margin: 30px 0;z-index: 9;}
.sec-authentication .authentication-container .authentication-box .continue-inner span{background-color: var(--color01);padding: 10px;color: #000;font-size: 16px;font-weight: 400;}
.sec-authentication .authentication-container .authentication-box .continue-inner::after{z-index: -1; position: absolute;content: '';width: 100%;height: 1px;top: 9px;right: 0;background-color: var(--shades08);}
.sec-authentication .authentication-container .authentication-box .authentication-attr{margin-bottom:20px;display:flex;justify-content:space-between;align-items:center}
.sec-authentication .authentication-container .authentication-box .authentication-attr .btn-fogot-password{color:var(--color03);font-size:16px;font-weight:300}
.sec-authentication .authentication-container .authentication-box .log-action{margin: 35px 0 0;}
.sec-authentication .authentication-container .authentication-box .action-btn{color:var(--shades09);font-size:18px;font-weight: 500;background-color:transparent;border-radius:10px;height: 60px;display: flex;align-items: center;justify-content: center; width: 100%; border:1px solid var(--shades09);margin:0 auto}
.sec-authentication .authentication-container .authentication-box .action-btn.became{background-color: var(--color03);color: var(--shades01);border: 1px solid var(--color03);}
.sec-authentication .authentication-wrap .authentication-footer{display: flex;justify-content: center;margin: 40px 0 30px;color: var(--shades09);font-weight: 400;font-size: 16px;}
.sec-authentication .driver-notifications .pricing-toggle{display:flex;align-items:center;justify-content:center}
.sec-authentication .driver-notifications .pricing-toggle .slider.round{border-radius:34px}
.sec-authentication .driver-notifications .pricing-toggle .slider.round:before{border-radius:50%}
.sec-authentication .driver-notifications .pricing-toggle .switch{position:relative;display:inline-block;width: 43px;height: 22px;margin:0}
.sec-authentication .driver-notifications .pricing-toggle .switch input{opacity:0;width:0;height:0}
.sec-authentication .driver-notifications .pricing-toggle .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:var(--shades12);-webkit-transition:.4s;transition:.4s;border: 1px solid var(--shades11);}
.sec-authentication .driver-notifications .pricing-toggle .slider:before{position:absolute;content:"";height:18px;width:18px;left:1px;bottom:1px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.sec-authentication .driver-notifications .pricing-toggle input:checked + .slider{background-color: var(--color03);}
.sec-authentication .driver-notifications .pricing-toggle input:focus + .slider{box-shadow:0 0 1px var(--shades10)}
.sec-authentication .driver-notifications .pricing-toggle .toggle-text{margin:0 10px}
.sec-authentication .driver-notifications .pricing-toggle input:checked + .slider:before{left:21px}
.sec-authentication .became-title.responsive{display: none;}

.sec-authentication .became-title{display: flex;align-items: center;}
.sec-authentication .became-title .text{color:#000;font-weight:400;font-size:16px;line-height:1.4;margin-right:5px}
.sec-authentication .became-title .click-btn{color:var(--color03);font-weight:500;font-size:16px;line-height:1.4}

@media screen and (max-width:1600px) {
  .section-newsletter .newsletter-image{right: 5%;}

  .sec-authentication .authentication-container{width: 70%;}
}
@media screen and (max-width:1440px) {
  .section-newsletter .newsletter-image{right: 20px;}
}
@media screen and (max-width:1366px) {
  .section-newsletter .newsletter-content .newsletter-title{font-size: 60px;}
  .section-newsletter .newsletter-content .newsletter-text{font-size: 16px;}
  .section-newsletter .newsletter-content .form-content .input-style{height: 68px;}
  .section-newsletter .newsletter-content .form-content .btn-form-submit .icon{width: 56px; height: 56px;}
}
@media screen and (max-width: 1024px) {
  .section-newsletter{padding: 80px 0;}
  .section-newsletter .newsletter-content .newsletter-title{font-size: 50px;}
  .section-newsletter .newsletter-content .form-content .input-style{height: 62px;}
  .section-newsletter .newsletter-content .form-content .form-control{border-radius: 12px;}
  .section-newsletter .newsletter-content .form-content .input-action{width: 62px;}
  .section-newsletter .newsletter-content .form-content .btn-form-submit{border-radius: 12px;}
  .section-newsletter .newsletter-content .form-content .btn-form-submit .icon{width: 50px; height: 50px;font-size: 32px;}
}
@media screen and (max-width: 990px) {
  .section-newsletter{padding: 40px 0;margin-top: 0;}
  .section-newsletter .newsletter-content .newsletter-title{font-size: 40px;}
  .section-newsletter .newsletter-image{right:0;}

  .sec-authentication .authentication-hader{flex-direction: column;}
  .sec-authentication .authentication-hader .authentication-logo{margin-bottom: 15px;}
  .error-page-section .error-content .text-content{order: 2;display: flex;flex-direction: column;align-items: center;}
  .error-page-section .error-content .image-part{order: 1;}
  .error-page-section .text.responsive{display: block;font-size: 40px;font-weight: 600;margin: 0 auto;
    text-align: center;}
  .error-page-section .error-content .text{display: none;}
  .error-page-section .error-content .back-action{order: 2;}
  .error-page-section .error-content .description{text-align: center;margin: 20px;font-size: 14px;line-height: 1.5;font-weight: 400;}
  .splash-section .logo {width: 300px;height: 300px;}
}
@media screen and (max-width: 480px) {
  .section-newsletter .newsletter-image{width: 100%; max-width: 50%;}
  .section-newsletter .newsletter-image img{object-position: right bottom;}
  .section-newsletter .newsletter-content .newsletter-title{line-height:1.2;}
  .section-newsletter .newsletter-content .newsletter-text{font-size: 13px;font-weight: 400;max-width: 70%;}
  .section-newsletter .newsletter-content .form-content{width: 100%; max-width: 50%; }
  .section-newsletter .newsletter-content .form-content .input-style{max-width: 100%;height: 48px;}
  .section-newsletter .newsletter-content .form-content .form-control{padding: 0 10px;font-size: 14px;}
  .section-newsletter .newsletter-content .form-content .input-action{width: 100%;margin: 10px 0 0;}
  .section-newsletter .newsletter-content .form-content .btn-form-submit{ border-radius: 30px;padding: 4px;}
  .section-newsletter .newsletter-content .form-content .btn-form-submit .icon{width: 40px;height: 40px;font-size: 20px;background-color: var(--color01);color: var(--color03);}
  .section-newsletter .newsletter-content .form-content .btn-form-submit .title{display: block;font-size: 14px;}


  .sec-authentication .authentication-wrap{margin: 0 auto;width: calc(100% - 10px);height: calc(100% - 10px);border-radius: 12px;min-height: 100vh;display: flex;flex-direction: column;justify-content: center;}
  .sec-authentication .authentication-container{width: 90%;padding-bottom: 25px;}
  .sec-authentication .authentication-img{display: none;}
  .sec-authentication .authentication-hader .became-title{display: none;}
  .sec-authentication .became-title.responsive{    display: flex;justify-content: center;margin-top: 25px;}
  .sec-authentication .authentication-hader{justify-content: center;}
  .sec-authentication{background: var(--color03);padding: 5px 0;}
  .sec-authentication .authentication-wrap.signup-wrap{margin: 0 5px;}
  .sec-authentication .authentication-wrap .authentication-footer{width: 90%;text-align: center;margin: 25px auto;}
  .sec-authentication .authentication-container .authentication-box .title{font-size: 22px;}
  .sec-authentication .authentication-container .authentication-box .sub-title{margin: 20px 0 45px;}
}



/*=========|| Custom Alert Modal ||=========*/
.custom-alert-modal{}

.custom-alert-modal .modal-header{background-color:var(--color01);align-items:center;border-bottom:none}
.custom-alert-modal .modal-content{border-radius:15px;overflow:hidden;border:none}
.custom-alert-modal .modal-title{color:#000;font-weight:600;font-size:17px;line-height:1}
.custom-alert-modal .cnf-btn{background:var(--color03);color:var(--shades01);font-size:14px;text-transform:capitalize;padding:10px 16px;border-radius:8px;font-weight:400}
.custom-alert-modal .cnf-btn.alt{color:var(--color03);border:1px solid var(--color03);background:var(--shades01)}
.custom-alert-modal .cnf-btn:disabled{background:var(--color01)}











@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {
  .section-header .section-title{font-size: 40px;}
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {

}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 