.page-banner-section .section-header{position:relative;display:flex;margin:0 0 30px;justify-content:flex-start!important}
.section-header{position:relative;display:flex;margin:0 0 30px;justify-content:flex-start!important}

.page-banner-section .section-header .section-title{font-family:'Playfair Display',serif;color:var(--shades02);font-size:64px;font-weight:700;margin:0;line-height:1.5}
.page-banner-section .section-header .section-title span{color:var(--color02)}
.page-banner-section{margin:25px 0}
.page-banner-section .banner-section{border-radius:30px;height:240px;background:var(--color03);display:flex;align-items:center;justify-content:center;flex-direction:column}
.banner-section .breadcrumb-section{display:flex}
.banner-section .breadcrumb-section .link{font-size:14px;font-weight:300;color:var(--shades01)}
.banner-section .breadcrumb-section .seperator{color:var(--shades01);margin:0 5px}
.banner-section .breadcrumb-section .link .breadcrumb-link{color:var(--shades01)}
.banner-section .page-title .title{position:relative;font-family:'Playfair Display',serif;color:var(--shades01);font-size:64px;font-weight:700;line-height:1.5;margin:0}
.other-page-content{padding:60px 0}
.other-page-content .content{background:var(--shades05);padding:30px 15px;border-radius:30px}
.other-page-content .terms-content .content-number .content-item{list-style:decimal;font-size:20px;font-weight:700;color:var(--color02)}
.other-page-content .terms-content .content-number .content-item+.content-item{margin-top:35px}
.terms-content .content-number .content-item .descriptions{font-size:15px;font-weight:400;line-height:1.8;color:var(--shades03);margin:15px 0 0}
.content-list{margin-top:30px}
.content-list .content-text{position:relative;list-style:none}
.content-list .content-text+.content-text{margin-top:12px}
.content-list .content-text:before{content:'';position:absolute;width:10px;height:10px;border-radius:50%;background:var(--color02);left:-20px;bottom:50%;transform:translate(0,50%)}
.content-list .content-text .text{font-size:15px;font-weight:400;line-height:1.8;color:var(--shades03);margin:15px 0 0}

@media screen and (max-width:1600px) {}

@media screen and (max-width:1440px) {}

@media screen and (max-width:1366px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 990px) {

  /* .section-header .section-title{font-size: 30px;text-align: center;}  */
  .section-header{justify-content:center}


}

@media screen and (max-width: 480px) {
  .banner-section .breadcrumb-section{display:none}
  .banner-section .page-title .title{font-size:30px}
  .page-banner-section .container{padding:0}
  .page-banner-section .banner-section{margin-top:-150px;border-radius:0 0 30px 30px}
  .banner-section .page-title{padding-top:65px}


}