.section-header{position: relative; display: flex; justify-content: center; margin: 0 0 30px;}
.section-header .section-title{font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5;}
.section-header .section-title span{ color: var(--color02);}

.section-about .about-watermark{position: absolute;right: 0;top: 0;height: 300px;width: 300px;z-index: 0;opacity: 0.06;}
.section-about .about-watermark img{width: 100%;height: 100%;}

.section-banner{position: relative;padding: 180px 0 40px;margin-top: -140px;height: 100vh; display: flex; align-items: center; background-color: var(--color01); overflow: hidden; z-index: 0;}
.section-banner:before{content:'';position: absolute;top: 0;left: 60%;width: 100%;height: 100%;background-color: var(--color02);z-index: -1;transform: skewX(-40deg);}
.section-banner .banner-image{ position: absolute; width: 100%;max-width: 600px;margin: 0 auto;top: 50%; right: -100%; transform: translateY(-50%);}
.section-banner .banner-image img{width: 100%; height: 100%; object-fit: contain;}
.section-banner .banner-content{position: relative;width: 100%;max-width: 660px;}
.section-banner .banner-title{font-size: 80px;font-family: 'Playfair Display', serif;font-weight: 700;color: var(--shades02);margin: 0;}
.section-banner .banner-title span{text-transform: uppercase;display: block;color: var(--color03);}
.section-banner .banner-text{color: var(--shades03);font-weight: 300;font-size: 18px;font-family: 'Inter', sans-serif;line-height: 1.4;margin: 30px 0 0;}
.section-banner .banner-action{display: flex; align-items: center; margin: 40px 0 0;}
.section-banner .btn-action{display: flex; align-items: center; background-color: var(--color02); border-radius: 60px; padding: 6px; min-width: 220px;}
.section-banner .btn-action .icon{display: flex; align-items: center; justify-content: center; width: 56px; height: 56px; border-radius: 50%; background-color: var(--color01);color: var(--color02); font-size: 28px;}
.section-banner .btn-action .title{padding: 0 16px; font-size: 18px; font-weight: 600; color: var(--shades01);}

.section-process{position: relative;padding:60px 0;display: flex; align-items: center; background-color: var(--shades01); }
.process-list{position: relative; display: flex; margin: 0;padding: 30px 0; border: solid var(--color01); border-width: 2px 0;}
.process-list .item{width: 100%; max-width:100%;margin: 0; padding: 30px 15px;}
.process-list .item .item-inner{display: flex;  align-items: center; padding: 0;}
.process-list .item .item-icon{width: 80px; height: 80px; display: flex; justify-content: center; align-items: center; border-radius: 50%; background-color: var(--color03); color: var(--shades01); font-size: 36px;overflow: hidden;}
.process-list .item .item-icon img{width: 100%;height: 100%;object-fit: cover;}
.process-list .item .item-content{width: 100%; max-width: calc(100% - 100px); margin-left: 20px;}
.process-list .item .item-title{font-weight: 600; font-size: 24px; font-family: 'Playfair Display', serif; color: var(--shades02);}
.process-list .item .item-text{font-weight: 300; font-size: 14px;color: var(--shades03); line-height: 1.4; margin: 10px 0 0;  text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 5;-webkit-box-orient: vertical;display: -webkit-box;}

.section-services{position: relative; background-color: var(--shades05); padding: 60px 0;}
.service-slider{position: relative;}
.service-slider .service-item{width: 100%; padding: 20px 0;}
.service-slider .service-item .item-inner{position: relative; background-color: var(--color01); border-radius: 40px; padding: 40px; text-align: center;} 
.service-slider .service-item .item-image{width: 240px; height: 240px; margin: 0 auto 40px; position: relative; z-index: 0;display: flex;align-items: center;justify-content: center;}
.service-slider .service-item .item-image.meal-box:before{width: 100%;height: 100%;}
.service-slider .service-item .item-image.meal-box:after{content: '';width: 100%;height: 100%;position: absolute;background: rgba(0, 0, 0, 0.25);backdrop-filter: blur(7px);left: 0;border-radius: 50%;}
.service-slider .service-item .coming-soon-text{position: absolute;top: 50%;z-index: 1;display: flex;align-items: center;justify-content: center;margin: 0 auto;transform: translateY(-50%);font-size: 30px;color: var(--shades01);font-weight: 600;line-height: 1.3;letter-spacing: 1px;padding: 15px;}
.service-slider .service-item .item-image:before{content:'';position: absolute; width: calc(100% - 50px); height: calc(100% - 50px); background-color: var(--color04); bottom: 0; left: 50%; transform: translateX(-50%); border-radius: 50%; z-index: -1;}
.service-slider .service-item .item-image img{width: 100%; height: 100%; object-fit: contain;}
.service-slider .service-item .item-title{font-size: 24px; font-weight: 700; color: var(--color02); text-transform: uppercase; line-height: 1.4; margin: 0;}
.service-slider .service-item .item-text{ margin: 20px 0 0; font-size: 16px; font-weight: 500; color: var(--shades04);}
.service-slider .swiper-pagination.swiper-pagination-bullets{ bottom: 0;}
.service-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}

.section-about{position: relative; background-color: var(--color05); padding: 60px 0;margin: 0 0 320px;}
.section-about .about-content{position: relative;z-index: 1;}
.section-about .about-content .about-title{font-family: 'Playfair Display', serif; color: var(--color02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5; margin: 0;}
.section-about .about-content .about-image{position: absolute;width: 100%;max-width: 70%;top: 0;left: calc(-70% - 30px);height: 500%; max-height: 640px;}
.section-about .about-content .about-image img{width: 100%; height: 100%; object-fit: contain;object-position: left;}
.section-about .about-content .about-text{font-size: 15px; font-weight: 400; line-height: 1.8; color: var(--shades03); margin: 30px 0 0;}
.section-about .about-content .about-action{display: flex; align-items: center;margin: 30px 0 0;}
.section-about .about-content .btn-action{height: 50px; display: flex; justify-content: center; align-items: center; padding: 0 16px; background-color: var(--color02); color: var(--shades01); border-radius: 10px;font-family: 'Inter', sans-serif; font-weight: 600; font-size: 18px;}

.section-benefits{position: relative; background-color: var(--shades05); padding: 60px 0; z-index: 0; margin: 0 0 160px;}
.section-benefits .benefits-content{position:relative;}
.section-benefits .benefits-title{font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5; margin: 0;}
.section-benefits .benefits-title span{color: var(--color02);}
.section-benefits .benefits{position: relative; margin: 30px 0 0;}
.section-benefits .benefits .item{display: flex;}
.section-benefits .benefits .item+.item{margin: 30px 0 0;}
.section-benefits .benefits .item .item-icon{width: 40px; height: 40px; border-radius: 10px; background-color: var(--color02); display: flex; align-items: center; justify-content: center; color: var(--shades01); font-size: 24px;margin-bottom: auto;}
.section-benefits .benefits .item .item-text{font-size: 20px; font-weight: 500; color: var(--shades04);line-height: 1.8; width: 100%; max-width: calc(100% - 60px); margin-left: 20px;}
.section-benefits .benefits-image{position: absolute;top: 0;right: 10%;z-index: -1;height: calc(100% + 160px);}
.section-benefits .benefits-image img{ width: 100%; height: 100%; object-fit: contain;}

.section-testimonial{position: relative; background-color: var(--shades01); padding: 60px 0;}
.section-testimonial .testimonial-header{position: relative; display: flex; justify-content: center; margin: 0 0 30px;}
.section-testimonial .testimonial-header .testimonial-title{position: relative;font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5; margin: 0;}
.section-testimonial .testimonial-header .testimonial-title span{color: var(--color02);}
.section-testimonial .testimonial-header .title-image{position: absolute;height: 150px;top: 50%;transform: translateY(-50%);left: -240px;}
.testimonial-slider .swiper-slide{height: unset;}

.section-testimonial .testimonial-header .title-image img{width: 100%; height: 100%; object-fit: contain;}
.section-testimonial .testimonial-slider-wrap{padding:0;}
.testimonial-slider{position:relative;}
.testimonial-slider .swiper-wrapper{position:relative;}
.testimonial-slider .swiper-button-prev{font-size: 30px;color: var(--color03);}
.testimonial-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.testimonial-slider .swiper-button-prev:after{display:none;}
.testimonial-slider .swiper-button-next{font-size: 30px;color: var(--color03);}
.testimonial-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.testimonial-slider .swiper-button-next:after{display:none;}
.testimonial-slider .testimonial-item{width: 100%; padding: 40px 20px;height: 100%;}
.testimonial-slider .testimonial-item .testimonial-inner{position: relative; background-color: var(--color01); border-radius: 40px; padding: 40px; text-align: center;height: 100%;}
.testimonial-slider .testimonial-item .testimonial-icon{position: absolute;font-size: 60px;color: var(--color03);top: -10px;right: -10px;}
.testimonial-slider .testimonial-item .testimonial-title{font-family: 'Playfair Display', serif; font-size: 20px; font-weight: 700;color: var(--color02); margin: 0; line-height: 1.4;}
.testimonial-slider .testimonial-item .testimonial-text{font-family: 'Inter', sans-serif;font-size: 18px; font-weight: 300;color: var(--shades04); font-style: italic; margin: 20px 0 0;line-height: 1.2;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 5;-webkit-box-orient: vertical;display: -webkit-box;}
.testimonial-slider .swiper-pagination.swiper-pagination-bullets{ bottom: 0; display: none;}
.testimonial-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}



@media screen and (max-width:1600px) {
  .section-banner .banner-image{ max-width: 460px;}
  .section-about{margin: 0 0 280px;}
  .section-benefits{margin: 0 0 120px;}
  .section-benefits .benefits-image{right: 5%;height: calc(100% + 120px);}
  .testimonial-slider .testimonial-item{padding: 40px 10px;}
}
@media screen and (max-width:1440px) {
  .section-benefits .benefits-image{right: 20px;}
  .testimonial-slider .testimonial-item{padding: 40px 20px;}
}
@media screen and (max-width:1366px) {
  .section-banner .banner-image{max-width: 400px;}
  .section-banner .banner-title{font-size: 60px;}
  .section-banner .banner-text{font-size: 16px;}
  .section-banner .btn-action .icon{width: 50px; height: 50px;}
  .section-header .section-title{font-size: 60px;}
  .section-about{margin: 0 0 180px;}
  .section-about .about-content .about-title{font-size: 60px;}
  .section-about .about-content .about-image{max-height: 540px;}
  .section-benefits{margin: 0 0 100px;}
  .section-benefits .benefits-image{height: calc(100% + 100px);}
  .section-benefits .benefits-title{font-size: 60px;}
  .section-benefits .benefits .item{align-items: center;}
  .section-benefits .benefits .item .item-text{font-size: 16px; line-height: 1.4;font-weight: 400;}
  .section-testimonial .testimonial-header .testimonial-title{font-size: 60px;}
  .section-testimonial .testimonial-header .title-image{height: 120px;left: -200px;}
  .testimonial-slider .testimonial-item .testimonial-icon{font-size: 50px;}
  .testimonial-slider .testimonial-item .testimonial-text{font-size: 16px;}
  
}
@media screen and (max-width: 1024px) {
  .section-banner .banner-title{font-size: 50px;}
  .section-banner .banner-text{font-size: 15px;}
  .section-banner .btn-action{padding: 4px;min-width: 180px;}
  .section-banner .btn-action .icon{width: 40px; height: 40px;font-size: 24px;}
  .section-banner .btn-action .title{font-size: 16px; font-weight: 500;}
  .section-process{padding: 40px 0;}
  .process-list{padding: 15px 0;}
  .process-list .item .item-icon{width: 60px; height: 60px;font-size: 30px;}
  .process-list .item .item-content{max-width: calc(100% - 80px);}
  .process-list .item .item-title{font-size: 20px;}
  .process-list .item .item-text{font-size: 13px;}
  .section-services{padding: 40px 0;}
  .section-header .section-title{font-size: 50px;}
  .service-slider .service-item .item-inner{padding: 20px;}
  .service-slider .service-item .item-image{width: 200px; height: 200px;margin: 0 auto 20px;}
  .service-slider .service-item .item-title{font-size: 20px;}
  .service-slider .service-item .item-text{margin: 10px 0;font-size: 14px; font-weight: 400;}
  .section-about{padding: 40px 0;margin: 0 0 120px;}
  .section-about .about-content .about-title{font-size: 50px;}
  .section-about .about-content .about-text{margin: 20px 0 0;font-size: 14px;}
  .section-about .about-content .about-image{max-height: 440px;}
  .section-benefits .benefits-title{font-size: 50px;}
  .section-benefits .benefits .item .item-icon{width: 30px; height: 30px; font-size: 20px;border-radius: 8px;}
  .section-benefits .benefits .item .item-text{font-size: 14px;}
  .section-testimonial{padding: 40px 0;}
  .section-testimonial .testimonial-header .testimonial-title{font-size: 50px;}
  .section-testimonial .testimonial-header .title-image{height: 100px;left: -160px;}
  .testimonial-slider .testimonial-item{padding: 40px 10px;}
  .testimonial-slider .testimonial-item .testimonial-inner{padding: 30px;}
  .testimonial-slider .testimonial-item .testimonial-icon{font-size: 40px;}
  .testimonial-slider .testimonial-item .testimonial-text{font-size: 14px;}
  .section-benefits .benefits-image {height: 460px;}
}
@media screen and (max-width: 990px) {
  .section-banner .banner-content{max-width:100%;margin-top: 20px;}
  .section-banner .banner-text{margin: 20px 0;font-size: 18px;max-width: 600px;}
  .section-banner .banner-image{position: relative; top: auto; right: auto; transform: none;max-width: 380px;}
  .section-banner .banner-action{justify-content: center;margin: 60px 0 0;}
  .section-banner .btn-action{background-color: var(--color01);}
  .section-banner .btn-action .icon{background-color: var(--color03); color: var(--shades01);}
  .section-banner .btn-action .title{font-weight: 600; color: var(--color03);}
  .section-process{padding: 0;}
  .section-process .container{max-width: 100%; padding: 0;}
  .process-list{overflow: auto; margin:0;}
  .process-list .swiper-button-prev{font-size:30px;color:var(--color03); left: 0;}
  .process-list .swiper-button-prev:before{content:'\f053';font-family:'Font Awesome 6 Pro'}
  .process-list .swiper-button-prev:after{display:none}
  .process-list .swiper-button-next{font-size:30px;color:var(--color03); right: 0;}
  .process-list .swiper-button-next:before{content:'\f054';font-family:'Font Awesome 6 Pro'}
  .process-list .swiper-button-next:after{display:none}
  .process-list .swiper-button-disabled{display:none;}

  .process-list .item{width: 100%; }
  .section-header .section-title{font-size: 40px;}
  .service-slider .service-item .item-image{width: 180px;height: 180px;margin: 0 auto 10px;}
  .section-about{margin:0;}
  .section-about .about-content .about-title{font-size: 40px; text-align: center;}
  .section-about .about-content .about-image{position: relative; left: auto; top: auto; height: auto; max-height: 100%; max-width: 380px; margin: 20px auto;}
  .section-about .about-content .about-text{font-size: 16px;}
  .section-about .about-content .about-action{justify-content: center;}
  .section-benefits{margin:0;}
  .section-benefits .benefits-title{font-size: 40px;}
  .section-benefits .benefits .item+.item{margin: 10px 0 0;}
  .section-benefits .benefits-image{right:0; height: 360px;top: 0;}
  .section-testimonial .testimonial-header .title-image{height: 80px; left: -120px;}
  .section-testimonial .testimonial-header .testimonial-title{font-size: 40px;}
  .testimonial-slider .testimonial-item{padding:20px;}

  
}
@media screen and (max-width: 480px) {
  .section-banner{margin-top: -120px;padding: 140px 0 40px;}
  .section-banner:before{left: 68%; width: 200%;background-color: var(--color03);}
  .section-banner .banner-image{max-width: 100%; height: 50vh;}
  .section-banner .banner-title{font-size: 35px;}
  .section-banner .banner-text{font-size: 12px;max-width: 100%; font-weight: 400;}
  /* .process-list .item{max-width: calc(60% - 20px);min-width: calc(60% - 20px);} */
  .process-list .item .item-icon{width: 60px;height: 60px;font-size: 24px;overflow: hidden;border-radius: 50%;}
  .process-list .item .item-icon img{width: 100%;height: 100%;object-fit: cover;}
  .process-list .item .item-content{max-width: calc(100% - 70px);margin-left: 10px;}
  .process-list .item .item-title{font-size: 16px; font-weight: 700;}
  .process-list .item .item-text{font-size: 11px;line-height: 1.2;font-weight: 400;margin: 0;}
  .section-header .section-title{line-height:1.2; text-align: center;}
  .service-slider.swiper{padding: 0 0 20px;}
  .section-about .about-content .about-text{font-size: 14px;}
  .section-about .about-content .about-image{margin: 40px auto;}
  .section-benefits .benefits-title{line-height:1.2; text-align: center;}
  .section-benefits .benefits-title span{display: block;}
  .section-benefits .benefits-image{display: none;}
  .section-benefits .benefits .item+.item{margin:20px 0 0;}
  .section-testimonial{padding: 80px 0 40px;}
  .section-testimonial .testimonial-header .testimonial-title{line-height:1.2; text-align: center;position: unset;}
  .section-testimonial .testimonial-header .title-image{height: 60px;left: auto;right: 0;top: -60px;transform: none;}
  .testimonial-slider.swiper{padding: 0 0 20px;}
  .testimonial-slider .swiper-pagination.swiper-pagination-bullets{ display: block;}
  .testimonial-slider .swiper-button-prev, .testimonial-slider .swiper-button-next{ display: none;}
  

  .section-about .about-watermark{width: unset;opacity: 0.04;left: 0;}
 
}