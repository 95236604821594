.page-banner-section.order-details .banner-section{position:relative}
.banner-back .back{position:absolute;left:20px;top:50%;transform:translateY(-50%)}
.banner-back .back .icon{font-size:35px;color:var(--shades01)}
.ordering-section .order-part{background:var(--color01);padding:50px;border-radius:30px}
.ordering-section .order-part .order-id{font-weight:700;font-size:35px;color:var(--color03);margin-bottom:15px}
.ordering-section .order-part .order-date{font-size:18px;line-height:1.4;font-weight:400}
.order-details-table{margin-top:25px;display:flex;justify-content:space-between}
.order-details-table .order-item-details{width:35%}
.order-details-table .order-item-details .order-no{font-size:18px;font-weight:600;color:var(--color03);text-transform:capitalize;margin-bottom:10px}
.orders .order-item .order-list{display:flex;justify-content:space-between}
.orders .order-item .order-list+ .order-list{margin-top:10px}
.orders .order-item .order-list .order-text{font-size:14px;font-weight:400}
.orders .order-item .order-list .order-text span{margin-left:4px}
.orders .order-item .order-list .price{font-size:16px;font-weight:600;color:var(--color03)}
.order-address{width:30%}
.order-payment{width:20%}
.order-address .title,.order-payment .title{font-size:18px;font-weight:600;color:var(--color03);text-transform:capitalize;margin-bottom:10px}
.order-address .address,.order-payment .payment-type{font-size:16px;line-height:1.4;font-weight:400}
.order-progress-section{margin-top:35px;background-image:url(../../../../public/assets/img/bg-star.svg)}
.order-progress-section .step-part{display:flex;margin-bottom:50px}
.order-progress-section .step-part .step{width:25%;max-width:calc(25% - 10px);height:10px;background:var(--shades01);margin:5px;border-radius:10px}
.order-progress-section .step-part .step.active{background:var(--color03)}
.step-result-part{text-align:center}
.step-result-part .step-result{display:none}
.step-result-part .step-result.active{display:block}
.step-result-part .step-result .step-title{font-family:'Playfair Display',serif;text-align:center;font-weight:700;font-size:50px;margin-bottom:30px}
.step-result-part .step-result .step-image.deliver{display:flex;width:100%;justify-content:space-between;margin-bottom:40px}
.step-result-part .step-result .step-image .img-wrap{width:40%}
.step-result-part .step-result .description{font-size:16px;font-weight:400;line-height:1.4;width:50%;margin:0 auto}
.step-image .delivery-map .map-image{width:600px;height:400px;overflow:hidden;border-radius:30px;box-shadow:2px 4px 8px rgb(125 125 125 / 30%)}
.step-image .delivery-map .map-view-btn{margin-top:-60px}
.step-image .delivery-map .map-view-btn .view-btn{background:var(--color03);color:var(--shades01);padding:12px 20px;border-radius:10px;text-transform:capitalize;font-size:16px;font-weight:400}



.orders .order-item .order-list .order-text .sub-text {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Playfair Display',serif;
    color: var(--secondary);
    text-transform: capitalize;
}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {

    .banner-back .back{top: 64% !important;}
    .ordering-section .order-part{padding: 30px;}
    .order-details-table{flex-wrap: wrap;}
    .order-details-table .order-item-details{width: 100%;}
    .order-address{width: 100%;margin-top: 20px;}
    .order-payment{width: 100%;margin-top: 15px;}
    .ordering-section .order-part .order-id{font-size: 25px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {
    .section-header .section-title{font-size: 25px !important;}
}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 