.deals-section{padding: 60px 0;}
.deals-section .deals-part{display: flex;padding-left: var(--padding);}
.deals-content{padding: 0 15px;width: 480px;}
.deals-section .section-header{justify-content: flex-start;margin: 0;}
.deals-section .descriptions{padding-bottom: 10px;font-size: 16px;line-height: 1.5;font-weight: 300;}
.deals-slider-section{width: 100%;max-width: calc(100% - 480px);}
.deals-slider-wrap{}
.deals-slider-wrap .deals-slider{padding: 0 0 70px;}
.deals-slider-wrap .deals-slider .deals-item{border:2px solid var(--color03);overflow:hidden;border-radius:40px;background:var(--color01)}
.deals-slider-wrap .deals-slider .deals-item .title{text-align:center;font-size:40px;padding:20px;line-height:1;font-weight:500;color:var(--color03);text-transform:capitalize}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-list{margin-top:25px;padding:20px}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-list .menu-item{display:flex;position:relative;align-items:center}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-list .menu-item+ .menu-item:before{content:'\2b';font-family:"Font Awesome 6 Pro";font-size:28px;position:absolute;font-weight:600;color:var(--color03);top:-15px;line-height:1;left:70px}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-list .menu-item .menu-img{width:170px;height:150px;overflow:hidden}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-list .menu-item .menu-img img{width:100%;height:100%;object-fit:contain}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-content{width:100%;max-width:calc(100% - 190px);margin-left:20px}
.deals-slider-wrap .deals-slider .deals-item .deals-menu-content .title-menu{font-size:20px;font-weight:700;font-family:'Nunito',sans-serif;color:var(--shades02)}
.deals-slider-wrap .deals-slider .deals-item .deals-price{background:var(--color03);color:var(--shades01);text-align:center;padding:24px 16px;font-size:20px;font-weight:600}
.deals-slider-wrap .deals-slider .deals-item .deals-price span{margin-left: 15px;}

.new-items-header{width: 30%;margin: 0 auto;text-align: center;margin-bottom: 25px;}
.new-items-header .descriptions{padding-bottom: 10px;font-size: 16px;line-height: 1.5;font-weight: 300;}
.deals-slider-wrap .deals-slider .deals-item .ingredients{position:relative;margin-top: 15px;}
.deals-slider-wrap .deals-slider .deals-item .ingredients .list-contains{ display: flex;align-items: center;}
.deals-slider-wrap .deals-slider .deals-item .ingredients .list-contains .icon{width:35px;height:35px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;border:2px solid var(--shades01);font-size:14px;padding: 5px;}
.deals-slider-wrap .deals-slider .deals-item .ingredients .list-contains .icon img{display: block; width: 100%; object-fit: contain; filter: brightness(0) invert(1);}
.deals-slider-wrap .deals-slider .deals-item .ingredients .list-contains .icon+.icon{margin-left:4px}

.deals-slider-wrap .swiper-button-prev , .deals-slider-wrap .swiper-button-next{font-size: 20px;color: var(--shades01);top: unset;bottom: 0;background: var(--color03);width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;border-radius: 50%;}
.deals-slider-wrap .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.deals-slider-wrap .swiper-button-prev:after{display:none;}
.deals-slider-wrap .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.deals-slider-wrap .swiper-button-next:after{display:none;}
.deals-slider-wrap .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.deals-slider-wrap .swiper-pagination{z-index: 4;}

.new-item-section {background: var(--shades05);padding: 60px 0;}
.new-item-section .menu-list{display:flex;position:relative;flex-wrap:wrap;margin:0 -30px}
.new-item-section .menu-inner{transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%}
.new-item-section .menu-list .menu-item{margin: 30px;width: 33.33%;position: relative;max-width: calc(33.33% - 60px);}
.new-item-section .menu-list .menu-item .menu-inner:hover{background:var(--color03);cursor:pointer}

.new-item-section .menu-list .menu-item .menu-inner .ingredients{position:relative;right:0}
.new-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains{right:-40px;position:absolute}
.new-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon{width:35px;height:35px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;border:2px solid var(--shades01);font-size:14px}
.new-item-section .menu-list .menu-item .menu-inner .ingredients .list-contains .icon+.icon{margin-top:4px}
.new-item-section .menu-list .menu-item .menu-inner .name{font-family:'Nunito',sans-serif;font-size:30px;font-weight:700;margin-bottom: 15px;}
.new-item-section .menu-list .menu-item .menu-inner .menu-image{    transition: all ease .3s;pointer-events: none;transform: translate(-20%,0) scale(1);pointer-events: none;width: 400px;height: 400px; }
.new-item-section .menu-list .menu-item .menu-inner:hover .menu-image{transition: all ease .3s;margin-left: 0;transform: translate(-20%,-20%) scale(0.6);pointer-events: none;
}
.new-item-section .menu-list .menu-item .menu-inner .menu-image img{width:100%;height:100%;object-fit:contain}
.new-item-section .menu-item .menu-inner .description{position: absolute;opacity: 0;padding-right: 120px;color: var(--shades01);line-height: 2;font-size: 15px;bottom: 40px;z-index: 1;height: 150px;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 5;-webkit-box-orient: vertical;display: -webkit-box;transform: translateY(150px);visibility: hidden;transition: all ease 0.3s;}
.new-item-section .menu-item .menu-inner:hover .description{opacity: 1;transform: translateY(0);visibility: visible;}
.new-item-section .menu-item .menu-inner:hover .name{color:var(--color01)}

.new-item-section .menu-list .description p {}

.new-item-section .menu-order-action {}

.new-item-section .menu-order-action .add-to-cart{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:0;right:0}
.new-item-section .menu-order-action .add-to-cart .icon{font-size:24px;margin-bottom:10px}
.new-item-section .menu-order-action .add-to-cart .price{font-size:20px;font-weight:600}
.new-item-section .menu-order-action .add-to-cart .text{font-size:14px;text-transform:uppercase;margin-top:5px}
.new-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart{background:var(--color01)}
.new-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .price{color:var(--shades02);font-weight:700}
.new-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .text{color:var(--color03);font-weight:400}
.new-item-section .menu-item .menu-inner:hover .menu-order-action .add-to-cart .icon{color:var(--color03)}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {
  .new-item-section .menu-list{margin: 0 -15px;}
  .new-item-section .menu-list .menu-item{width: 50%;max-width: calc(50% - 60px);}
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .deals-section .deals-part{flex-wrap: wrap;flex-direction: column;}
  .new-items-header , .deals-content{width: 100%;}
  .deals-section .deals-img{display: none;}
  .deals-slider-section{max-width: 100%;}
  .new-item-section .menu-list{margin: 0 -15px;}
  .new-item-section .menu-list .menu-item{width: 100%;max-width: calc(100% - 60px);}
  .new-item-section .menu-list .menu-item .menu-inner .menu-image{width: 520px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .new-item-section .menu-list .menu-item .menu-inner .menu-image{width: 250px;}
	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 