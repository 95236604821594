.faq-section{padding:60px 0}
.faqitem.active .faq-item-title{background:var(--color03);color:var(--color01);border-radius: 10px 10px 0 0;}
.faqitem.active .faq-item-title::after{color:var(--shades01);transform:translateY(-50%) rotate(90deg)}
.faqitem.active .faq-item-title .text{color:var(--color01)}
.faqitem+ .faqitem{margin-top:15px}
.faqitem .faq-item-title{position:relative;background:var(--color01);border-radius:10px;line-height:1.5;padding:20px 65px 20px 20px}
.faqitem .faq-item-title::after{content:"\f054";font-family:'Font Awesome 6 Pro';position:absolute;top:50%;transform:translateY(-50%);right:25px;font-size:24px;font-weight:800;color:var(--color03)}
.faqitem .faq-item-title .text{margin-bottom:0;color:var(--color03);font-weight:600;font-size:18px}
.faqitem .faq-content{background: var(--color03);padding: 40px 22px 30px 22px;border-radius:14px;display:none;margin-top: -18px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.faqitem.active .faq-content{display:block}
.faqitem .faq-content .descriptions{font-size:15px;line-height:1.4;font-weight:300;color: var(--shades01);margin-bottom: 0;}
.faqitem .faq-content .title-part{margin-bottom:25px}
.faqitem .faq-content .title-part .content-title{font-size:16px;font-weight:600;color:var(--color03)}
.faqitem .faq-content .description-list{margin-bottom:25px}
.faqitem .faq-content .description-list ul{padding-left:18px}
.faqitem .faq-content .description-list ul li{font-size:15px;line-height:1.4;position:relative;list-style:none;font-weight:300}
.faqitem .faq-content .description-list ul li:before{content:'';position:absolute;width:10px;height:10px;background:var(--color03);border-radius:50%;left:-17px;top:50%;transform:translateY(-50%)}
.faqitem .faq-content .description-list ul li+ li{margin-top:10px}
.faqitem .faq-content .apply-action{display:flex;justify-content:center;margin-top:45px}
.faqitem .faq-content .apply-action .apply{background:var(--color03);color:var(--shades01);height:44px;padding:0 45px;text-transform:capitalize;font-weight:600;border-radius:10px;width:240px}