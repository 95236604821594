.refer-section{padding: 60px 0;}
.refer-section .refer-part{background: var(--color01);padding: 40px;border-radius: 30px;}
.refer-section .refer-part .text-refer{text-align: center;margin-bottom: 25px;font-size: 36px;line-height: 1.4;font-weight: 500;}
.refer-section .refer-part .text-refer span{color: var(--color03);}
.refer-section .refer-form{}
.refer-section .refer-form .input-style{margin-top: 25px;}
.refer-section .refer-form .input-style .form-label{font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.refer-form .input-style .form-control{border: 1px solid var(--color03);height: 44px;border-radius: 10px;}
.refer-form .form-action{margin: 40px 0;text-align: center;}
.refer-form .form-action .btn-refer{background: var(--color03);color: var(--shades01);font-size: 18px;text-transform: capitalize;padding: 12px 30px;border-radius: 8px;font-weight: 400;}

.or-text{text-align: center;text-transform: capitalize;font-weight: 600;font-size: 16px;color: var(--color03);position: relative;}
.or-text:before{content: '';position: absolute;width: 100px;height: 1px;background: rgb(0 0 0 / 20%);top: 50%;right: 116px;}
.or-text:after{content: '';position: absolute;width: 100px;height: 1px;background: rgb(0 0 0 / 20%);top: 50%;left: 116px;}

.refer-section .link-share-section{margin-top: 35px;}
.refer-section .link-share-section .link-copy{}
.refer-section .link-share-section .link-copy .form-label {font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.refer-section .link-share-section .link-copy .form-control {border: none;background: transparent;height: auto;border-radius: 10px;}
.refer-section .copy-part{display: flex; border: 1px solid var(--color03);background: var(--shades01);height: 44px;border-radius: 10px;}
.refer-section .link-share-section .link-copy .input-group-append {}
.refer-section .link-share-section .link-copy .input-group-append .input-group-text{background: var(--color03);border: none;font-size: 16px;font-weight: 400;color: var(--shades01);padding: 10px 20px;border-radius: 0 8px 8px 0;}
.refer-section .link-share-section .btn-share{display: flex;align-items: center;width: 100%;background: var(--color03);color: var(--shades01);border-radius: 10px;text-align: center;justify-content: center;font-size: 18px;padding: 10px;text-transform: capitalize;font-weight: 500;}
.refer-section .link-share-section .btn-share .icon{width: 33px;margin-left: 10px;height: 33px;}
.refer-section .link-share-section .btn-share .icon img{width: 100%;height: 100%;}

.refer-img{width: 100%;height: 100%;}
.refer-img img{width: 100%;height: 100%;}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .refer-section .refer-part .text-refer{font-size: 20px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}
