.blog-slider-section{}
.blog-slider-section .blog-slider{padding: 60px 70px;}
.blog-slider-section .blog-slider .blog-item{display: flex;background: var(--color03);overflow: hidden;border-radius: 30px;align-items: center;}
.blog-slider .blog-item .blog-slider-content{width: 100%;max-width: calc(100% - 620px);margin-right: 20px;padding: 40px;}
.blog-item .blog-slider-content .title{font-size: 40px;line-height: 1.4;color: var(--shades01);font-weight: 600;text-transform: uppercase;}
.blog-item .blog-slider-content .reading-time{display: flex;margin-top: 25px;align-items: center;}
.blog-slider-content .reading-time .icon{color: var(--shades01);font-size: 16px;margin-right: 10px;}
.blog-slider-content .reading-time .text{font-size: 14px;font-weight: 300;color: var(--shades01);text-transform: capitalize;}
.blog-slider-content .link{margin-top: 30px;}
.blog-slider-content .link .action{background: var(--color01);color: var(--color03);font-weight: 600;height: 44px;display: inline-flex;align-items: center;justify-content: center;text-align: center;padding: 0 25px;border-radius: 10px;}
.blog-slider-image{}
.blog-slider-image .img-wrap{width: 600px;height: 540px;overflow: hidden;}
.blog-slider-image .img-wrap img{width: 100%;height: 100%;object-fit: cover;}
.blog-slider-section .swiper-wrapper{position:relative;}
.blog-slider-section .swiper-button-prev{font-size: 24px;color: var(--shades01);background: var(--color03);width: 50px;height: 50px;border-radius: 50%;}
.blog-slider-section .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.blog-slider-section .swiper-button-prev:after{display:none;}
.blog-slider-section .swiper-button-next{font-size: 24px;color: var(--shades01);background: var(--color03);width: 50px;height: 50px;border-radius: 50%;}
.blog-slider-section .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.blog-slider-section .swiper-button-next:after{display:none;}
.blog-slider-section .swiper-pagination.swiper-pagination-bullets{ bottom: 0; display: none;}
.blog-slider-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}

.blog-item-section{}
.blog-item-section .blog-header{display: flex;}
.blog-item-section .blog-header .section-header{}
.blog-item-section .blog-header .section-header .section-title{}
.blog-search{width: 70%;display: flex;justify-content: flex-end;align-items: flex-end;}
.blog-search .blog-search-input{width: 50%;display: flex;align-items: center;background: var(--color01);border-radius: 10px;height: 50px;padding: 0 10px;}
.blog-search .blog-search-input .form-control{border: none;background: none;}
.blog-search .blog-search-input .text{}
.blog-search .blog-search-input .icon{color: var(--color03);}
/* .blog-search .category-search{width: 30%;}
.blog-search .date-search{width: 30%;} */
.blog-search .blog-inner{display: flex;align-items: center;}


.blog-item-section .dropdown{background: var(--color03);margin-left: 10px;color: var(--color01);border-radius: 10px;width: 160px;}
.blog-item-section .dropdown.date-dropdown{}
.blog-item-section .dropdown{}
.blog-item-section .dropdown .dropdown-toggle{padding: 0;height: 50px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--color01);font-size: 14px;font-weight: 500;padding: 0 20px;}
.blog-item-section .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.blog-item-section .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.blog-item-section .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.blog-item-section .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color03) !important;}
.blog-item-section .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.blog-item-section .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.blog-item-section .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.blog-item-section .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}

.blog-item-section .dropdown .dropdown-toggle:after {border: 0;color: #fff;color: var(--color01);content: "\f078";display: block;font-family: Font Awesome\ 5 Pro;font-size: 14px;font-weight: 500;margin: 2px 0 0;opacity: 1;position: absolute;right: 12px;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);transition: all .3s ease;vertical-align: initial;}
.blog-item-section .dropdown.show .dropdown-toggle:after {-webkit-transform: translateY(-50%) rotate(-180deg);transform: translateY(-50%) rotate(-180deg);}

.blog-lists{display: flex;padding: 40px 0;flex-wrap: wrap;margin: 0 -15px;}
.blog-lists .blog-item{width: 33.33%;max-width: calc(33.33% - 30px);margin: 15px;border: 2px solid var(--color01);padding: 15px;border-radius: 25px;}

.blog-lists .blog-item .blog-image{overflow: hidden;border-radius: 25px;height: 250px;width: 100%;}
.blog-item .blog-image{overflow: hidden;border-radius: 25px;height: 250px;width: 100%;}
.blog-lists .blog-item .blog-image img{width: 100%;height: 100%;object-fit: cover;}
.blog-item .blog-image img{width: 100%;height: 100%;object-fit: cover;}
.blog-item .blog-info{padding-top: 15px;}
.blog-item .blog-info .blog-data{    display: flex;justify-content: space-between;margin-bottom: 25px;align-items: center;flex-wrap: wrap;}
.blog-item .blog-info .blog-data .date{display: flex;margin-top: 10px;}
.blog-item .blog-info .blog-data .date .icon{color: var(--color02);margin-right: 5px;}
.blog-item .blog-info .blog-data .date .text{font-size: 14px;color: var(--shades07);}
.blog-item .blog-info .blog-data .author{display: flex;margin-top: 10px;}
.blog-item .blog-info .blog-data .author .icon{color: var(--color02);margin-right: 5px;}
.blog-item .blog-info .blog-data .author .text{font-size: 14px;color: var(--shades07);text-transform: capitalize;}
.blog-item .blog-info .blog-details .blog-text{color:#1B2A49;font-weight: 700;font-size: 25px;text-transform: uppercase;line-height: 1.4;}
.blog-item .blog-info .blog-data .tag{}
.blog-item .blog-info .blog-data .tag .tag-text{background: var(--color01);color: var(--color03);font-weight: 500;padding: 0px 14px;height: 30px;display: flex;align-items: center;justify-content: center;border-radius: 8px;}
.blog-details{}
.blog-details .blog-head{font-size: 24px;font-weight: 600;line-height: 1.5;color: var(--shades03);text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;}

.related-section{padding: 60px 0;}
.related-section .blog-item{    border: 2px solid var(--color01);padding: 15px;border-radius: 25px;display: block;}

@media screen and (max-width:1600px) {}
@media screen and (max-width:1440px) {
  .blog-item .blog-info .blog-data .tag{margin-left: 0;margin-top: 10px;}
  .blog-item .blog-info .blog-data{align-items: center;}
}
@media screen and (max-width:1366px) {}
@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 990px) {
  .blog-slider-image .img-wrap{height: 340px;}
  .blog-slider .blog-item .blog-slider-content{max-width: 100%;order: 2;padding: 15px;}
  .blog-item .blog-slider-content .title{font-size: 25px;line-height: 1.2;}
  .blog-item .blog-slider-content .reading-time{margin-top: 10px;}
  .blog-slider-content .link{margin: 10px 0;}
  .blog-slider-content .link .action{height: 38px;}
  .blog-slider-section .blog-slider .blog-item{flex-wrap: wrap;}

  .blog-item-section .blog-header{display: block;}
  .blog-search{display: unset;width: 100%;}
  .blog-search .blog-search-input{width: 100%;margin-bottom: 10px;}
  .blog-search .category-search , .blog-search .date-search{width: 100%;margin-bottom: 10px;}
  .blog-item-section .dropdown{margin-left: 0;width: 100%;}
  .blog-search .date-search{margin-left: 10px;}
  .blog-item-section .blog-header .section-header .section-title{font-size: 38px;}

  .blog-lists .blog-item{width: 50%;max-width: calc(50% - 30px);}
  .blog-item .blog-info .blog-data .tag{margin-left: 0;margin-top: 10px;}
  .blog-details .blog-head{font-size: 22px;line-height: 1.2;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 480px) {
  .blog-slider-section .blog-slider{padding: 0;}
  .blog-lists .blog-item{width: 100%;max-width: 100%;}
  .blog-slider-section .blog-slider .blog-item{flex-wrap: wrap;}
  .blog-slider .blog-item .blog-slider-content{max-width: 100%;order: 2;padding: 15px;}
  .blog-item .blog-slider-content .title{font-size: 25px;line-height: 1.2;}
  .blog-item .blog-slider-content .reading-time{margin-top: 10px;}
  .blog-slider-content .link{margin: 10px 0;}
  .blog-slider-content .link .action{height: 38px;}
  .blog-item-section{padding: 40px 0;}
  .blog-slider-image .img-wrap{height: 340px;}
  .blog-slider-section .swiper-button-prev{font-size: 20px;color: var(--shades01);top: unset;bottom: 0;}
  .blog-slider-section .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
  .blog-slider-section .swiper-button-prev:after{display:none;}
  .blog-slider-section .swiper-button-next{font-size: 20px;color: var(--shades01);top: unset;bottom: 0;}
  .blog-slider-section .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
  .blog-slider-section .swiper-button-next:after{display:none;}
  .blog-slider-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
  .blog-slider-section .swiper-pagination.swiper-pagination-bullets{z-index: 4;display: block;}
  .blog-item-section .blog-header{display: block;}
  .blog-search{display: unset;width: 100%;}
  .blog-search .blog-search-input{width: 100%;margin-bottom: 10px;}
  .blog-search .category-search , .blog-search .date-search{width: 100%;margin-bottom: 10px;}
  .blog-item-section .dropdown{margin-left: 0;}
  .blog-item-section .blog-header .section-header .section-title{font-size: 38px;}
  .blog-slider-section .swiper-wrapper{padding: 40px 0 60px;}
}

@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}



