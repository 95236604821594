/* ********|| MILESTONE STARTS ||******** */
.sec-milestone{padding: 60px 0;position: relative;}
.sec-milestone .milestone-inner{position: relative;background-color: var(--color01);padding: 60px 10px 0px 25px;border-radius: 47px;overflow: hidden;border: 3px solid var(--color03);z-index: 10;}
.sec-milestone .milestone-inner::after{position: absolute;content: '';background-size: contain;background-image: url(/public/assets/img/bg-star.svg);width: 100%;height: 100%;top: 0;left: 0;opacity: 0.3;z-index: -1;}
.sec-milestone .milestone-inner .milestone-item{display: flex;justify-content: space-between;align-items: center;}
.sec-milestone .milestone-content{width: 100%;max-width: calc(40% - 10px);margin-left: 20px;}
.sec-milestone .milestone-content .milestone-title{color: var(--color03);font-size: 65px; font-weight: 600;font-family: 'Playfair Display', serif;line-height: 1.1;margin: 0 0 20px;}
.sec-milestone .milestone-content .milestone-text{color: var(--shades09);font-size: 16px;font-weight: 400;line-height: 1.5;text-transform: capitalize;}
/* .sec-milestone .milestone-content .milestone-img{width: 420px;height: 420px;} */
.sec-milestone .milestone-content .milestone-img img{width: 68%;height: 100%;object-fit: cover;}
.sec-milestone .level-inner{display: flex;justify-content: center;width: 100%;max-width: calc(55% - 10px);}
.sec-milestone .level-inner .level-item{position:relative;background-color: var(--color07);padding: 10px;width: 280px;border-radius: 20px;}
.sec-milestone .level-inner .level-item+.level-item{margin-left: 80px;}
.sec-milestone .level-inner .level-item.lock-off{background-color: var(--color03);}
.sec-milestone .level-inner .level-item .level-content{position:relative;display:flex;flex-direction:column;justify-content:space-between;height:100%}
.sec-milestone .level-inner .level-item .level-content::after{position:absolute;content:'';width:104px;height:150px;background:linear-gradient(0deg,#176DAE -31.53%,#2390D7 107.13%);border-radius:14px;transform:skewY(-29deg);bottom:-34px;right:-5px;z-index:-1}
.sec-milestone .level-inner .level-item .level-content.lock-off::after{background:linear-gradient(0deg,#F14E2D -31.53%,#BA432B 107.13%)}
.sec-milestone .level-inner .level-item::after{top:43%;right:-13px;position:absolute;content:'';width:150px;height:150px;border:12px solid var(--color07);border-radius:20px;transform:rotate(134deg);z-index:-1}
.sec-milestone .level-inner .level-item.lock-off::after{border:12px solid var(--color03)}
.sec-milestone .level-inner .level-item .level-content .level-list{background-color:var(--shades01);position:relative;border-radius:34px;padding:40px 20px;height:100%;display:flex;flex-direction:column;margin-top:-35px}
.sec-milestone .level-inner .level-item .level-content .level-list::after{position:absolute;content:'';bottom:-18px;right:0;left:0;margin:0 auto;background-color:var(--shades01);width:50px;height:50px;border-radius:5px;transform:rotate(137deg)}
.sec-milestone .level-inner .level-item .level-content .level-list .list-content{display:flex;align-items:center;margin:0 0 10px}
.sec-milestone .level-inner .level-title{font-size:40px;color:var(--shades01);font-weight:600;line-height:1.4;display:flex;align-items:center;justify-content:space-around;padding:35px 0 0}
.sec-milestone .level-inner .level-title span{font-size:60px}
.sec-milestone .level-inner .level-list .list-content .icon{color:var(--color07);font-size:20px;padding-right:10px}
.sec-milestone .level-inner .level-list .list-content .icon.lock-off{color:var(--color03)}
.sec-milestone .level-inner .level-list .list-content .text{text-transform: capitalize;color:var(--shades09);font-size:15px;font-weight:400;line-height:1.4}
.sec-milestone .level-inner .level-item .lock-icon{position:absolute;top:-51px;left:0;right:0;margin:0 auto;width:60px;height:60px;background:linear-gradient(180deg,#2390D7 0%,#22B7D3 100%);border-radius:50%;display:flex;align-items:center;justify-content:center}
.sec-milestone .level-inner .level-item .lock-icon.lock-off{background:linear-gradient(180deg,#F14E2D 0%,#92301C 100%)}
.sec-milestone .level-inner .level-item .lock-icon img{width:50%;height:50%}

/* ********|| MILESTONE ENDS ||******** */
/* ********|| CHALLENGES START ||******** */
.sec-challenges{padding: 60px 0;}
.sec-challenges .challenges-heading{margin: 0 0 60px;}
.sec-challenges .challenges-title{color: var(--color03);font-size: 65px; font-weight: 600;font-family: 'Playfair Display', serif;line-height: 1.1;margin: 0 0 20px;}
.sec-challenges .challenges-sub-title{color: var(--shades09);font-size: 16px;font-weight: 400;line-height: 1.5;text-transform: capitalize;}
.sec-challenges .challenges-item .challenges-icon.big{font-size: 43px}
.sec-challenges .challenges-item .challenges-icon img{width: 70%;transition: all .4s ease;}
.sec-challenges .challenges-item{transition: all .4s ease;text-align:center;position: relative;background-color:var(--color01);border-radius: 10px;padding: 30px 10px;display: flex;flex-direction: column;height: 100%;align-items: center;justify-content: center;}
.sec-challenges .challenges-item .challenges-icon{box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);transition: all .4s ease;display: flex;align-items: center;justify-content: center;background-color: var(--color03);color: var(--shades01);width: 90px;height: 90px;border-radius: 50%;font-size: 26px;}
.sec-challenges .challenges-item .challenges-text{transition: all .4s ease;margin: 20px 0 0;font-size: 25px;font-weight: 600;color: var(--color03);line-height: 1.3;}
.sec-challenges .challenges-item .challenges-content{text-transform: capitalize;transition: all .4s ease;font-size: 15px;font-weight: 400;color: var(--shades09);line-height: 1.4;margin: 15px 0 20px;}
.sec-challenges .challenges-item .challenges-pts{transition: all .4s ease;display:flex;align-items:center;justify-content:center;background-color:var(--color03);color:var(--color01);width:80px;height:80px;border-radius:50%;font-size:16px;font-weight:600;position:absolute;top:-27px;right:-27px}
.sec-challenges .challenges-item .challenges-action .challenges-btn{box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);transition: all .4s ease;background-color: var(--color03);color: var(--shades01);width: 120px;height: 40px;border-radius: 5px;display: flex;align-items: center;justify-content: center;font-weight: 600;font-size: 16px;}
.sec-challenges .challenges-item:hover{background-color: var(--color03);box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);}
.sec-challenges .challenges-item:hover .challenges-icon{background-color: var(--color01);color: var(--color03);}
.sec-challenges .challenges-item:hover .challenges-icon.img{background-color: transparent;box-shadow: none;}
.sec-challenges .challenges-item:hover .challenges-icon img{width: 100%;}
.sec-challenges .challenges-item:hover .challenges-text{color: var(--shades01);}
.sec-challenges .challenges-item:hover .challenges-content{color: var(--shades01);}
.sec-challenges .challenges-item:hover .challenges-action .challenges-btn{background-color: var(--shades01);color: var(--color03);}
.sec-challenges .challenges-item:hover .challenges-pts{background-color: var(--color01);color: var(--color03);}

/* ********|| CHALLENGES END ||******** */


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {
  .sec-milestone .milestone-inner{padding: 60px 30px 60px 25px;}
  .sec-challenges .challenges-item .challenges-pts{right: -10px;}
}
@media screen and (max-width: 1366px) {
  .sec-milestone .milestone-inner{padding: 60px 20px 60px 25px;}
  .sec-milestone .milestone-content{max-width: calc(30% - 10px);}
  .sec-milestone .level-inner{max-width: calc(70% - 10px);}
  .sec-milestone .milestone-content .milestone-title{font-size: 40px;}
  .sec-milestone .level-inner .level-item+.level-item{margin-left: 50px;}
  .sec-challenges .challenges-item .challenges-pts{right: -7px;}
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
  .sec-milestone .milestone-inner{padding: 60px 20px 60px 25px;}
  .sec-milestone .milestone-content{max-width: calc(20% - 10px);}
  .sec-milestone .level-inner{max-width: calc(85% - 10px);}
  .sec-milestone .milestone-content .milestone-title{font-size: 30px;}
  .sec-milestone .level-inner .level-item+.level-item{margin-left: 50px;}
	
}
@media screen and (max-width: 990px) {
  
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 768px) {
	.sec-milestone .level-inner{flex-wrap: wrap;max-width: 100%;padding: 160px 0;}
  .sec-milestone .milestone-inner .milestone-item{flex-wrap: wrap;}
  .sec-milestone .milestone-content{max-width: 100%;}
  .sec-milestone .milestone-content .milestone-img{display: none;}
  .sec-milestone .level-inner .level-item+.level-item{margin-left: 0;margin-top: 160px;}
  .sec-milestone .level-inner .level-item{width: 100%;}

  .sec-challenges .col-lg-3{margin-top: 60px;}
  .sec-challenges .challenges-item{margin: 25px;}

  .sec-milestone .level-inner .level-item .level-content::after{display: none;}
  .sec-milestone .level-inner .level-item::after{bottom: 0px;right: 45%;top: unset;transform: rotate(134deg) translateY(-40%);}
  .sec-milestone .milestone-inner{padding:60px 10px 10px;}
  .sec-challenges .challenges-heading {text-align: center;margin: 0 0 20px;}
  .sec-challenges .challenges-title , .sec-milestone .milestone-content .milestone-title{text-align: center;font-size: 40px;}
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .sec-milestone .level-inner .level-item::after{right: 40%;}
	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 