.search-part-career{display: flex;justify-content: center;}
.search-part-career .location-dropdown{}
.search-part-career .location-search{width: 25%;}
.search-part-career .job-search{width: 25%;}
.search-part-career .job-dropdown{margin-left: 10px;}
.search-part-career .dropdown{background: var(--color03);color: var(--color01);border-radius: 10px;}
.search-part-career .dropdown.location-dropdown{background: var(--color01);color: var(--color03);}
.search-part-career .dropdown.location-dropdown .dropdown-toggle{color: var(--color03);}
.search-part-career .dropdown.location-dropdown .dropdown-toggle:after{color: var(--color03);}
.search-part-career .dropdown{}
.search-part-career .dropdown .dropdown-toggle{padding: 0;height: 50px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--color01);font-size: 14px;font-weight: 500;padding: 0 20px;}
.search-part-career .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.search-part-career .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.search-part-career .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.search-part-career .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color03) !important;}
.search-part-career .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.search-part-career .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.search-part-career .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.search-part-career .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}

.search-part-career .dropdown .dropdown-toggle:after {border: 0;color: #fff;color: var(--color01);content: "\f078";display: block;font-family: Font Awesome\ 5 Pro;font-size: 14px;font-weight: 500;margin: 2px 0 0;opacity: 1;position: absolute;right: 12px;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);transition: all .3s ease;vertical-align: initial;}
.search-part-career .dropdown.show .dropdown-toggle:after {-webkit-transform: translateY(-50%) rotate(-180deg);transform: translateY(-50%) rotate(-180deg);}

.career-list-part{padding:60px 0}
.careeritem.active .career-item-title{background:var(--color03);color:var(--color01)}
.careeritem.active .career-item-title::after{color:var(--shades01);transform:translateY(-50%) rotate(90deg)}
.careeritem.active .career-item-title .text{color:var(--color01)}
.careeritem+ .careeritem{margin-top:15px}
.careeritem .career-item-title{position:relative;background:var(--color01);border-radius:10px;line-height:1.5;padding:20px 65px 20px 20px}
.careeritem .career-item-title::after{content:"\f054";font-family:'Font Awesome 6 Pro';position:absolute;top:50%;transform:translateY(-50%);right:25px;font-size:24px;font-weight:800;color:var(--color03)}
.careeritem .career-item-title .text{margin-bottom:0;color:var(--color03);font-weight:600;font-size:18px}
.careeritem .career-content{background:var(--shades05);margin:20px;padding:30px;border-radius:14px;display:none}
.careeritem .career-item-title:hover{cursor: pointer;}
.careeritem.active .career-content{display:block}
.careeritem .career-content .descriptions{font-size:15px;line-height:1.4;font-weight:300;margin-bottom:25px}
.careeritem .career-content .title-part{margin-bottom:25px}
.careeritem .career-content .title-part .content-title{font-size:16px;font-weight:600;color:var(--color03)}
.careeritem .career-content .description-list{margin-bottom:25px}
.careeritem .career-content .description-list ul{padding-left:18px}
.careeritem .career-content .description-list ul li{font-size:15px;line-height:1.4;position:relative;list-style:none;font-weight:300}
.careeritem .career-content .description-list ul li:before{content:'';position:absolute;width:10px;height:10px;background:var(--color03);border-radius:50%;left:-17px;top:50%;transform:translateY(-50%)}
.careeritem .career-content .description-list ul li+ li{margin-top:10px}
.careeritem .career-content .apply-action{display:flex;justify-content:center;margin-top:45px}
.careeritem .career-content .apply-action .apply{background:var(--color03);color:var(--shades01);height:44px;padding:0 45px;text-transform:capitalize;font-weight:600;border-radius:10px;width:240px;display: flex;justify-content: center;align-items: center;}

/* .career-list-part .careeritem:nth-of-type(2){display: block;} */
.future-section{padding: 60px 0;}
.future-part {display: grid;grid-template-columns: repeat(4, 1fr);grid-template-rows: repeat(3, 1fr);grid-column-gap: 15px;grid-row-gap: 15px;}
.future-item{overflow: hidden;border-radius: 30px;position: relative;}
.future-item .future-img{}
.future-item img{width: 100%;height: 100%;object-fit: cover;}
.future-item:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
.future-item:nth-child(2) { grid-area: 1 / 3 / 2 / 4; }
.future-item:nth-child(3) { grid-area: 1 / 4 / 3 / 5; }
.future-item:nth-child(4) { grid-area: 2 / 1 / 3 / 2; }
.future-item:nth-child(5) { grid-area: 2 / 2 / 3 / 4; }
.future-item:nth-child(6) { grid-area: 3 / 1 / 4 / 2; }
.future-item:nth-child(7) { grid-area: 3 / 2 / 4 / 4; }
.future-item:nth-child(8) { grid-area: 3 / 4 / 4 / 5; }
.future-section .video-action {align-items: center;display: flex;justify-content: center;left: 0;position: absolute;right: 0;top: 50%;transform: translateY(-50%);z-index: 8;}
.future-section .video-action .btn-action {background-color: var(--color03);border-radius: 50%;color: var(--shades01);font-size: 45px;height: 100px;padding: 0 20px;width: 100px;}

@media screen and (max-width: 990px) {
    .search-part-career .location-search{width: 35%;}
    .search-part-career .job-search{width: 35%;}
    .future-item:nth-child(1){grid-area: 1 / 1 / 1 / 3;}
    .future-item:nth-child(5) {
        grid-area: 2 / 1 / 3 / 4;
    }
    /* .future-item:nth-child(7) {grid-area: -1 / 5 / 4 / 4;} */
    .future-part{grid-template-columns: repeat(1, 1fr);}

}

@media screen and (max-width: 480px) {
    .search-part-career{flex-wrap: wrap;}
    .search-part-career .location-search{width: 100%;}
    .search-part-career .job-search{width: 100%;}
    .search-part-career .job-dropdown{margin-left: 0;margin: 20px 0 0;}
    .career-list-part{padding: 40px 0;}
    .careeritem .career-content{margin: 0;padding: 15px;}
    .careeritem .career-item-title .text{font-size: 16px;}
    .future-section{padding: 30px 0;}
    .future-item:nth-child(1) {
        grid-area: 1 / 1 / 1 / 6;
    }
    .future-item:nth-child(2) {
        grid-area: 2 / 6 / 2 / 4;
    }
    .future-item:nth-child(3) {
        grid-area: 3 / 4 / 3 / 6;
    }
    .future-item:nth-child(4) {
        grid-area: 2 / 1 / 3 / 4;
    }
    .future-item:nth-child(5) {
        grid-area: 3 / 1 / 3 / 6;
    }
    .future-item:nth-child(6) {
        grid-area: 4 / 6 / 4 / 4;
    }
    .future-item:nth-child(7) {
        grid-area: 18 / 2 / 4 / 4;
    }
    .future-item:nth-child(8) {
        grid-area: 4 / 4 / 8 / 6;
    }
    .future-item{height: 250px;}
}