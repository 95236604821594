.delivery-progress-section{padding: 60px 0;}
.delivery-progress-section .delivery-progress-part{}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate{display: flex;justify-content: space-between;margin: 0 30px;}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts {}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts{text-align: center;}
/* .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .icon{display: none;} */
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .delivery{display: none;}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts.active .icon{display: none;}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts.active .delivery{display: block;}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .icon img{}
.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .text{font-size: 20px;font-weight: 400;color: var(--color03);padding-top: 25px;}
.delivery-progress-section .delivery-progress-part .progress{margin-top: -64px;height: 22px;border-radius: 50px;width: 100%;border: 1px solid var(--color03);background: rgb(241 78 45 / 22%);position: relative;z-index: -1;}
.delivery-progress-section .delivery-progress-part .progress .progress-bar{background: var(--color03);}

.delivery-options-section{padding: 60px 0;}
.delivery-options-section .delivery-option{background: var(--color01);box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.2);border-radius: 15px; padding-top: 40px;overflow: hidden;}
.delivery-options-section .delivery-option .delivery-image{width: 300px;height: 300px;margin: 0 auto;}
.delivery-options-section .delivery-option .delivery-image img{width: 100%;height: 100%;object-fit: contain;}
.delivery-options-section .delivery-option .option-action{    width: 100%;background: var(--color03);text-transform: capitalize;font-family: 'Playfair Display', serif;color: var(--shades01);font-size: 35px;line-height: 1;font-weight: 600;padding: 18px;margin-top: 25px;display: block;text-align: center;}

.address-changer{display:flex;list-style-type:none;margin:0 -15px 20px;padding:0;border-bottom:1px solid #e2e6e9}
.address-changer .menuitem{width:50%;text-align:center;padding:5px;text-transform:uppercase;color:#000;font-weight:600;line-height:1;position:relative;margin:0 5px 7px;display:block;background:transparent}
.address-changer .menuitem.active::before{content:"";display:block;width:80%;height:4px;background-color:var(--color03);bottom:-9px;position:absolute;border-radius:10px;left:50%;transform:translateX(-50%)}
.select-default-address .address-item+.address-item{margin-top:8px}
.select-default-address .address-item .custom-radio{background:var(--shades01);border:1px solid var(--color03);border-radius:5px;height:30px;padding:5px 15px;position:relative}
.select-default-address .address-item .custom-radio .custom-radio-input{cursor:pointer;height:100%;left:0;opacity:0;position:absolute;top:0;width:100%;z-index:1}
.select-default-address .address-item .custom-radio .custom-radio-label{color:var(--color02);color:var(--color03);font-size:14px;font-weight:500;line-height:1;margin:0;padding:0 30px 0 0;position:relative;width:100%}
.select-default-address .address-item .custom-radio .custom-radio-label:before{background:var(--shades01);border:1px solid var(--color03);border-radius:3px;content:"";height:18px;position:absolute;right:-7px;top:50%;transform:translateY(-50%);width:18px}
.select-default-address .address-item .custom-radio .custom-radio-label:after{align-items:center;background:var(--color03);content:"\f111";display:none;font-family:"Font Awesome 5 Pro";font-weight:600;justify-content:center;margin:0 auto;position:absolute;right:-4px;text-align:center;top:50%;transform:translateY(-50%);width: 12px;
  height: 12px;
  border-radius: 3px;}
.select-default-address .address-item .custom-radio .custom-radio-input:checked~.custom-radio-label:after{display:flex}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .delivery-options-section .delivery-option{margin-top: 25px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 