.delivery-registration-section{padding: 60px 0;}
.delivery-registration-section .regitration-form-content{background: var(--color01);border-radius: 30px;padding:60px 30px;}
.delivery-registration-section .regitration-form-content .description{font-size: 16px;line-height: 1.5;font-weight: 400;}
.delivery-registration-section .form-part{}
.delivery-registration-section .form-part .registration-form-section{}
.delivery-registration-section .form-part .registration-form-section{}
.delivery-registration-section .form-part .registration-form-section .form-options{display: flex;align-items: center;margin-top: 25px;}
.delivery-registration-section .form-part .registration-form-section .form-options .option{ }
.delivery-registration-section .form-part .registration-form-section .form-options .option+ .option{margin-left:20px}

.delivery-registration-section .form-part .registration-form-section .form-options .option .go-signin-btn{
    font-weight: 500;
    line-height: 1;
    width: 100%;
    padding: 0 30px 0 0;
    color: var(--color03);
    padding: 11px 20px;
    border: 1px solid var(--color03);
    border-radius: 10px;
    font-family: 'Playfair Display',serif;
    color: var(--color03);
    font-size: 20px;
}

.delivery-registration-section .form-part .registration-form-section .form-tab-section{display:flex;margin-top:25px;visibility: hidden;}
.delivery-registration-section .form-part .registration-form-section .form-tab-section.active{visibility: visible;}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-content{max-width:calc(100% - 350px);width:100%}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section{width:320px;margin-left:30px;background:var(--color03);border-radius:20px;padding:30px 15px;height:max-content}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list{}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .title{font-size:34px;padding-left:15px;line-height:1.2;color:var(--color01);font-weight:600;position:relative}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .title:before{content:'';position:absolute;width:5px;height:30px;background:var(--shades01);left:-15px;top:5px}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent{padding:15px;margin-top:25px}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent .list{display:flex;align-items:flex-start}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent .list+ .list{margin-top:15px}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent .list .icon{font-size:25px;color:var(--shades01);margin-right:14px}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent .list .text{font-size:16px;line-height:1.5;color:var(--shades01);font-weight:400}
.delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section .form-details-list .item-conent .list .text span{font-weight:600;margin-right:10px}


.delivery-registration-section .custom-radio{position: relative;margin:0;display: flex; align-items:center; line-height:1;}
.delivery-registration-section .custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.delivery-registration-section .custom-radio .custom-radio-label{position:relative;margin:0;font-size:14px;font-weight:500;line-height:1;width:100%;padding:0 30px 0 0;color:var(--color03);padding:15px 20px 15px 50px;border:1px solid var(--color03);border-radius:10px}
.delivery-registration-section .custom-radio .custom-radio-label:before{content:'';position:absolute;width:28px;height:28px;background:transparent;border:1px solid var(--color03);top:50%;left:10px;transform:translateY(-50%);border-radius:50%}
.delivery-registration-section .custom-radio .custom-radio-label .text{width:100%;font-family:'Playfair Display',serif;color:var(--color03);font-size:20px}
.delivery-registration-section .custom-radio .custom-radio-label:after{content:"\f111";position:absolute;font-family:"Font Awesome 5 Pro";left:17px;top:50%;transform:translateY(-50%);font-weight:600;display:none;align-items:center;justify-content:center;text-align:center;border-radius:4px;background:none;margin:0 auto}
.delivery-registration-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label{background: var(--color03);}
.delivery-registration-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label .text{color: var(--shades01);}
.delivery-registration-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:flex;color: var(--shades01);}
.delivery-registration-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: transparent;border: 1px solid var(--shades01);}



.delivery-registration-section .input-style{margin-top: 25px;}
.delivery-registration-section .input-style .form-label{font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.delivery-registration-section .input-style .form-control{border: 1px solid var(--color03);height: 55px;border-radius: 10px;}
.delivery-registration-section .form-action{margin: 40px 0;text-align: center;}


.form-condition{display:flex;margin-top:25px;align-items:center}
.form-condition .text{padding-left:15px;font-size:14px;line-height:1.5;font-weight:400}
.form-condition .text span{color:var(--color03);font-weight:500}
.form-tab-section .custom-checkbox{position:relative;padding-left:23px;margin:3px 0;width:30px;display:inline-block;height:30px}
.form-tab-section .custom-checkbox .custom-checkbox-input{position:absolute;z-index:1;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.form-tab-section .custom-checkbox .custom-checkbox-label{position:relative;margin:0;line-height:16px;font-size:14px}
.form-tab-section .custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width:30px;height:30px;background:#fff;border:1px solid var(--color03);top:50%;left:-24px;transform:translateY(-50%);border-radius:2px}
.form-tab-section .custom-checkbox .custom-checkbox-label:after{content:"\f00c";position:absolute;font-family:"Font Awesome 5 Pro";width:30px;top:50%;left:-24px;transform:translateY(-50%);font-weight:500;font-size:20px;display:none;color:#fff;text-align:center}
.form-tab-section .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:block}
.form-tab-section .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background:var(--color03)}
.form-action-submit{margin-top:25px;display:flex;align-items:center;justify-content:center}
.form-action-submit .btn-register{background:var(--color03);width:100%;color:var(--shades01);font-size:26px;text-transform:capitalize;padding:22px 30px;border-radius:8px;font-weight:500;text-align: center;}





 @media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
    .delivery-registration-section .form-part .registration-form-section .form-options{flex-wrap: wrap;}
    .delivery-registration-section .form-part .registration-form-section .form-options .option{width: 100%;}
    .delivery-registration-section .form-part .registration-form-section .form-options .option .go-signin-btn{width: 100%;display: flex;padding: 15px 20px;}
    .delivery-registration-section .form-part .registration-form-section .form-options .option+ .option{margin-left: 0;margin-top: 10px;}

    .delivery-registration-section .form-part .registration-form-section .form-tab-section{flex-wrap: wrap;}
    .delivery-registration-section .form-part .registration-form-section .form-tab-section .form-content{max-width: 100%;}

    .delivery-registration-section .form-part .registration-form-section .form-tab-section .form-details-section{width: 100%;margin-left: unset;margin-top: 15px;}

    .delivery-registration-section .regitration-form-content{margin: 0 -15px;}
    .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts.active .icon ,.delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts{width: 80px;height: 80px;}
    .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .icon img{width: 100%;height: 100%;}
    .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts.active .delivery{width: 80px;height: 80px;}
    .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts.active .delivery img{width: 100%;height: 100%;}

    .delivery-progress-section .delivery-progress-part .progress{margin-top: 0;height: 12px;}
    .delivery-progress-section .delivery-progress-part .progress-bar-navigate .parts .text{font-size: 14px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 