.rewards-profile-section{padding:60px 0;position:relative}
.rewards-profile-section .profile-section{overflow:hidden;height:400px;width:100%;border-radius:30px;position:relative}
.rewards-profile-section .profile-section .rewards-image{height:400px;width:100%;border-radius:30px}
.rewards-profile-section .profile-section .rewards-image img{width:100%;height:100%;object-fit:cover}
.rewards-profile-section .profile-section .text-content{position:absolute;z-index:1;top:0;padding:60px 45px;width:50%}
.rewards-profile-section .profile-section .text-content .profile-name{font-size:30px;line-height:1;margin:0 0 25px;color:var(--shades01);font-weight:400}
.rewards-profile-section .profile-section .text-content .profile-name span{font-weight:600;color:var(--color01)}
.rewards-profile-section .profile-section .text-content .points{font-size:38px;line-height:1;font-weight:700;color:var(--color01)}
.rewards-profile-section .profile-section .text-content .points span{margin-right:10px}


.rewards-menu-section{padding: 60px 0;position: relative;}
.rewards-menu-section .left-side-menu{}
.rewards-menu-section .left-side-menu .left-side-menu-link{}
.rewards-menu-section .left-side-menu .left-side-menu-link .image{overflow:hidden;width:100%;height:370px;border-radius:15px 15px 0 0}
.rewards-menu-section .left-side-menu .left-side-menu-link .image img{width:100%;height:100%;object-fit:cover}
.rewards-menu-section .left-side-menu .left-side-menu-link .menu-text{background:var(--color03);font-family:'Playfair Display',serif;font-weight:600;line-height:1;color:var(--shades01);padding:25px;border-radius:0 0 15px 15px;text-align:center;font-size:35px}

.rewards-menu-section .right-side-menu-link{}
.rewards-menu-section .right-side-menu-link+ .right-side-menu-link{margin-top:20px}
.rewards-menu-section .right-side-menu-link .image{border-radius:15px 15px 0 0;overflow:hidden;height:160px}
.rewards-menu-section .right-side-menu-link .image img{width:100%;height:100%;object-fit:cover}
.rewards-menu-section .right-side-menu-link .menu-text{background:var(--color03);font-family:'Playfair Display',serif;font-weight:600;line-height:1;color:var(--shades01);padding:16px;border-radius:0 0 15px 15px;text-align:center;font-size:25px}

.rewards-foods-section{padding: 60px 0;position: relative;}
.rewards-foods-section .rewards-title{    font-family: 'Playfair Display', serif;color: var(--shades02);font-size: 64px;font-weight: 700;margin: 0;line-height: 1.5;margin: 0;}
.rewards-foods-section .rewards-title span {color: var(--color02);}


.rewards-foods-section .foods-list{display:flex;position:relative;flex-wrap:wrap;margin:0 -30px}
.rewards-foods-section .food-inner{transition:all ease .3s;background:var(--color01);border-radius:30px;padding:26px;height:100%}
.rewards-foods-section .foods-list .food-item{margin:30px; padding-bottom:50px;width:33.33%;position:relative;max-width:calc(33.33% - 60px)}
.rewards-foods-section .foods-list .food-item .food-inner:hover{background:var(--color03);cursor:pointer}

.rewards-foods-section .foods-list .food-item .food-inner .ingredients{position:relative;right:0}
.rewards-foods-section .foods-list .food-item .food-inner .ingredients .list-contains{right:-40px;position:absolute}
.rewards-foods-section .foods-list .food-item .food-inner .ingredients .list-contains .icon{width:35px;height:35px;background:var(--color03);color:var(--shades01);display:flex;align-items:center;justify-content:center;border-radius:50%;border:2px solid var(--shades01);font-size:14px}
.rewards-foods-section .foods-list .food-item .food-inner .ingredients .list-contains .icon+.icon{margin-top:4px}
.rewards-foods-section .foods-list .food-item .food-inner .name{font-family:'Nunito',sans-serif;font-size:30px;font-weight:700}
.rewards-foods-section .foods-list .food-item .food-inner .menu-image{    transition: all ease .3s;pointer-events: none;transform: translate(-20%,0) scale(1);pointer-events: none;width: 400px;height: 400px; }
.rewards-foods-section .foods-list .food-item .food-inner:hover .menu-image{transition: all ease .3s;margin-left: 0;transform: translate(-20%,-20%) scale(0.6);pointer-events: none;
}
.rewards-foods-section .foods-list .food-item .food-inner .menu-image img{width:100%;height:100%;object-fit:contain}

.rewards-foods-section .food-item .food-inner .description{position: absolute;opacity: 0;padding-right: 120px;color: var(--shades01);line-height: 2;font-size: 15px;bottom: 70px;z-index: 1;height: 150px;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 5;-webkit-box-orient: vertical;display: -webkit-box;transform: translateY(150px);visibility: hidden;transition: all ease 0.3s;pointer-events: none;}
.rewards-foods-section .food-item .food-inner:hover .description{opacity: 1;transform: translateY(0);visibility: visible;}
.rewards-foods-section .food-item .food-inner:hover .name{color:var(--color01)}

.rewards-foods-section .foods-list .description p {}

.rewards-foods-section .menu-order-action {position: relative;}

.rewards-foods-section .menu-order-action .add-to-cart{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:-26px;right:-26px}
.rewards-foods-section .menu-order-action .add-to-cart .icon{font-size:24px;margin-bottom:10px}
.rewards-foods-section .menu-order-action .add-to-cart .points{font-size:20px;font-weight:600}
.rewards-foods-section .menu-order-action .add-to-cart .text{font-size:14px;text-transform:uppercase;margin-top:5px}
.rewards-foods-section .food-item .food-inner:hover .menu-order-action .add-to-cart{background:var(--color01)}
.rewards-foods-section .food-item .food-inner:hover .menu-order-action .add-to-cart .points{color:var(--shades02);font-weight:700}
.rewards-foods-section .food-item .food-inner:hover .menu-order-action .add-to-cart .text{color:var(--color03);font-weight:400}
.rewards-foods-section .food-item .food-inner:hover .menu-order-action .add-to-cart .icon{color:var(--color03)}

.rewards-foods-section .food-item .cart-action{width: 100%;margin-top: 15px;position: absolute;}
.rewards-foods-section .food-item .cart-action .cart-btn{width: 100%;border-radius: 10px;padding: 15px;font-size: 16px;font-weight: 600;color: var(--color03);background: var(--color01);}

.point-conversion-section .conversion-title{    font-family: 'Playfair Display', serif;color: var(--shades02);font-size: 64px;font-weight: 700;margin: 0;line-height: 1.5;margin: 0;}
.point-conversion-section .conversion-title span {color: var(--color02);}
.point-conversion-section .intro{font-size:30px;line-height:1;margin:0 0 25px;color:var(--shades02);font-weight:400}
.point-conversion-section .profile-content .intro span{font-weight:600;color:var(--color03)}
.point-conversion-section .profile-content .points{font-size:38px;line-height:1;font-weight:700;color:var(--color03)}
.point-conversion-section .profile-content .points span{margin-right:10px}

.point-conversion-section{position: relative;padding: 60px 0;margin: 100px 0;}
.point-conversion-section:before{ margin: 0 auto;   content: '';position: absolute;width: 90%;height: 100%;background: var(--color01);left: 0;right: 0;top: 0;border-radius: 30px;}
.point-conversion-section .conversion-image{width: 100%;height: 100%;}
.point-conversion-section .conversion-image img{width: 100%;height: 100%;}
.point-conversion-section .sub-text{font-size: 16px;margin: 30px 0 0;font-weight: 400;text-align: center;}


.point-conversion-section .link-copy-section{margin-top: 35px;}
.point-conversion-section .link-copy-section .link-copy{}
.point-conversion-section .link-copy-section .link-copy .form-label {font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.point-conversion-section .link-copy-section .link-copy .form-control {border: none;background: transparent;height: auto;border-radius: 10px;}
.point-conversion-section .copy-part{display: flex; border: 1px solid var(--color03);background: var(--shades01);height: 55px;border-radius: 10px;}
.point-conversion-section .link-copy-section .link-copy .input-group-append {}
.point-conversion-section .link-copy-section .link-copy .input-group-append .input-group-text{background: var(--color03);border: none;font-size: 16px;font-weight: 400;color: var(--shades01);padding: 10px 20px;border-radius: 0 8px 8px 0;}
.point-conversion-section .link-copy-section .btn-copy{display: flex;align-items: center;width: 100%;background: var(--color03);color: var(--shades01);border-radius: 10px;height:55px;text-align: center;justify-content: center;font-size: 25px;padding: 10px;text-transform: capitalize;font-weight: 500;}






.merchandise-section{padding: 60px 0;position: relative;}
.merchandise-section .merchandise-title{    font-family: 'Playfair Display', serif;color: var(--shades02);font-size: 64px;font-weight: 700;margin: 0;line-height: 1.5;margin: 0;}
.merchandise-section .merchandise-title span {color: var(--color02);}


.merchandise-section .merchandise-list{display:flex;position:relative;flex-wrap:wrap;margin:0 -30px;padding: 60px 0;}
.merchandise-section .merchandise-inner{transition: all ease .3s;position: relative;background: var(--color01);border-radius: 30px;height: 100%;overflow: hidden;}
.merchandise-section .merchandise-list .merchandise-item{margin:30px; padding-bottom:50px;width:33.33%;position:relative;max-width:calc(33.33% - 60px)}
/* .merchandise-section .merchandise-list .merchandise-item .merchandise-inner:hover{background:var(--color03);cursor:pointer} */

.merchandise-section .merchandise-list .merchandise-item .merchandise-inner .name{font-family:'Nunito',sans-serif;font-size:30px;font-weight:700}
.merchandise-section .merchandise-list .merchandise-item .merchandise-inner .menu-image{ width: 100%;height: 400px; }
.merchandise-section .merchandise-list .merchandise-item .merchandise-inner:hover .menu-image{}
.merchandise-section .merchandise-list .merchandise-item .merchandise-inner .menu-image img{width:100%;height:100%;object-fit:cover}
.merchandise-section .merchandise-list .merchandise-item .merchandise-inner .info-icon{position: absolute;z-index: 1;color: var(--color03);right: 20px;top: 20px;background: var(--color01);width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 20px;border-radius: 50px;border: 2px solid var(--color03);cursor: pointer;}
  /* .merchandise-section .merchandise-list .merchandise-item .merchandise-inner .info-icon:hover .description{opacity: 1;visibility: visible;} */
.merchandise-section .merchandise-item .merchandise-inner .description{position: absolute;opacity: 0;padding: 40px;color: var(--shades01);line-height: 2;font-size: 15px;width: 100%;z-index: 0;height: 100%;visibility: hidden;transition: all ease 0.3s;top: 0;background: var(--color03);pointer-events: none;}
.merchandise-section .merchandise-item .merchandise-inner .info-icon:hover ~ .description{opacity: 1;visibility: visible;cursor: pointer;}
/* .merchandise-section .merchandise-item .merchandise-inner:hover .name{color:var(--color01)} */

.merchandise-section .merchandise-list .description{}
.merchandise-section .merchandise-list .description .title{font-size: 30px;font-weight: 600;letter-spacing: 0.5px;}
.merchandise-section .merchandise-list .description .content-item{}
.merchandise-section .merchandise-list .description .content-item .list{padding: 0;}
.merchandise-section .merchandise-list .description .content-item .list .item-text{display: flex;align-items: center;}
.merchandise-section .merchandise-list .description .content-item .list .item-text .icon{font-size: 20px;margin-right: 10px;}
.merchandise-section .merchandise-list .description .content-item .list .item-text .text{line-height: 1;font-size: 16px;font-weight: 300;}

.merchandise-section .menu-order-action {position: relative;}

.merchandise-section .menu-order-action .add-to-cart{background:var(--color03);color:var(--shades01);position:absolute;border-radius:30px 0;padding:15px;bottom:0;right:0;    display: flex;align-items: center;}
.merchandise-section .menu-order-action .add-to-cart .icon{font-size:24px;margin-bottom:10px}
.merchandise-section .menu-order-action .add-to-cart .points{font-size:20px;font-weight:500}
.merchandise-section .menu-order-action .add-to-cart .text{font-size:20px;text-transform:uppercase;margin-left:5px;font-weight: 500;}
.merchandise-section .merchandise-item .merchandise-inner:hover .menu-order-action .add-to-cart{background:var(--color01)}
.merchandise-section .merchandise-item .merchandise-inner:hover .menu-order-action .add-to-cart .points{color:var(--color03);}
.merchandise-section .merchandise-item .merchandise-inner:hover .menu-order-action .add-to-cart .text{color:var(--color03);}
.merchandise-section .merchandise-item .merchandise-inner:hover .menu-order-action .add-to-cart .icon{color:var(--color03)}

.merchandise-section .merchandise-item .cart-action{width: 100%;margin-top: 15px;position: absolute;}
.merchandise-section .merchandise-item .cart-action .cart-btn{width: 100%;border-radius: 10px;padding: 15px;font-size: 16px;font-weight: 600;color: var(--color03);background: var(--color01);}



.points-donation-section{padding: 60px 0;position: relative;}
.points-donation-section .donation-title{    font-family: 'Playfair Display', serif;color: var(--shades02);font-size: 64px;font-weight: 700;margin: 0;line-height: 1.5;margin: 0;}
.points-donation-section .donation-title span {color: var(--color02);}


.points-donation-section .donation-list{display:flex;position:relative;flex-wrap:wrap;margin:0 -30px;padding: 60px 0;}
.points-donation-section .points-donation-inner{transition: all ease .3s;position: relative;background: var(--color01);border-radius: 30px;height: 100%;overflow: hidden;}
.points-donation-section .donation-list .points-donation-item{margin:30px; padding-bottom:50px;width:33.33%;position:relative;max-width:calc(33.33% - 60px)}
.points-donation-section .donation-list .points-donation-item .points-donation-inner .menu-image{    width: 300px;height: 320px;margin: 0 auto;}
.points-donation-section .donation-list .points-donation-item .points-donation-inner .menu-image img{    width: 100%;height: 100%;object-fit: contain;object-position: bottom;}
.points-donation-section .donation-list .points-donation-item .points-donation-inner .info-icon{position: absolute;z-index: 1;color: var(--color03);right: 20px;top: 20px;background: var(--color01);width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 20px;border-radius: 50px;border: 2px solid var(--color03);cursor: pointer;}
.points-donation-section .donation-list .description .content-item .list .item-text+ .item-text{margin-top: 10px;}
.points-donation-section .points-donation-item .points-donation-inner:hover{cursor: pointer;} 
.points-donation-section .points-donation-item .points-donation-inner .description{position: absolute;opacity: 0;padding: 40px;color: var(--shades01);line-height: 2;font-size: 15px;width: 100%;z-index: 0;height: 100%;visibility: hidden;transition: all ease 0.3s;top: 0;background: var(--color03);pointer-events: none;}
.points-donation-section .points-donation-item .points-donation-inner:hover .description{opacity: 1;visibility: visible;cursor: pointer;}
/* .points-donation-section .points-donation-item .points-donation-inner:hover .name{color:var(--color01)} */

.points-donation-section .donation-list .description{}
.points-donation-section .donation-list .description .title{font-size: 30px;font-weight: 600;letter-spacing: 0.5px;}
.points-donation-section .donation-list .description .content-item{}
.points-donation-section .donation-list .description .content-item .list{padding: 0;}
.points-donation-section .donation-list .description .content-item .list .item-text{display: flex;align-items: center;}
.points-donation-section .donation-list .description .content-item .list .item-text .icon{font-size: 20px;margin-right: 10px;}
.points-donation-section .donation-list .description .content-item .list .item-text .text{line-height: 1;font-size: 16px;font-weight: 300;}

.points-donation-section .name-organization{background:var(--color03);color:var(--shades01);padding:20px; display: flex;align-items: center;position: relative;justify-content: center;font-size:20px;text-transform:uppercase;font-weight: 500;}
.points-donation-section .name-organization .name{}
.points-donation-section .points-donation-item .points-donation-inner:hover .name-organization{background:var(--color01);color:var(--color03);}

.organization-details{padding: 60px 0;background: var(--color01);}
.organization-details .details-part-organization{}
.organization-details .details-part-organization .organization{display:flex;align-items:center;margin-bottom:25px}
.organization-details .details-part-organization .organization .logo{width:90px;height:90px;overflow:hidden;border-radius:50%;box-shadow:0 4px 18px 0 rgba(0,0,0,0.1)}
.organization-details .details-part-organization .organization .logo img{width:100%;height:100%;object-fit:cover}
.organization-details .details-part-organization .organization .name-organization{width:100%;max-width:calc(100% - 120px);margin-left:30px;font-size:60px;font-weight:700;color:var(--color03);font-family:'Playfair Display',serif}
.organization-details .details-part-organization .organization-details-content{font-size:16px;line-height:1.5;font-weight:300}
.organization-details .details-part-organization .points-donate-part{margin-top:25px}
.organization-details .details-part-organization .points-donate-part .points{display:flex;align-items:center;background:var(--shades01);padding:10px;border-radius:8px;width:max-content}
.organization-details .details-part-organization .points-donate-part .points .points-donate{}
.organization-details .details-part-organization .points-donate-part .points .points-donate .btn-points{width:50px;height:50px;background:var(--color03);color:var(--shades01);font-size:30px;margin:0 10px;border-radius:5px}
.organization-details .details-part-organization .points-donate-part .points .input-style{border:none;text-align:center;font-size:22px;width:200px;max-width:100%;text-transform:capitalize;font-weight:600}
.organization-details .details-part-organization .points-donate-part .points .points-value{margin-right:10px;font-size:25px;color:var(--color03);font-weight:500}
.organization-details .details-part-organization .points-donate-part .points .points-value span{margin:0 5px;color:var(--shades02)}
.organization-details .details-part-organization .donate-action{display:flex;align-items:center;justify-content:center;margin-top:35px}
.organization-details .details-part-organization .action-btn{width:100%;background:var(--color03);color:var(--color01);font-size:25px;line-height:1;padding:25px;border-radius:10px;font-weight:500}
.organization-details .donation-image img{width: 100%;height: 100%;}

.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: black;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  font-weight: 500;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: #FBE7D3;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: #FBE7D3;
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: #FBE7D3;
  color: #F14E2D;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
 /* top: 3pt;
  Uncomment this to lower the icons as requested in comments*/
  content: '';
 /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: #FBE7D3;
  cursor: default;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.arrow { 
  top: 0;
}




@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {
  .rewards-foods-section .foods-list .food-item .food-inner .menu-image{height: 350px;margin: 25px 0;}
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .rewards-foods-section .foods-list{margin: 0 -15px;}
  .rewards-foods-section .foods-list .food-item{width: 100%;max-width: calc(100% - 60px);}

  .rewards-foods-section .rewards-title{font-size: 40px;text-align: center;}
  .rewards-menu-section .right-side-menu-link{margin-top: 30px;}
  .points-donation-section .donation-list{padding: 30px 0;margin: 0 -15px;}
  .points-donation-section .donation-list .points-donation-item{width: 100%;max-width: calc(100% - 60px);padding-bottom: 0;}
  .points-donation-section .donation-title{text-align: center;font-size: 40px;}

  .conversion-content{padding: 20px;}
  .point-conversion-section .conversion-title{font-size: 30px;}

  .rewards-profile-section .profile-section .rewards-image img{object-position: left;}
  .account-tab-section{padding: 30px 0;}
  .rewards-menu-section{padding: 30px 0;}
  .point-conversion-section .conversion-image{width: 350px;height: 350px;}
  .rewards-profile-section .profile-section .text-content{width: 100%;}

  .rewards-foods-section .foods-list .food-item .food-inner .menu-image{height: 340px;}

  .merchandise-section .merchandise-title{font-size: 35px;text-align: center;}
  .merchandise-section .merchandise-list{margin: 0 -15px;padding: 30px 0;}
  .merchandise-section .merchandise-list .merchandise-item{max-width: calc(100% - 60px);width: 100%;}

}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .point-conversion-section{padding: 30px 0;margin: 50px 0;}
  .merchandise-section .merchandise-title{font-size: 30px;text-align: center;}
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 