.story-section .story-section-header{position: relative; display: flex; margin: 0 0 30px;justify-content: flex-start;}
.story-section-header .story-section-title{font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5;}
.story-section-header .story-section-title span{ color: var(--color02);}

.story-section{padding: 60px 0;}
.story-section .story-content{}
.story-section .story-content .content{}
.story-section .story-content .content .description{font-size: 15px;font-weight: 400;line-height: 1.8;color: var(--shades03);margin: 30px 0 0;}
.story-section .story-img{width: 100%;height: 100%;}
.story-section .story-img img{width: 100%;height: 100%;}

.section-mission{padding: 60px 0;position: relative;background: var(--color01);margin: 180px 0  0;}
.vision-part .section-header{position: relative; display: flex; margin: 0 0 30px;justify-content: flex-start;}
.section-mission .vision-part{position: relative;}
.vision-part .vision-list{width: 50%;max-width: 50%;position: relative;z-index: 1;}
.vision-part .vision-list .vision-item{}
.vision-part .vision-list .vision-item+ .vision-item{margin-top: 40px;}
.vision-list .vision-item .item-header{display: flex;align-items: center;margin-bottom: 12px;}
.vision-list .vision-item .item-header .item-icon{width: 40px;height: 40px;margin-right: 20px;font-size: 35px;color: var(--color03);}
.vision-list .vision-item .item-header .item-icon img{width: 100%;height: 100%;object-fit: contain;}
.vision-list .vision-item .item-header .item-title{    margin-bottom: 0;width: 100%;max-width: calc(100% - 60px);font-size: 20px;font-weight: 600;color: var(--color02);font-family: 'Poppins', sans-serif;text-transform: uppercase;}

.vision-list .vision-item .item-content{font-family: 'Poppins', sans-serif;font-size: 16px;font-weight: 400;line-height: 1.4;}
.vision-img-section{}
.vision-img-section .man-img{position: absolute;bottom: -72px;right: 0;width: 430px;z-index: 0;}
.vision-img-section .man-img img{width: 100%;height: 100%;}
.vision-img-section .grass-img{position: absolute;bottom: -72px;z-index: 0;right: -180px;width: 120px;}
.vision-img-section .grass-img img{width: 100%;height: 100%;}

.mission-part{background: var(--color03);margin-top: -140px;padding: 24px 20px;border-radius: 10px;}
.mission-part .section-header{position: relative; display: flex; margin: 0 0 30px;justify-content: flex-start;}
.mission-part .mission-list{}
.mission-part .section-header .section-title span{color: var(--color01);}
.mission-part .mission-list .mission-item{}
.mission-part .mission-list .mission-item+ .mission-item{margin-top: 35px;}

.mission-list .mission-item .misstion-tag{position: relative;}
.misstion-tag .bg-tag{ position: absolute;transform: translateY(-50%);}
.misstion-tag .bg-tag img{}
.mission-list .mission-item .misstion-tag .text{     font-size: 18px;position: relative;font-family: 'Poppins', sans-serif;padding-left: 25px;font-weight: 700;background-image: url(../../../../public/assets/img/vision-point.svg);color: var(--color02);text-transform: uppercase;background-repeat: no-repeat;height: 65px;background-size: 200px 60px;display: flex;align-items: center;}
.mission-list .mission-item .mission-description{font-family: 'Poppins', sans-serif;color: var(--shades01);font-size: 16px;line-height: 1.4;}

.team-section{padding: 60px 0;background: var(--shades05);}
.team-section .section-header{display: flex;justify-content: center;}
.team-section .team-slider-wrap{}
.team-slider-wrap .team-slider{}
.team-slider-wrap .team-slider .team-item{padding: 55px;}
.team-item .team-inner{display: flex;background: var(--color01);border-radius: 25px;overflow: hidden;}
.team-item .team-inner .team-image{width: 300px;}
.team-item .team-inner .team-image img{width: 100%;height: 100%;object-fit: cover;}
.team-item .team-content{width: 100%;max-width: calc(100% - 360px);margin:0 30px;padding: 25px 0;min-height: 250px;display: flex;align-items: flex-start;flex-direction: column;justify-content: center;}
.team-item .team-content .team-author{font-size: 35px;font-family: 'Poppins', sans-serif;font-weight: 600;color: var(--color03);margin-bottom: 20px;text-transform: capitalize;}
.team-item .team-content .team-designation{font-size: 22px;font-family: 'Poppins', sans-serif;font-weight: 600;color: var(--color03);text-transform: capitalize;}
.team-item .team-content .description{font-size: 15px;font-family: 'Poppins', sans-serif;line-height: 1.5;font-weight: 400;color: var(--shades02);margin-top: 25px;text-transform: capitalize;}
.team-slider .swiper-button-prev{font-size: 20px;color: var(--color03);}
.team-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.team-slider .swiper-button-prev:after{display:none;}
.team-slider .swiper-button-next{font-size: 20px;color: var(--color03);}
.team-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.team-slider .swiper-button-next:after{display:none;}
.team-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}

.highlights-section{padding: 60px 0;position: relative;}
.highlights-section .section-header{flex-direction: column;justify-content: center;align-items: center;padding: 0 175px;
  text-align: center;}
.highlights-section .highlights-image{position: relative;}
.highlights-section .highlights-image .image-desktop{position: absolute;top: 155px;right: 6%;z-index: -1;}
.highlights-section .highlights-image .image-desktop img{width: 100%;height: 100%;}
.highlights-section .highlight-video-part{}
.highlights-section .highlight-video-part .highlights-slider-wrap{}
.highlight-video-part .highlight-slider{}
.highlight-slider .highlight-item{margin-bottom: 55px;}  
.highlight-slider .highlight-item .highlight-video{    height: 412px;overflow: hidden;width: 100%;border-radius: 25px;position: relative;}  
.highlight-slider .highlight-item .highlight-video{position: relative;}  
.highlight-slider .highlight-item .highlight-video:before{position: absolute;content: '';width: 100%;height: 100%;background: linear-gradient(180deg, rgba(0, 0, 0, 0.108) 0%, rgba(0, 0, 0, 0.297) 100%);
}
.highlight-slider .highlight-item .highlight-video img{  width: 100%;height: 100%;object-fit: cover;}  

.video-action {align-items: center;display: flex;justify-content: center;left: 0;position: absolute;right: 0;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);z-index: 8;}
.video-action .btn-action {background-color: var(--color03);border-radius: 50%;color: var(--shades01);font-size:45px;height: 100px;padding: 0 20px;width: 100px;}
.highlight-slider .swiper-button-prev{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.highlight-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.highlight-slider .swiper-button-prev:after{display:none;}
.highlight-slider .swiper-button-next{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
.highlight-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.highlight-slider .swiper-button-next:after{display:none;}
.highlight-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.highlight-slider .swiper-pagination{z-index: 4;}


.fun-facts-section{padding: 150px 0 60px;position: relative;background: var(--shades05);}
.fun-facts-img{overflow: hidden;}
.fun-facts-img img{width: 100%;height: 100%;}
.fun-fact-tag{overflow: hidden;}
.fun-fact-tag img{width: 100%;height: 100%;}
.fun-fact-boxes{margin-top: 85px;}
.fun-fact-boxes .swiper-grid-column>.swiper-wrapper{flex-direction: unset;}
.fact-slider{}
.fact-slider .fact-item{display: flex;align-items: center;justify-content: center;padding: 44px;height: 330px;background-repeat: no-repeat;background-position: center center;background-size: contain;width: 100%;}
.item-inner{padding: 0 40px;}
.item-inner .fact-title{ font-family: 'Poppins', sans-serif;font-size: 24px;font-weight: 600;color: var(--shades01);margin-bottom: 24px;}
.item-inner .fact-description{color: var(--shades01);font-family: Poppins,sans-serif;font-size: 13px;font-weight: 300;line-height: 1.4;padding: 0 30px;   -webkit-line-clamp: 5;-webkit-box-orient: vertical;bottom: 40px;display: -webkit-box;font-size: 15px;height: 90px;line-height: 2;overflow: hidden;text-overflow: ellipsis;transition: all .3s ease;}
.fact-slider .fact-item.fact-01 {background-image: url(../../../../public/assets/img/fact-1.svg);}
.fact-slider .fact-item.fact-02 {background-image: url(../../../../public/assets/img/fact-2.svg);}
.fact-slider .fact-item.fact-03 {background-image: url(../../../../public/assets/img/fact-3.svg);}
.fact-slider .fact-item.fact-04 {background-image: url(../../../../public/assets/img/fact-4.svg);}
.fact-slider .fact-item.fact-05 {background-image: url(../../../../public/assets/img/fact-5.svg);}
.fact-slider .fact-item.fact-06 {background-image: url(../../../../public/assets/img/fact-6.svg);}


.fun-fact-boxes  .swiper {width: 100%;height: 100%;margin-left: auto;margin-right: auto;}

.fun-fact-boxes .swiper-slide {text-align: center;font-size: 18px;height: calc((100% - 30px) / 2) !important;display: flex;justify-content: center;margin-top: 0 !important;margin-bottom: 50px;align-items: center;}



.career-section{padding: 60px 0 40px;}
.career-section .career-slider{padding: 35px 50px 80px;}
.career-slider .career-item{height: 100%; max-height: 100%;}
.career-slider .career-item+ .slider-item{padding: 40px 0 20px 40px;}
.career-slider .career-item .item-inner{background: var(--color01);height: 100%;border-radius: 25px;padding: 40px;}
.career-slider .career-item .item-inner .job-title{font-size: 25px;text-transform: capitalize;font-weight: 700;color: var(--color03);margin-bottom: 20px;line-height: 1.2;}
.career-slider .career-item .item-inner .job-description{font-size: 15px;line-height: 1.4;color: var(--shades02);font-weight: 400;}
.career-slider .career-item .item-inner .job-action{position: absolute;left: 0;right: 0;bottom: 0;display: flex;align-items: center;justify-content: center;margin-bottom: -22px;}
.career-slider .career-item .item-inner .job-action .apply{background: var(--color03);color: var(--shades01);height: 50px;display: flex;align-items: center;justify-content: center;width: 40%;border-radius: 15px;font-weight: 500;}
.career-slider  .swiper-slide{max-height: 100%;height: unset;}
.career-slider .swiper-button-prev{font-size: 30px;color: var(--color03);bottom: 0;}
.career-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.career-slider .swiper-button-prev:after{display:none;}
.career-slider .swiper-button-next{font-size: 30px;color: var(--color03);bottom: 0;}
.career-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.career-slider .swiper-button-next:after{display:none;}
.career-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.career-slider .swiper-pagination{bottom: 0;}

.vision-img-section .responsive-image{display: none;}
.image-mobile{display: none;}
.fun-facts-responsive{display: none;}
.item-inner .fact-description{ }

@media screen and (max-width:1600px){

  .highlights-section .highlights-image .image-desktop{top: 270px;width: 35%;}
}
@media screen and (max-width:1440px){
  .item-inner{padding: 0 30px;}
  .item-inner .fact-description{padding: 0;font-size: 11px;}
  .mission-part{padding: 20px 10px;}
  .highlights-section .highlights-image .image-desktop{top: 270px;width: 35%;}
}

@media screen and (max-width:1366px){
  .item-inner {padding: 0 10px;}
  .fact-slider .fact-item{padding: 40px;}
  .item-inner .fact-title{margin-bottom: 14px;}
}

@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 990px) {
  .story-section .story-section-header{justify-content: center;margin-top: 35px;}
  .story-section-header .story-section-title{font-size: 40px;}
  .mission-part{margin-top: 0;border-radius: 0;}

  .vision-img-section .grass-img{display: none;}

  .fun-facts-section{padding: 60px 0;}
  .fun-facts-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
  .fun-facts-section .fun-fact-boxes .swiper-button-prev{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
  .fun-facts-section .fun-fact-boxes .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
  .fun-facts-section .fun-fact-boxes .swiper-button-prev:after{display:none;}
  .fun-facts-section .fun-fact-boxes .swiper-button-next{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
  .fun-facts-section .fun-fact-boxes .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
  .fun-facts-section .fun-fact-boxes .swiper-button-next:after{display:none;}
  .fun-facts-section .fun-fact-boxes .swiper-pagination{z-index: 5;}

  .section-mission{margin: 20px 0 0;padding: 30px 0 0;}
  .vision-img-section .man-img{transform: scaleX(-1);bottom: -12px;right: 0;width: 230px;display: none;}
  .vision-img-section .responsive-image{    display: block;position: absolute;right: 0;bottom: 0;}
  .vision-part .vision-list {width: 80%;max-width: 80%;position: relative;z-index: 1;}
.vision-part .vision-list{width: 70%;max-width: 70%;}
.mission-part .section-header{justify-content: center;}
.mission-list .mission-item .misstion-tag .text{justify-content: center;background-position: center;padding-left: 0;}
.mission-list .mission-item .mission-description{text-align: center;}
.team-slider .swiper-button-next, .team-slider .swiper-button-prev{    bottom: 0;top: unset;}
.team-slider-wrap .team-slider .team-item{padding: 55px 0;}
.team-item .team-inner{flex-direction: column;}
.team-item .team-content{max-width: calc(100% - 60px);}
.team-item .team-inner .team-image{max-width: 100%;width: 100%;height: 400px;}

.highlights-section .highlight-video-part .highlights-slider-wrap{margin-top: 150px;}
.highlights-section .highlight-video-part{margin: 30px 0;}
.image-mobile{display: block;position: absolute;width: 100%;height: 400px;margin-bottom: 150px;}
.highlights-section .highlights-image .image-desktop{display: none;}
.image-mobile img{width: 100%;height: 100%;object-fit: contain;}
.highlights-section .section-header{padding:0 0 210px 0;}
.fun-facts-img , .fun-fact-tag{display: none;}
.fun-facts-responsive{width: 100%;height: 100%;display: block;}
.fun-facts-responsive img{width: 100%;height: 100%;object-fit: cover;}
.vision-part .section-header{justify-content: center;}
.career-section .career-slider{padding:50px 0 80px 0;}
.career-slider .swiper-button-next, .career-slider .swiper-button-prev{    bottom: 0;top: unset;}

}

@media screen and (max-width:480px){
  .image-mobile{height: 480px;}
}