.contact-details{padding: 50px 0;}
.contact-details .detailsitem .details-item-title{position:relative;background:var(--color01);border-radius:10px;line-height:1.5;padding:20px 65px 20px 20px}
.contact-details .detailsitem .details-item-title::after{content:"\f054";font-family:'Font Awesome 6 Pro';position:absolute;top:50%;transform:translateY(-50%);right:25px;font-size:24px;font-weight:800;color:var(--color03)}
.contact-details .detailsitem .details-item-title .text{margin-bottom:0;color:var(--shades03);font-weight:400;font-size:25px}
.contact-details .detailsitem .details-content{padding: 40px 22px 30px 22px;border-radius:14px;box-shadow: 0px 4px 17px rgba(65, 64, 64, 0.16);margin: 20px 0 0;}
.contact-details .detailsitem.active .details-content{display:block}
.contact-details .detailsitem+ .detailsitem{margin-top:15px}
.contact-details .detailsitem .details-content .descriptions{font-size:15px;line-height:1.4;font-weight:400;color: var(--shades03);margin-bottom: 0;}
.contact-details .detailsitem .details-content .descriptions-title h5{font-size:30px;line-height:1.4;font-weight:500;color: var(--color03);margin:30px 0;}
.contact-details .detailsitem .details-content .descriptions-list{list-style: none;}
.contact-details .detailsitem .details-content .descriptions-list li {font-size:15px;line-height:1.4;font-weight:400;color: var(--shades03);margin-bottom: 10px;}
.contact-details .detailsitem .details-content ul li {font-size:15px;line-height:1.4;font-weight:400;color: var(--shades03);margin-bottom: 10px;}
.contact-details .detailsitem .helpfull-inner{display: flex;align-items: center;justify-content: space-between;background-color: var(--color01);padding:20px;border-radius:15px;}
.contact-details .detailsitem .helpfull-inner .helpfull-title{ margin-bottom: 0;color: #000;font-weight: 400;font-size: 22px;}
.emoji-content{display: flex;align-items: center;}
.emoji-content.mod{display: none;}
.emoji-content .emoji-btn{background-color: var(--shades01);color: var(--color03);font-weight: 500;font-size: 18px; border: 2px solid var(--color03);width: 110px;height: 56px;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
.emoji-content .emoji-btn .icon{padding-right: 7px;font-size: 25px;}
.emoji-content .emoji-btn+.emoji-btn{margin-left: 15px;}
.contact-details .detailsitem .details-content .content{border-bottom: 1px solid var(--shades11);margin-bottom: 35px;padding-bottom: 50px;font-size: 14px;font-weight: 400;}
.contact-details .detailsitem .details-content{display: none;}

.contact-details .detailsitem.active .details-item-title{background:var(--color03);color:var(--color01);border-radius: 10px 10px 0 0;}
.contact-details .detailsitem.active .details-item-title::after{color:var(--shades01);transform:translateY(-50%) rotate(90deg)}
.contact-details .detailsitem.active .details-item-title .text{color:var(--color01)}

/* .help-feature-section .feature-list .feature-item{width: 33.33%;max-width: calc(33.33% - 40px);margin: 20px;background: var(--shades01);box-shadow: 0px 4px 22px rgba(57, 57, 57, 0.18);border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: center;align-items: center;text-align: center;transition: all 0.3s ease;}
.help-feature-section .feature-list .feature-item:hover{background: var(--color01);cursor: pointer;} */
.contact-details .details-payments{display: flex;align-items: center;justify-content: center;padding: 40px 0;position: relative;}
.contact-details .details-payments .payments-icon{background-color: transparent;font-size: 50px;color: var(--color03);position: absolute;left: 0;}
/* .contact-details .details-payments .payments-icon.close{display: none;} */
.contact-details .details-payments .icon{width: 120px;height: 120px;overflow: hidden;font-size: 120px;color: var(--color03);margin: 0 auto;margin-bottom: 15px;}
.contact-details .details-payments .icon img{width: 100%;height: 100%;}
.contact-details .details-item .details-content{}
.contact-details .details-item .details-content .title{text-transform: capitalize;margin-bottom: 10px;font-size: 26px;font-weight: 500;}

.banner-back .back{position:absolute;left:20px;top:50%;transform:translateY(-50%)}
.banner-back .back .icon{font-size:35px;color:var(--shades01)}

@media screen and (max-width: 990px) {
    .contact-details .detailsitem .details-item-title .text{font-size: 18px;}
}
@media screen and (max-width: 480px) {
    .emoji-content.mod{display: flex;align-items: center;justify-content: center;margin: 30px 0 0;}
    .emoji-content{display: none;}
}