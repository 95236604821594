.loyalty-section{padding: 60px 0;}
.loyalty-section .loyalty-part{display: flex;justify-content: center;text-align: center;flex-wrap: wrap;margin: 0 -30px;padding-top: 35px;}
.loyalty-section .loyalty-part .loyalty-item.arrow{top: 100px;}
.loyalty-section .loyalty-part .loyalty-item{position: relative;width: 20%;max-width: calc(20% - 30px);margin: 15px;}
.loyalty-section .loyalty-part .loyalty-item .icon{width: 200px;height: 200px;position: relative;background: var(--color03);margin: 0 auto;display: flex;align-items: center;justify-content: center;box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);border-radius: 20px 20px 20px 0;border: 8px solid var(--color01);margin-bottom: 25px;font-size: 90px;color: var(--shades01);}
.arrow {position: relative;top: 100px;}
.arrow img{width: 100%;}
  
.loyalty-section .loyalty-part .loyalty-item .icon img{}
.loyalty-part .loyalty-content{}
.loyalty-part .loyalty-content .title{font-size: 20px;font-weight: 600;margin-bottom: 15px;}
.loyalty-part .loyalty-content .description{font-size: 14px;line-height: 1.4;font-weight: 400;}
.loyalty-part .loyalty-content .description p{}




@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	.loyalty-section .loyalty-part .loyalty-item.arrow{height: 220px;display: flex;justify-content: center;align-items: center;}
}
@media screen and (max-width: 990px) {
  .loyalty-section .loyalty-part{flex-wrap: wrap;margin: unset;}
  .loyalty-section .loyalty-part .loyalty-item{max-width: 100%;width: 100%;}
  .loyalty-section .loyalty-part .loyalty-item.arrow{transform: rotate(90deg);width: 200px;height: 250px;display: flex;justify-content: center;top: unset;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 