.user-profile-section{margin-top:-27px}
.user-profile-section .profile-form .profile-content .intro{font-size:24px;margin-bottom:10px;line-height:1;font-weight:500}
.user-profile-section .profile-form .profile-content .intro span{color:var(--color03);text-transform:capitalize}
.user-profile-section .profile-form .profile-content .points{font-size:30px;line-height:1;font-weight:500;margin:10px 0}
.user-profile-section .profile-form .profile-content .points span{color:var(--color03);text-transform:capitalize}
.user-profile-section .profile-form .profile-content .points-calculation{font-size:16px;line-height:1;font-weight:500}
.user-profile-section .profile-form .profile-content .points-calculation span{color:var(--color03);margin-right:5px}
.user-profile-section .profile-form{display:flex;background:var(--color01);border-radius:0 0 30px 30px;padding:20px 30px;border:2px solid var(--color03);align-items:center;width: 540px;margin: 0 auto;}
.user-profile-section .profile-form .no-image{width:100%;height:100%;overflow:hidden;border-radius:50%}
.user-profile-section .profile-form .profile-image-edit{position:relative;width:200px;height:200px}
.user-profile-section .profile-form .profile-content{width:100%;max-width:calc(100% - 220px);margin-left:20px}
.user-profile-section .profile-form .no-image img{width:100%;height:100%;object-fit:cover}
.user-profile-section .profile-form .icon{background:var(--color03);width:40px;height:40px;display:flex;align-items:center;justify-content:center;border-radius:50px;position:absolute;right:30px;bottom:10px;font-size:18px}
.profile-image-edit .upload-file{display:none}
.profile-image-edit .file-input-label{cursor:pointer;justify-content:center;display:flex;align-items:center;border-radius:10px;font-weight:400;color:var(--color01);font-size:14px;margin-bottom:0}


.account-tab-section{padding: 60px 0;position: relative;}
.account-tab-section .tab-list{display: flex;justify-content: center;}
.account-tab-section .tab-list .tab-item{}
.account-tab-section .tab-list .tab-item+ .tab-item{margin-left:20px;}
.account-tab-section .tab-list .tab-item .tab-link{    background: var(--shades01);color: var(--color03);border: 1px solid var(--color03);border-radius: 6px;padding: 8px 12px;font-size: 16px;font-weight: 400;}
.account-tab-section .tab-list .tab-item .tab-link.active{ background: var(--color03);color: var(--shades01);}

.account-option-section{}
.account-option-section .account-option{box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.2);margin: 0 0 40px;border-radius: 16px;overflow: hidden;display: block;}
.account-option-section .account-option .acount-img{width: 100%;height: 600px;overflow: hidden;}
.account-option-section .account-option .acount-img img{width: 100%;height: 100%;object-fit: cover;}
.account-option-section .account-option .account-content{background: var(--color03);color: var(--shades01);text-align: center;padding: 30px 15px;}
.account-option-section .account-option .account-content .text{font-family: 'Playfair Display', serif;margin-bottom: 0;font-size: 38px;}

.account .section-header{justify-content: flex-start;}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	.account-option-section .account-option .acount-img{height: 320px;}
  .account-option-section .account-option .account-content .text{font-size: 25px;}
}
@media screen and (max-width: 990px) {

  .user-profile-section .profile-form{width: unset;}
  .user-profile-section .profile-form .profile-image-edit{width: 120px;height: 120px;}
  .user-profile-section .profile-form .profile-content{max-width: calc(100% - 140px);}
  .account-tab-section .tab-list{flex-wrap: wrap;justify-content: flex-start;margin: 0 -15px;}

  .account-option-section .account-option .account-content{padding: 20px 5px;}
  .account-tab-section .tab-list .tab-item .tab-link{margin-top: 12px;display: flex;}
  .account-tab-section .tab-list .tab-item{margin-left: 20px;}
  .account-option-section .account-option .acount-img{height: 140px;}
  .account-option-section .account-option .account-content .text{font-size: 16px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 