.apply-section{padding: 60px 0;}
.apply-section .form-section{width: 80%;margin: 0 auto;background: var(--color01);border-radius: 20px;padding: 60px;}
.apply-section .form-section .title{font-family: 'Playfair Display', serif;color: var(--color03);font-size: 34px;font-weight: 700;margin: 0;line-height: 1;margin-bottom: 25px;text-transform: capitalize;}
.apply-section .form-section .required-text{font-size: 14px;font-weight: 400;text-transform: capitalize;}
.apply-section .form-section .required-text span{color: var(--color03);}
.apply-section .dropdown{background: var(--color03);color: var(--color01);border-radius: 10px;}
.apply-section .dropdown.location-dropdown{background: var(--color01);color: var(--color03);}
.apply-section .dropdown.location-dropdown .dropdown-toggle{color: var(--color03);}
.apply-section .dropdown.location-dropdown .dropdown-toggle:after{color: var(--color03);}
.apply-section .dropdown{}
.apply-section .dropdown .dropdown-toggle{padding: 0;height: 50px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--color01);font-size: 14px;font-weight: 500;padding: 0 20px;}
.apply-section .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.apply-section .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.apply-section .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 50px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.apply-section .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color03) !important;}
.apply-section .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.apply-section .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.apply-section .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.apply-section .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}

.apply-section .dropdown .dropdown-toggle:after {border: 0;color: #fff;color: var(--color01);content: "\f078";display: block;font-family: Font Awesome\ 5 Pro;font-size: 14px;font-weight: 500;margin: 2px 0 0;opacity: 1;position: absolute;right: 12px;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);transition: all .3s ease;vertical-align: initial;}
.apply-section .dropdown.show .dropdown-toggle:after {-webkit-transform: translateY(-50%) rotate(-180deg);transform: translateY(-50%) rotate(-180deg);}

.apply-section .input-file {display: flex;align-items: center;justify-content: center;height: 40px;}
.apply-section .upload-file{display: none;}
.apply-section .file-input-label {cursor: pointer;justify-content: center;display: flex;align-items: center;border-radius: 10px;font-weight: 400;color: var(--color01);font-size: 14px;padding: 10px 12px;background-color: var(--color03);box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);margin-bottom: 0;width: 280px;height: 50px;}
.upload-section{display: flex;justify-content: center;margin: 25px 0 0 ;flex-wrap: wrap;}
.upload-section .upload-action{margin-top: 35px;}
.upload-section .upload-action+ .upload-action{margin-left: 15px;}
.form-action{}
.form-action .submit-action{background: none;}

.apply-section .form-content{margin-top: 25px;}
.apply-section .form-content .form-row{margin-bottom: 15px;}
.apply-section .form-content .input-style{}
.apply-section .form-content .input-style+ .input-style{margin-top: 25px;}
.apply-section .form-content .input-style .form-control{border: none;height: 50px;background: rgba(255, 90, 57, 0.36);border-radius: 10px;}
.apply-section .form-content .input-style .form-control.message{resize: none;height: 145px;padding: 12px;}
.apply-section .form-content .form-action{margin-top: 60px;display: flex;justify-content: center;}
.apply-section .form-content .form-action .form-btn{width: 320px;background: var(--color03);color: var(--shades01);font-size: 20px;padding: 15px;border-radius: 10px;font-family: 'Inter', sans-serif;}
.apply-section .form-content .input-style .form-control.message{    resize: none;height: 145px;padding: 12px;}
.apply-section .form-content .input-style .form-control::-webkit-input-placeholder {color: var(--shades01) !important;}

.apply-section .form-content .input-style .form-control:-ms-input-placeholder {color: var(--shades01) !important;}

.apply-section .form-content .input-style .form-control::placeholder {color: var(--shades01) !important;}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	.upload-section .upload-action+ .upload-action{margin-left: 15px !important;} 
}
@media screen and (max-width: 990px) {
  .apply-section .form-section{width: 100%;padding:30px 20px;}
  .apply-section .form-content .input-style{margin-top: 15px;}
  .upload-section .upload-action+ .upload-action{margin-left: 0;}
  .apply-section .form-content .form-action .form-btn{text-align: center;}
  .apply-section .form-content .form-row{margin-bottom: 0;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .apply-section .form-section .title{text-align: center;}
  
	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}