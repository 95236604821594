.order-list-section .section-header{justify-content: flex-start;}
.order-search-section .dropdown{background: var(--color03);margin-left: 10px;color: var(--color01);border-radius: 10px;}
.order-search-section .dropdown.date-dropdown{}
.order-search-section .dropdown{}
.order-search-section .dropdown .dropdown-toggle{padding: 0;height: 50px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--color01);font-size: 14px;font-weight: 500;padding: 0 20px;}
.order-search-section .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.order-search-section .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.order-search-section .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.order-search-section .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color03) !important;}
.order-search-section .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.order-search-section .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.order-search-section .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.order-search-section .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}

.order-search-section .dropdown .dropdown-toggle:after {border: 0;color: #fff;color: var(--color01);content: "\f078";display: block;font-family: Font Awesome\ 5 Pro;font-size: 14px;font-weight: 500;margin: 2px 0 0;opacity: 1;position: absolute;right: 12px;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);transition: all .3s ease;vertical-align: initial;}
.order-search-section .dropdown.show .dropdown-toggle:after {-webkit-transform: translateY(-50%) rotate(-180deg);transform: translateY(-50%) rotate(-180deg);}



.order-list-section .bill-section{background:var(--color01);position:relative;padding:20px 25px;}
.order-list-section .order-history-slider .order-wrap-slider{padding:30px}
.order-list-section .order-history-slider .swiper-wrapper{padding:60px 0}
.order-list-section .order-history-slider .swiper-button-next,.swiper-rtl .swiper-button-prev{right:0}
.order-list-section .order-history-slider .swiper-button-prev,.swiper-rtl .swiper-button-next{left:0}
.order-list-section .bill-section:before{background:linear-gradient(-45deg,#fbe7d3 16px,red 16px,blue 16px,transparent 0),linear-gradient(45deg,#fbe7d3 16px,transparent 0);background-position:left top;background-repeat:repeat-x;background-size:14px 32px;content:" ";display:block;height:32px;width:100%;position:absolute;top:-30px;left:0}
.order-list-section .bill-section:after{background:linear-gradient(-45deg,transparent 16px,#fbe7d3 0),linear-gradient(45deg,transparent 16px,#fbe7d3 0);background-repeat:repeat-x;background-position:left bottom;background-size:14px 32px;content:"";display:block;width:100%;height:32px;position:absolute;bottom:-30px;left:0}
.order-list-section .bill-section .bill-title{text-align:center;font-size:60px;font-weight:700;line-height:.8;margin:0 25px 22px;color:var(--color03);text-transform:uppercase;font-family:'Playfair Display',serif}
.order-list-section .bill-section .bill-date{text-align:center;color:var(--shades07);font-size:20px;font-family:'Playfair Display',serif;text-transform:uppercase;font-weight:500}
.order-list-section .bill-section .bill-date span+ span{margin-left:8px}
.order-list-section .bill-list{margin:34px 0 0;border-top:2px solid var(--shades02);border-bottom:2px solid var(--shades02);padding:30px 0}
.order-list-section .delivery-section{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.order-list-section .delivery-section .icon{font-size:30px;color:var(--color03)}
.order-list-section .delivery-section .text-part .text{font-size:16px;font-weight:600;text-transform:capitalize}
.order-list-section .delivery-section .text-part .sub-text{font-size:16px;font-family:'Playfair Display',serif;color:var(--shades07);font-weight:300}
.order-list-section .delivery-section .charges{font-size:20px;font-weight:600}
.order-list-section .total-price{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.order-list-section .total-price .text{font-size:22px;font-weight:600;font-family:'Playfair Display',serif;margin-bottom:5px}
.order-list-section .total-price .items{font-size:16px;color:var(--shades07);font-weight:400}
.order-list-section .total-price .price{font-size:20px;font-weight:600}
.order-list-section .payment-action{margin-top:35px;text-align:center;display:flex;justify-content:center}
.order-list-section .payment-action .pay-btn{height:40px;background:var(--color03);border-radius:50px;font-size:20px;font-family:'Playfair Display',serif;color:var(--shades01);font-weight:600;letter-spacing:1px;padding:25px;text-transform:uppercase;display:flex;align-items:center;justify-content:center;width:80%}
.order-list-section .bill-list .bill-item{display: flex;justify-content: space-between;align-items: flex-start;}
.order-list-section .bill-list .bill-item .menu-name .text{font-size:16px;font-weight:600;font-family:'Playfair Display',serif}
.order-list-section .bill-list .bill-item .menu-name .sub-text{font-size:12px;font-weight:400;font-family:'Playfair Display',serif;color:var(--secondary);text-transform:capitalize}
.order-list-section .bill-list .bill-item .menu-name{width: 100%;max-width: calc(100% - 180px);}
.order-list-section .bill-list .menu-item-number{display:flex;align-items:center;width: 80px;justify-content: flex-start;}
.order-list-section .bill-list .menu-item-number .btn-action{background:var(--color03);color:var(--shades01);font-size:10px;width:22px;height:22px;border-radius:5px;display:flex;align-items:center;justify-content:center;}
.order-list-section .bill-list .menu-item-number .input-style{width:30px;height:25px;text-align:center;border:none;background:none;font-size:18px;font-weight:500;}
.order-list-section .bill-list .menu-price{font-size:18px;font-weight:500;width: 100px;text-align: right;}

.order-history-slider .swiper-wrapper{position:relative;}
.order-history-slider .swiper-button-prev{font-size: 24px;color: var(--shades01);background: var(--color03);width: 50px;height: 50px;border-radius: 50%;}
.order-history-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.order-history-slider .swiper-button-prev:after{display:none;}
.order-history-slider .swiper-button-next{font-size: 24px;color: var(--shades01);background: var(--color03);width: 50px;height: 50px;border-radius: 50%;}
.order-history-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.order-history-slider .swiper-button-next:after{display:none;}
.order-history-slider .swiper-pagination.swiper-pagination-bullets{ bottom: 0; display: block;}
.order-history-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
.order-list-section .bill-list .menu-item-number .sub-text{font-size: 14px;font-weight: 400;color: var(--secondary);}

@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {
    .order-list-section .payment-action .pay-btn{width: 100%;padding: 8px 15px;height: 45px;font-size: 14px;}
    .order-list-section .payment-action a+ a{margin-left: 15px;}
    .order-list-section .bill-section .bill-title{font-size: 40px;}
}
@media screen and (max-width: 1366px) {
    .order-list-section .payment-action{flex-wrap: wrap;}
    .order-list-section .payment-action a+ a{margin-left: 15px;}
    .order-list-section .payment-action .pay-btn{width: 100%;font-size: 14px;}
    .modal-addons .modal-dialog {
        max-width: 980px;
    }
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
    .order-list-section .section-header{text-align: center;justify-content: center;}
    .order-list-section .payment-action .pay-btn{font-size: 16px;width: 100%;margin-bottom: 15px;}
    .order-list-section .payment-action{flex-wrap: wrap;}
    
    .order-list-section .bill-list .bill-item{flex-wrap: wrap;}
    .order-list-section .bill-list .bill-item .menu-name{max-width: 100%;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 