.profile-details-section{padding: 60px 0;}
.profile-details-section img{width: 100%;height: 100%;}
.profile-details-section .section-header{justify-content: flex-start;}
.profile-details-section .profile-form{display: flex;background: var(--color01);border-radius: 30px;padding: 30px;}
.profile-details-section .profile-form .no-image{width: 250px;height: 250px;border-radius: 50%;overflow: hidden;border: 2px solid var(--color03);}
.profile-details-section .profile-form .no-image img{width:100%;height:100%}
.profile-details-section .profile-form .icon{background:var(--color03);width:40px;height:40px;display:flex;align-items:center;justify-content:center;border-radius:50px;position:absolute;right:30px;bottom:40px;font-size:18px}
.profile-details-section .profile-form .profile-image-edit{position:relative;width:280px;height:280px}
.profile-details-section .profile-form .profile-edit{width:100%;max-width:calc(100% - 320px);margin-left:40px}
.profile-details-section .profile-image-edit .upload-file{display:none}
.profile-details-section .profile-image-edit .file-input-label{cursor:pointer;justify-content:center;display:flex;align-items:center;border-radius:10px;font-weight:400;color:var(--color01);font-size:14px;padding:10px 12px;margin-bottom:0}
.profile-details-section .profile-edit .input-style{margin-top: 25px;}
.profile-details-section .profile-edit .input-style .form-label{font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.profile-details-section .profile-edit .input-style .form-control{border: 1px solid var(--color03);height: 44px;border-radius: 10px;}
.profile-details-section .profile-edit .form-action{margin: 40px 0;text-align: center;}
.profile-details-section .profile-edit .form-action .btn-change{background: var(--color03);color: var(--shades01);font-size: 18px;text-transform: capitalize;padding: 12px 30px;border-radius: 8px;font-weight: 400;}



.profile-details-section .custom-radio{position: relative;padding: 8px 30px 8px 0;margin:0;display: flex; align-items:center; line-height:1;}
.profile-details-section .custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.profile-details-section .custom-radio .custom-radio-label{position: relative;margin: 0;font-size: 14px;font-weight: 500;color: var(--color02);line-height: 1;width: 100%;padding: 0 30px 0 0;color: var(--color03);}
.profile-details-section .custom-radio .custom-radio-label:before{content:'';position:absolute;width:28px;height:28px;background:var(--shades01);border:1px solid var(--color03);top:50%;right:-7px;transform:translateY(-50%);border-radius:3px}
.profile-details-section .custom-radio .custom-radio-label .text{width:100%;color:var(--shades02)}
.profile-details-section .custom-radio .custom-radio-label:after{content:"\f111";position:absolute;font-family:"Font Awesome 5 Pro";width:14px;height:14px;top:50%;right:0;transform:translateY(-50%);font-weight:600;display:none;align-items:center;justify-content:center;text-align:center;border-radius:4px;background:var(--color03);margin:0 auto}
.profile-details-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:flex;}
.profile-details-section .custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: var(--shades01);border: 1px solid var(--color03);}

.shipping-address{background: var(--color01);padding: 30px;border-radius: 30px;}
.shipping-address .shipping-item{}

.shipping-address .shipping-item .address-item{display:flex;align-items:center;background:var(--shades01);padding-right:50px;border-radius:10px;height:52px;border:1px solid var(--color03);justify-content:space-between;position:relative}
.shipping-address .shipping-item .address-item .address-content{width:100%;color:var(--shades02);font-size:14px;font-weight:500;line-height:1;padding:15px 10px 15px 20px;cursor:pointer}
.shipping-address .shipping-item .custom-radio{padding:0;position:absolute;top:50%;right:28px;transform:translateY(-50%);height:28px;width:28px;display:block}
.shipping-address .shipping-item .address-item .address-content{width:100%;color:var(--shades02);font-size:14px;font-weight:500;line-height:1}

/* .shipping-address .shipping-item .custom-radio{background:var(--shades01);padding:15px 20px;border-radius:10px;height:52px;border:1px solid var(--color03)} */


.shipping-address .shipping-item+ .shipping-item{margin-top:20px}
.add-new-address{display:flex;align-items:center;justify-content:center;margin-top:25px}
.add-new-address .add-btn{background:var(--color03);color:var(--shades01);font-size:18px;text-transform:capitalize;padding:12px 30px;border-radius:8px;font-weight:400}







.profile-details-section .payment-type{background: var(--color01);padding: 30px;border-radius: 30px;}
.profile-details-section .payment-type .payment-item{}
.profile-details-section .payment-type .payment-item .custom-radio{background:var(--shades01);padding:15px 20px;border-radius:10px;border:1px solid var(--color03)}
.profile-details-section .payment-type .payment-item+ .payment-item{margin-top:20px}
.profile-details-section .add-new-type{display:flex;align-items:center;justify-content:center;margin-top:25px}
.profile-details-section .add-new-type .add-btn{background:var(--color03);color:var(--shades01);font-size:18px;text-transform:capitalize;padding:12px 30px;border-radius:8px;font-weight:400}
.profile-details-section .payment-details-section .custom-radio-label{display:flex;align-items:center}
.payment-details-section .custom-radio-label .icon{font-size:23px;width:30px;height:22px;margin-right:10px}
.profile-details-section .save-all-data{border-top:1px solid var(--shades06);padding:30px 0 0;margin:30px 0 0;display:flex;justify-content:center}
.profile-details-section .save-all-data .btn-action{background:var(--color03);color:var(--shades01);font-size:18px;text-transform:capitalize;padding:12px 30px;border-radius:8px;font-weight:400}
.profile-details-section .save-all-data .btn-action+ .btn-action{margin-left:20px}
.profile-details-section .save-all-data .btn-action.alt{color:var(--color03);border:1px solid var(--color03);background:var(--shades01)}



.modal-newaddress .modal-dialog{max-width: 900px;}
.modal-newaddress .modal-header{background-color: transparent;align-items: center;border-bottom: none;padding: 0;}
.modal-newaddress .modal-title{color: #fff;font-weight: 600;font-size: 40px;line-height: 1;font-family: 'Playfair Display', serif;margin: 0 0 32px;letter-spacing: 0.3px;}
.modal-newaddress .modal-content{border-radius: 23px;border: none;background-color:transparent;}
.modal-newaddress .modal-body{background-color: var(--color01);border-radius: 23px 23px 0px 0px;overflow: hidden;padding: 30px 40px;}
.modal-newaddress .modal-body .address-inner .address-option{display: flex;align-items: center;}
.modal-newaddress .custom-radio{position: relative;padding: 8px 30px 8px 0;margin:0;display: flex; align-items:center; line-height:1;}
.modal-newaddress .custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.modal-newaddress .custom-radio .custom-radio-label{position: relative;margin: 0;font-size: 12px;font-weight: 500;color: var(--shades01);line-height: 1;width: 100%;padding: 0 0 0 25px;}
.modal-newaddress .custom-radio .custom-radio-label:before{content:'';position:absolute;width:22px;height:22px;background:var(--color03);border:1px solid var(--color03);top:50%;left:-6px;transform:translateY(-50%);border-radius:50%}
.modal-newaddress .custom-radio .custom-radio-label .text{width:100%;color:var(--color03);font-size: 14px;font-weight: 400;}
.modal-newaddress .custom-radio .custom-radio-label:after{content:"\f111";position:absolute;font-family:"Font Awesome 5 Pro";width:10px;height:10px;top:50%;left:0;transform:translateY(-50%);font-weight:600;display:none;align-items:center;justify-content:center;text-align:center;border-radius:50%;background:var(--shades01);margin:0 auto}
.modal-newaddress .custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:flex;}
.modal-newaddress .custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: var(--color03);}
.modal-newaddress .modal-footer{background-color: var(--color01);border-top: none;border-radius: 0px 0px 23px 23px;}
.modal-backdrop.show{opacity: .82;}

.modal-newaddress .modal-footer{display: flex;align-items: center;justify-content: center;}
.modal-newaddress .modal-footer .save-action{margin: 0 0 30px;}
.modal-newaddress .modal-footer .save-action .save-btn{background:var(--color03);color:var(--shades01);width:154px;height:58px;font-weight:600;font-size:14px;border-radius:13px}
.modal-newaddress .modal-footer .save-action .cancel-btn{background:transparent;color:var(--color03);border:1px solid var(--color03);width:154px;height:58px;font-weight:600;font-size:14px;border-radius:13px;margin-left:20px}
.modal-newaddress .address-form{margin: 30px 0 0;}
.modal-newaddress .address-form .input-style .form-label{font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.modal-newaddress .address-form .input-style .form-control{border: 1px solid var(--color03);height: 55px;border-radius: 13px;color: var(--shades02);font-size: 16px;font-weight: 300;letter-spacing: 0.3px;}
.modal-newaddress .address-form .input-style .form-control::placeholder{color: var(--shades11) !important;}
.modal-newaddress .address-form .form-action{margin: 40px 0;text-align: center;}



@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .profile-details-section .profile-form{flex-wrap: wrap;justify-content: center;}
  .profile-details-section .profile-form .profile-edit{max-width: 100%;margin-left: 0;margin-top: 15px;}

  .profile-details-section .img-part{display: none;}
  .profile-details-section .shipping-img{display: none;}

  .profile-details-section .section-header{justify-content: center;}
  .modal-newaddress .modal-body .address-inner .address-option{flex-wrap: wrap;}
  .modal-newaddress .modal-footer .save-action{display: flex;}
  .modal-newaddress .modal-footer .save-action .save-btn , .modal-newaddress .modal-footer .save-action .cancel-btn{width: unset;padding: 15px 20px;}
  .modal-newaddress .modal-footer{justify-content: flex-start;padding: 15px 0;}
  .profile-details-section .save-all-data .btn-action{font-size: 14px;}
  .profile-details-section .save-all-data{padding: unset;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .add-new-address .add-btn{font-size: 14px;}
	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 