.description-section .left-section-header{position: relative; display: flex; margin: 0 0 30px;justify-content: flex-start;}
.description-section .left-section-header .left-section-title{font-family: 'Playfair Display', serif; color: var(--shades02); font-size: 64px; font-weight: 700; margin: 0; line-height: 1.5;text-transform: capitalize;}
.description-section .left-section-header .left-section-title span{ color: var(--color02);}


.description-section{padding: 60px 0;}
.description-section .description-content{}
.description-section .description-content .content{}
.description-section .description-content .content .description{font-size: 20px;font-weight: 400;line-height: 1.8;color: var(--shades03);margin: 30px 0 0;}
.description-section .description-img{width: 100%;height: 100%;}
.description-section .description-img img{width: 100%;height: 100%;}

.range-section{padding: 60px 0;background: var(--color03);position: relative;overflow: hidden;max-height: 100%;min-height: 100%;}
.range-section .range-content{position: relative;}
.range-section .range-content .range-title{font-family: 'Playfair Display', serif;color: var(--shades01);font-size: 84px;font-weight: 700;line-height: 1.5;margin: 0;}
.range-section .range-content .range-tag{position: relative;display: flex;align-items: center;margin: 25px 0;}
.range-section .range-content .range-tag .text{position: relative;z-index: 2;padding-left: 15px;margin-bottom: 0;font-size: 45px;color: var(--color03);font-family: 'Poppins', sans-serif;font-weight: 600;text-transform: capitalize;}
.range-section .range-content .range-tag:before{position: absolute;content: '';width: 500%;height: 80px;background: var(--color01);z-index: 1;border-radius: 8px;}
.range-section .range-content .range-img{height: 100%;left: calc(-66.67% - 60px);max-width: 66.67%;position: absolute;top: 0;width: 100%;}
.range-section .range-content .range-img img{height: 100%;}
.range-section .range-content .range-other-content{margin-top: 55px;}
.range-section .range-content .range-other-content .sub-title{font-size: 45px;color: var(--shades01);font-family: 'Poppins', sans-serif;text-transform: uppercase;}
.range-section .range-content .range-other-content .description{font-size: 25px;font-weight: 400;line-height: 1.4;color: var(--shades01);margin: 20px 0 0;}
.range-section .range-content .range-other-content .range-btn{display: flex;margin: 30px 0 0;}
.range-section .range-content .range-other-content .range-btn .menu{height:55px;display: flex;justify-content: center;align-items: center;padding: 0 16px;background-color: var(--color01);color: var(--color03);border-radius: 8px;font-family: 'Poppins', sans-serif;font-weight: 600;font-size: 20px;width: 200px;}

.form-section{position: relative;padding: 60px 0;z-index: 0;margin: 0 0 160px;}
.form-section .form-img{position: absolute;top: 0;left: -4%;z-index: -1;height: calc(100% + 100px);transform: scaleX(-1);}
.form-section .form-img img{width: 100%;height: 100%;object-fit: contain;}
.form-part{}
.form-part .form{}
.form-part .form .form-title{font-family: 'Playfair Display', serif;color: var(--color02);font-size: 64px;font-weight: 700;line-height: 1.5;margin: 0;}
.form-part .form .form-content{margin-top: 25px;}
.form-part .form .form-content .input-style{}
.form-part .form .form-content .input-style+ .input-style{margin-top: 25px;}
.form-part .form .form-content .input-style .form-control{background: var(--shades06);border: none;height: 60px;}
.form-part .form .form-content .input-style .form-control.message{resize: none;height: 145px;padding: 12px;}
.form-part .form .form-content .form-action{margin-top: 60px;display: flex;justify-content: center;}
.form-part .form .form-content .form-action .form-btn{width: 200px;background: var(--color03);color: var(--shades01);font-size: 20px;padding: 15px;border-radius: 10px;font-family: 'Inter', sans-serif;}

@media screen and (max-width:1600px) {
  .range-section{padding: 40px 0;}

  .form-section .form-img{height: calc(100% + -150px);}
}
@media screen and (max-width:1440px) {
  .range-section .range-content .range-title{font-size: 64px;}
}
@media screen and (max-width:1366px) {
  .range-section .range-content .range-title{font-size: 54px;}
  .range-section .range-content .range-tag .text{font-size: 35px;}
  .range-section .range-content .range-other-content .sub-title{font-size: 35px;}
}
@media screen and (max-width: 1024px) {


  .range-section .range-content .range-title{font-size: 40px;}
  .range-section .range-content .range-tag:before{height: 70px;}
  .range-section .range-content .range-tag .text{font-size: 26px;}
  .range-section .range-content .range-other-content .sub-title{font-size: 30px;}
  .range-section .range-content .range-other-content .description{font-size: 18px;}

  .form-section .form-img{height: calc(100% + -300px);}
  .form-part .form .form-title{font-size: 50px;}

  .description-section .description-content .content .description{font-size: 16px;}
}
@media screen and (max-width: 990px) {

  .range-section .range-content .range-img{position: relative;left: auto;top: auto;height: auto;max-height: 100%;max-width: 100%;margin: 50px auto;}
  .range-section .range-content .range-img img{width: 100%;}
    .range-section .range-content .range-tag:before{width: 100%;}
    .range-section .range-content .range-tag .text{text-align: center;}
    .range-section .range-content .range-title{text-align: center;}
    .range-section .range-content .range-other-content{text-align: center;}
    .range-section .range-content .range-other-content .range-btn{justify-content: center;}
    .range-section .range-content .range-tag{text-align: center;justify-content: center;}

    .form-section .form-img{display: none;}
    .form-part .form .form-title{font-size: 40px;}

    .description-section .left-section-header .left-section-title{justify-content: center;font-size: 34px;text-align: center;}
    .description-section .left-section-header{justify-content: center;}
}
@media screen and (max-width: 480px) {

  .description-section{padding: 40px 0;}
  .description-section .description-img{width: 80%;height: 80%;margin: 0 auto;}
  .description-section .description-content .content .description{text-align: center;}

  .range-section .range-content .range-img img{width: 100%;}

  .form-section{padding: 40px 0;margin: 0;}
  .form-part .form .form-title{font-size: 40px;}
}