.delivery-order-section{padding: 60px 0;}
.delivery-order-section .bill-section{background:var(--color01);position:relative;padding:20px 25px;position: sticky;top: 40px;margin-bottom: auto;width: 350px;}
.delivery-order-section .bill-section:before{background:linear-gradient(-45deg,#fbe7d3 16px,red 16px,blue 16px,transparent 0),linear-gradient(45deg,#fbe7d3 16px,transparent 0);background-position:left top;background-repeat:repeat-x;background-size:14px 32px;content:" ";display:block;height:32px;width:100%;position:absolute;top:-30px;left:0}
.delivery-order-section .bill-section:after{background:linear-gradient(-45deg,transparent 16px,#fbe7d3 0),linear-gradient(45deg,transparent 16px,#fbe7d3 0);background-repeat:repeat-x;background-position:left bottom;background-size:14px 32px;content:"";display:block;width:100%;height:32px;position:absolute;bottom:-30px;left:0}
.delivery-order-section .bill-section .bill-title{text-align:center;font-size:60px;font-weight:700;line-height:.8;margin:0 25px 22px;color:var(--color03);text-transform:uppercase;font-family:'Playfair Display',serif}
.delivery-order-section .bill-section .bill-date{text-align:center;color:var(--shades07);font-size:20px;font-family:'Playfair Display',serif;text-transform:uppercase;font-weight:500}
.delivery-order-section .bill-section .bill-date span+ span{margin-left:8px}

.delivery-order-section .bill-list{margin:34px 0 0;border-top:2px solid var(--shades02);border-bottom:2px solid var(--shades02);padding:30px 0}
.delivery-order-section .bill-list .empty-cart{display : block;text-align: center; font-size: 16px;font-weight: 600;text-transform: capitalize;}
.delivery-order-section .bill-list .bill-item{display:flex;align-items:center; justify-content: space-between;}
.delivery-order-section .bill-list .bill-item .menu-name{width: 100%;}
.delivery-order-section .bill-list .bill-item .menu-price{font-size: 18px;font-weight: 500;width: 100px;min-width: 100px;display: flex;align-items: center;justify-content: flex-end;}
.delivery-order-section .bill-list .bill-item .remove-btn{background:transparent;color:var(--color03);font-size:10px;width:18px;height:18px;display:flex;align-items:center;justify-content:center;margin-left:4px}
.delivery-order-section .bill-list .bill-item .menu-item-number{display: flex;align-items: center;justify-content: center;width: 80px;min-width: 80px;}
.delivery-order-section .bill-list .menu-item-number .btn-action{background:var(--color03);color:var(--shades01);font-size:10px;width:22px;height:22px;border-radius:5px;display:flex;align-items:center;justify-content:center}
.delivery-order-section .bill-list .menu-item-number .input-style{width:30px;height:25px;text-align:center;border:none;background:none;font-size:18px;font-weight:500}
.delivery-order-section .bill-list .bill-item .menu-name .text{font-size:16px;font-weight:600;font-family:'Playfair Display',serif}
.delivery-order-section .bill-list .bill-item .menu-name .sub-text{font-size:12px;font-weight:400;font-family:'Playfair Display',serif;color:var(--secondary);text-transform:capitalize}
.delivery-order-section .bill-list .bill-item+.bill-item{margin-top:20px}



.delivery-order-section .coupon-section{display: flex;border-bottom: 2px solid var(--shades02);padding: 20px 0;justify-content: space-between;}
.delivery-order-section .coupon-section .icon{font-size: 30px;color: var(--color03);width: 35px;height: 35px;display: flex;align-items: center;justify-content: center;}
.delivery-order-section .coupon-section .content-wrap{display: flex;align-items: center;justify-content: center;width: calc(100% - 30px);}
.delivery-order-section .coupon-section .button-wrap{width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;}
.delivery-order-section .coupon-section .button-wrap .action-btn{width: 20px;height: 20px;color: var(--danger);background-color: transparent;font-size: 14px;padding: 0;}
.delivery-order-section .coupon-section .text-part{display: flex;align-items: center;justify-content: center;margin-left: 10px;font-size: 13px;font-weight: 500;}
.delivery-order-section .coupon-section .text-part .form-control{border-top-right-radius: 0;border-bottom-right-radius: 0;border-color: var(--color03);}
.delivery-order-section .coupon-section .charges{font-size: 16px;font-weight: 500;display: flex;align-items: center;justify-content: center;margin-left: 10px;}
.delivery-order-section .coupon-section .content-wrap .input-wrap {width: calc(100% - 20px);display: flex;margin-left: auto;}
.delivery-order-section .coupon-section .content-wrap .input-wrap .apply-btn{background-color: var(--color03);color: #fff;text-transform: uppercase;font-size: 15px;font-weight: 500;padding: 0 14px;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.delivery-order-section .coupon-section .content-wrap .input-wrap .form-control{border-bottom-right-radius: 0;border-top-right-radius: 0;border-color: var(--color03);}
.bill-section .apply-Point-section {border-bottom: 2px solid var(--shades02);padding: 15px 0}
.bill-section .apply-Point-section .custom-radio {background: transparent;border: none;border-radius: 5px;height: 30px;padding: 5px 15px 5px 0px;position: relative;}
.bill-section .apply-Point-section .custom-radio .custom-radio-input {cursor: pointer;height: 100%;left: 0;opacity: 0;position: absolute;top: 0;width: 100%;z-index: 1;}
.bill-section .apply-Point-section .custom-radio .custom-radio-label {color: var(--color03);font-size: 14px;font-weight: 500;line-height: 1;margin: 0;padding: 0 0 0 25px;position: relative;width: 100%;}
.bill-section .apply-Point-section .custom-radio .custom-radio-label .text{color: var(--shades02);line-height: 1;font-size: 16px;font-weight: 600;text-transform: capitalize;display: flex;justify-content: space-between;}
.bill-section .apply-Point-section .custom-radio .custom-radio-label:before {background: var(--shades01);border: 1px solid var(--color03);border-radius: 3px;content: "";height: 18px;position: absolute;left: 0px;top: 50%;transform: translateY(-50%);width: 18px;}
.bill-section .apply-Point-section .custom-radio .custom-radio-label:after {align-items: center;background: var(--color03);content: "\f111";display: none;font-family: Font Awesome 5 Pro;font-weight: 600;justify-content: center;margin: 0 auto;position: absolute;left: 2px;text-align: center;top: 50%;transform: translateY(-50%);width: 14px;}
.bill-section .apply-Point-section .custom-radio .custom-radio-input:checked~.custom-radio-label:after {display: flex;}



.delivery-order-section .delivery-section{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.delivery-order-section .delivery-section .icon{font-size:30px;color:var(--color03)}
.delivery-order-section .delivery-section .text-part .text{font-size:16px;font-weight:600;text-transform:capitalize}
.delivery-order-section .delivery-section .text-part .sub-text{font-size:16px;font-family:'Playfair Display',serif;color:var(--shades07);font-weight:300}
.delivery-order-section .delivery-section .charges{font-size:20px;font-weight:600}

.delivery-order-section .total-price{display:flex;border-bottom:2px solid var(--shades02);padding:20px 0;justify-content:space-between}
.delivery-order-section .total-price .text{font-size:22px;font-weight:600;font-family:'Playfair Display',serif;margin-bottom:5px}
.delivery-order-section .total-price .items{font-size:16px;color:var(--shades07);font-weight:400}
.delivery-order-section .total-price .price{font-size:20px;font-weight:600}

.delivery-order-section .payment-action{margin-top:35px;text-align:center;display:flex;justify-content:center}
.delivery-order-section .payment-action .pay-btn{height:40px;background:var(--color03);border-radius:50px;font-size:20px;font-family:'Playfair Display',serif;color:var(--shades01);font-weight:600;letter-spacing:1px;padding:25px;text-transform:uppercase;display:flex;align-items:center;justify-content:center;width:80%}
.delivery-order-section .delivery-order-part{background: var(--color01);display: flex;padding: 30px;border-radius: 30px;position: relative;width: 100%;max-width: calc(100% - 370px);margin-right: 20px;}
.delivery-order-section .delivery-order-part+ .delivery-order-part{margin-top: 60px;}
.delivery-order-section .delivery-order-part .order-part-delivery{max-width:calc(100% - 350px);width:100%}
.delivery-order-section .delivery-order-part .additional-data{width: 320px;margin-left: 30px;height: max-content;background: var(--color03);color: var(--shades01);padding: 8px;border-radius: 15px;}

.delivery-order-section .inner-option-view{position: relative;}
.delivery-order-section  .inner-option-view .delivery-image{    position: absolute;width: 460px;bottom: -30px;right: -70%;display: none;}
.delivery-order-section  .inner-option-view .delivery-image img{width: 100%;}
.delivery-order-section .inner-option-view.delivery .delivery-image{    position: absolute;width: 460px;bottom: -30px;right: -70%;}
.delivery-order-section .inner-option-view .form-content{}
.delivery-order-section .inner-option-view .form-content .form-inner-title{font-size: 35px;line-height: 1.4;font-weight: 600;font-family: 'Playfair Display', serif;margin-top: 25px;}
.delivery-order-section .inner-option-view .form-content .shipping-item{margin-top: 25px;}
.delivery-order-section .inner-option-view .input-style{margin-top: 25px;}
.delivery-order-section .inner-option-view .input-style .form-label{font-size: 14px;font-weight: 500;margin-bottom: 15px;color: var(--color03);}
.delivery-order-section .inner-option-view .input-style .form-control{border: 1px solid var(--color03);height: 55px;border-radius: 10px;}
.delivery-order-section .inner-option-view .input-style .form-control.comments{height: 120px;}
.delivery-order-section .inner-option-view .form-action{margin: 40px 0;text-align: center;}


.delivery-order-section .inner-option-view  .dropdown{background: var(--shades01);color: var(--shades02);border-radius: 10px;border: 1px solid var(--color03);}
.delivery-order-section .inner-option-view  .dropdown .dropdown-toggle{padding: 0;height: 50px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--shades02);font-size: 14px;font-weight: 500;padding: 0 20px;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-time{padding: 0 10px; margin: 0 0 5px;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-time .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-time .form-control::-webkit-input-placeholder{ color:var(--shades02)!important;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-time .form-control:focus{ border-color:var(--color02);}
.delivery-order-section .inner-option-view  .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.delivery-order-section .inner-option-view  .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.delivery-order-section .inner-option-view  .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}

.delivery-order-section .inner-option-view  .dropdown .dropdown-toggle:after {border: 0;color: #fff;color: var(--shades02);content: "\f078";display: block;font-family: 'Font Awesome\ 5 Pro';font-size: 14px;font-weight: 500;margin: 2px 0 0;opacity: 1;position: absolute;right: 12px;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);transition: all .3s ease;vertical-align: initial;}
.delivery-order-section .inner-option-view  .dropdown.show .dropdown-toggle:after {-webkit-transform: translateY(-50%) rotate(-180deg);transform: translateY(-50%) rotate(-180deg);}

.delivery-order-part .order-part-delivery .title{text-align:center;font-size:45px;line-height:1.5;font-weight:700;font-family:'Playfair Display',serif;color:var(--color03)}
.delivery-order-section .additional-data .title-part{font-size:36px;line-height:1.2;padding:15px;margin-bottom:25px;font-family:'Playfair Display',serif;font-weight:700;color:var(--color01)}
.inner-data-part .delivery-options{display:flex;justify-content:space-between;margin-top:45px}
.inner-data-part .delivery-options .option{background:var(--shades01);padding:20px 30px;text-align:center;color:var(--color03);font-weight:500;font-size:20px;border-radius:12px;width:48%}
.delivery-order-section .inner-option-view .note-section{background:rgb(215 89 47 / 17%);margin-top:25px;padding:15px;border-radius:10px;color:var(--color03);font-weight:500;font-size:16px}
  .inner-data-part .delivery-options .option.active{background: var(--color03);color: var(--shades01);}
.delivery-order-section .additional-data .additional-item{}
.delivery-order-section .additional-data .additional-item .additional-list{}
.delivery-order-section .additional-data .additional-item .additional-list+ .additional-list{margin-top: 30px;}
.delivery-order-section .additional-data .additional-item .additional-list .icon{width:70px;height:70px;background:var(--color01);display:flex;align-items:center;justify-content:center;border-radius:50%;color:var(--color03);font-size:30px;margin:0 auto;margin-bottom:25px}
.delivery-order-section .additional-data .additional-item .additional-list .text-content{text-align: center;}
.delivery-order-section .additional-data .additional-item .additional-list .text-content .text-title{font-size:30px;line-height:1.5;font-family:'Playfair Display',serif;font-weight:700;margin-bottom:10px}
.delivery-order-section .additional-data .additional-item .additional-list .text-content .description{line-height:1.4;color:var(--color01);font-size:14px}
.delivery-order-section .inner-option-view .input-style textarea{resize:none;height:120px!important}
/* .delivery-order-section .inner-option-view .form-submit{background:var(--color03);margin-top:35px;width:100%;text-align:center;color:var(--shades01);font-size:26px;text-transform:capitalize;padding:22px 30px;border-radius:8px;font-weight:500} */
.delivery-order-section .inner-option-view  .btn-submit{background:none;color:var(--shades01);background:var(--color03);margin-top:35px;width:100%;text-align:center;color:var(--shades01);font-size:26px;text-transform:capitalize;padding:22px 30px;border-radius:8px;font-weight:500}
  .delivery-order-section .inner-option-view .input-style textarea:focus{box-shadow: none;}
  .delivery-order-section .inner-option-view .input-style .form-control.comments{height: 120px;}
  .delivery-order-section .inner-option-view .shipping-item .custom-radio{background:var(--shades01);border-radius:10px;height:52px;border:1px solid var(--color03)}
  .delivery-order-section .inner-option-view .shipping-item+ .shipping-item{margin-top:20px}
  .delivery-order-section .inner-option-view .custom-radio{position: relative;margin:0;display: flex; align-items:center; line-height:1;}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-label{position:relative;margin:0;font-size:14px;font-weight:500;line-height:1;width:100%;padding:0 30px 0 0;color:var(--color03);padding:15px 20px 15px 50px;border-radius:10px;display: flex;}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-label:before{content:'';position:absolute;width:28px;height:28px;background:transparent;border:1px solid var(--color03);top:50%;left:10px;transform:translateY(-50%);border-radius:5px}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-label .text{width:100%;color:var(--color03);font-size:18px}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-label .icon{    font-size: 23px;width: 30px;height: 22px;margin-right: 10px;}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 14px;height: 14px;top: 50%;left: 17px;transform: translateY(-50%);font-weight: 600;display: none;align-items: center;justify-content: center;text-align: center;border-radius: 4px;background: var(--color03);margin: 0 auto;}
  .delivery-order-section .additional-data .delivery-image{    position: absolute;width: 460px;bottom: -30px;}
  .delivery-order-section .additional-data .delivery-image img{width: 100%;}
  /* .delivery-order-section .inner-option-view .custom-radio .custom-radio-input:checked ~ .custom-radio-label .text{color: var(--shades01);} */
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:flex;color: var(--color03);}
  .delivery-order-section .inner-option-view .custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: transparent;}
  
  

.delivery-order-section .inner-option-view .shipping-item .custom-checkbox{background:var(--shades01);border-radius:10px;height:52px;border:1px solid var(--color03)}
.delivery-order-section .inner-option-view .shipping-item+ .shipping-item{margin-top:20px}


.delivery-order-section .inner-option-view .custom-checkbox{position: relative;margin:0;display: flex; align-items:center; line-height:1;padding: 0;}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-label{position:relative;margin:0;font-size:14px;font-weight:500;line-height:1;width:100%;padding:0 30px 0 0;color:var(--color03);padding:15px 20px 15px 50px;border-radius:10px}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width:28px;height:28px;background:transparent;border:1px solid var(--color03);top:50%;left:10px;transform:translateY(-50%);border-radius:5px}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-label .text{width:100%;color:var(--color03);font-size:16px}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 14px;height: 14px;top: 50%;left: 17px;transform: translateY(-50%);font-weight: 600;display: none;align-items: center;justify-content: center;text-align: center;border-radius: 4px;background: var(--color03);margin: 0 auto;}

/* .delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label .text{color: var(--shades01);} */
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:flex;color: var(--color03);}
.delivery-order-section .inner-option-view .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: transparent;}

.delivery-order-section .additional-data .shipping-item .custom-checkbox{background:var(--shades01);border-radius:10px;height:52px;border:1px solid var(--color03);padding: 0;}
.delivery-order-section .additional-data .custom-checkbox{position: relative;margin:0;display: flex; align-items:center; line-height:1;}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.delivery-order-section .additional-data  .shipping-item{margin-top: 35px;}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-label{position:relative;margin:0;font-size:14px;font-weight:500;line-height:1;width:100%;padding:0 30px 0 0;color:var(--color03);padding:8px 20px 8px 50px;border-radius:10px}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width:28px;height:28px;background:transparent;border:1px solid var(--color03);top:50%;left:10px;transform:translateY(-50%);border-radius:5px}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-label .text{width:100%;color:var(--color03);font-size:16px}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 14px;height: 14px;top: 50%;left: 17px;transform: translateY(-50%);font-weight: 600;display: none;align-items: center;justify-content: center;text-align: center;border-radius: 4px;background: var(--color03);margin: 0 auto;}

/* .delivery-order-section .additional-data .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label .text{color: var(--shades01);} */
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:flex;color: var(--color03);}
.delivery-order-section .additional-data .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: transparent;}

.delivery-order{display: flex;width: 100%;}


.modal-thanks-order .thanks-content{display: flex;flex-direction: column;justify-content: center;height: 100%;}
.modal-thanks-order .thanks-img{width: 460px;height: 500px;}
.modal-thanks-order .thanks-img img{width: 100%;height: 100%;object-fit: cover;}
.modal-thanks-order .modal-content{border-radius: 23px;border: none;}
.modal-thanks-order .thanks-content .thanks-title{color: var(--shades13);font-weight: 700;font-size: 48px;line-height: 1.5;}
.modal-thanks-order .thanks-content .thanks-title span{color: var(--color03);}
.modal-thanks-order .thanks-content .thanks-text{color: var(--shades02);font-size: 20px;line-height: 1.5;font-weight: 400;}
.modal-thanks-order .thanks-content .skip-btn{background-color: transparent;color: var(--color03);font-weight: 400;font-size: 16px;text-decoration: underline;text-align: left;padding: 0;}
.modal-thanks-order .thanks-content .media-title{color: var(--shades02);font-size: 16px;line-height: 1.3;font-weight: 400;margin: 35px 0 0;}
.modal-thanks-order .thanks-content .media-list{display: flex;align-items: center;margin: 25px 0;}
.modal-thanks-order .thanks-content .media-list .media-icon{margin-right: 20px;height: 30px;width: 30px;}
.modal-thanks-order .thanks-content .media-list .media-icon img{height: 100%;width: 100%;}
.modal-thanks-order .modal-dialog{max-width: 1000px;}
.modal-thanks-order .modal-body{padding: 30px;position: relative;}
.modal-thanks-order .modal-body .cancel-btn{position: absolute;top: 32px;right: 30px;display: flex;align-items: center;justify-content:center;background-color: var(--color03);font-size: 20px;border-radius: 50%;width: 40px;height: 40px;color: var(--shades01);}
.modal-thanks-order .search{margin: 40px 0 25px;}
.modal-backdrop.show{opacity: .82 !important;}
.modal-thanks-order .search .search-input{display:flex;border-radius:6px;overflow:hidden;position:relative}
.modal-thanks-order .search .search-input .form-control{border:1px solid var(--color03);height:52px;min-height:0;padding:6px 16px;padding-left:20px;border-radius:13px 0 0 13px}
.modal-thanks-order .search .search-input .form-control::placeholder{font-size:14px;color:var(--secondaryColor)!important}
.modal-thanks-order .search .search-input .btn-search{border:0;background-color:var(--color03);width: 190px; color:#fff;font-size:14px;font-weight:600;letter-spacing:.5px;transition:all .3s ease;border-radius:0 13px 13px 0}




@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {
  .delivery-order-section .inner-option-view .delivery-image{display: none;}
  .delivery-order-section .delivery-order-part{flex-wrap: wrap;}
  .delivery-order-section .delivery-order-part .order-part-delivery{max-width: 100%;}
  .delivery-order-section .delivery-order-part .additional-data{width: 100%;margin-left: 0;margin-top: 15px;}
}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .delivery-order-section .delivery-order-part{flex-wrap: wrap;}
  .delivery-order-section .delivery-order-part .order-part-delivery{max-width: 100%;}

  .delivery-order-section .delivery-order-part .additional-data{width: 100%;margin-left: unset;margin-top: 15px;}

  .delivery-order-section .inner-option-view .delivery-image{display: none;}
  .delivery-order-section .additional-data .title-part{font-size: 22px;}
  .delivery-order-section .additional-data .additional-item .additional-list .text-content .text-title{font-size: 18px;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

} 