.page-banner-section .back-action .back-btn{display: none;font-size: 16px;font-weight: 500;color: var(--color03); }
.page-banner-section .back-action .back-btn .icon{display: flex;align-items: center;}
.page-banner-section .back-action .back-btn .icon i{font-size: 30px;padding-right: 10px;}
.blog-details-section{padding: 60px 0;}
.blog-details-section .blog-details-image{width: 100%;}
.blog-details-section .blog-details-image .img-wrap{width: 100%;height: 470px;border-radius: 40px 40px 0 0;overflow: hidden;}
.blog-details-image .img-wrap img{width: 100%;height: 100%;object-fit: cover;}
.blog-content{background: var(--color03);border-radius: 0 0 40px 40px;padding: 35px;}
.blog-title{font-size: 35px;font-weight: 600;color: var(--shades01);margin-bottom: 20px; line-height: 1.2;}
.blog-time{display: flex;align-items: center;}
.blog-time .icon{font-size: 14px;color: var(--shades01);margin-right: 10px;}
.blog-time .text{font-size: 14px;color: var(--shades01);font-weight: 400;}
.blog-data{display: flex;margin: 10px 0 25px;align-items: center;flex-wrap: wrap;}
.blog-data .date{display: flex;}
.blog-data .date .icon{color: var(--color02);margin-right: 8px;font-size: 18px;}
.blog-data .date .text{font-size: 18px;color: var(--shades02);font-weight: 400;}
.blog-data .author{display: flex;margin-left: 8px;}
.blog-data .author .icon{color: var(--color02);margin-right: 8px;font-size: 18px;}
.blog-data .author .text{font-size: 18px;color: var(--shades02);text-transform: capitalize;font-weight: 400;}
.blog-data .tag{margin-left: 8px;}
.blog-data .tag .tag-text{background: var(--color01);color: var(--color03);font-weight: 500;padding: 0px 14px;height: 44px;display: flex;align-items: center;justify-content: center;border-radius: 8px;font-size: 18px;}

.blog-details-section{}
.blog-details-section .blog-details-content{padding-bottom: 60px;border-bottom: 1px solid var(--shades06);    font-size: 13px;
  font-weight: 400;}
.blog-details-section .blog-details-content .description{font-size: 16px;line-height: 1.5;font-weight: 400;}
.blog-details-section .blog-details-content .description p{}
.blog-details-section .blog-details-content .description-points{display: flex;justify-content: space-between;padding: 25px 0;}
.blog-details-content .description-points .number-points{width: 50%;}
.blog-details-content .description-points .number-points.icon{margin-left: 35px;}
.blog-details-content .description-points .number-points .list{padding-left: 18px;}
.blog-details-content .description-points .number-points .list .list-item{font-size: 16px;line-height: 1.5;font-weight: 400;list-style: decimal;}
.blog-details-content .description-points .number-points.icon .list .list-item{list-style: none;}
.blog-details-content .description-points .number-points.icon .list {padding-left: 0;}
.blog-details-content .description-points .number-points.icon .list .list-item:before{font-size: 16px;content: '\f0a9'; font-family: "Font Awesome 6 Pro";color: var(--color03);margin-right: 8px;}
.blog-details-content .description-points .number-points .list .list-item+ .list-item{margin-top: 10px;}

.description-image{display: flex;justify-content: space-between;padding:  0 0 25px;}
.description-image .left-img{width: 50%;overflow: hidden;border-radius: 20px;height: 280px;}
.description-image .left-img img{width: 100%;height: 100%;object-fit: cover;}
.description-image .right-img{width: 50%;overflow: hidden;border-radius: 20px;height: 280px;margin-left: 35px;}
.description-image .right-img img{width: 100%;height: 100%;object-fit: cover;}

.comments-section{}
.comments-section .comment-post{padding-top: 30px;}
.comments-section .comment-part{box-shadow: 0px 4px 44px 0px #00000040;border-radius: 30px;padding: 30px;}
.comment-post .title{font-family: 'Playfair Display', serif;color: var(--color03);font-size: 30px;text-align: center;font-weight: 700;margin: 0;line-height: 1.5;text-transform: uppercase;}
.comment-post .comment-list{    width: 90%;margin: 0 auto;display: block;padding:60px 0 40px;}
.comment-post .comment-list .comment-item{    display: flex;align-items: center;}
.comment-post .comment-list .comment-item+ .comment-item{margin-top: 35px;}
.comment-list .comment-item .comment-image{width: 75px;height: 75px;overflow: hidden;border-radius: 50%;}
.comment-list .comment-item .comment-image img{width: 100%;height: 100%;}
.comment{width: 100%;max-width: calc(100% - 95px);margin-left: 20px;}
.comment .comment-author{display: flex;justify-content: space-between;}
.comment .comment-author .author-info{    display: flex;align-items: center;}
.comment .comment-author .author-info .name{font-size: 14px;font-weight: 500;margin-right: 25px;}
.comment .comment-author .author-info .date{    font-size: 13px;color: var(--shades07);font-weight: 400;}
.comment .comment-author .reply-text{}
.comment .comment-author .reply-text .reply{    font-size: 13px;color: var(--shades07);font-weight: 400;}
.comment .reply-comment{margin-top: 10px;}
.comment .reply-comment .text{font-size: 13px;font-weight: 400;line-height: 1.4;}
.comment .reply-comment .text p{margin-bottom: 0;}

.comments-section{}
.comments-section{}
.comment-add {    width: 70%;margin: 0 auto;display: block;padding:60px 0 40px;}
.comment-add .title{font-family: 'Playfair Display', serif;color: var(--color03);font-size: 30px;text-align: center;font-weight: 700;margin: 0;line-height: 1.5;text-transform: uppercase;}
.comment-add .form-content{margin-top: 25px;}
.comment-add .form-content .input-style{margin-top: 20px;}
.comment-add .form-content .input-style+ .input-style{margin-top: 25px;}
.comment-add .form-content .input-style .form-control{background: var(--color01);border: none;height: 60px;}
.comment-add .form-content .input-style .form-control.message{resize: none;height: 145px;padding: 12px;}
.comment-add .form-content .form-action{margin-top: 60px;display: flex;justify-content: center;}
.comment-add .form-content .form-action .form-btn{width: 200px;background: var(--color03);color: var(--shades01);font-size: 20px;padding: 15px;border-radius: 10px;font-family: 'Inter', sans-serif;text-transform: uppercase;}

.related-blogs-slider{}
.related-blogs-slider .swiper-wrapper{margin-bottom: 60px;}
.related-blogs-slider .related-slider{padding: 0 55px;}
.related-blogs-slider .swiper-button-prev{font-size: 20px;color: var(--color03);}
.related-blogs-slider .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
.related-blogs-slider .swiper-button-prev:after{display:none;}
.related-blogs-slider .swiper-button-next{font-size: 20px;color: var(--color03);}
.related-blogs-slider .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
.related-blogs-slider .swiper-button-next:after{display:none;}
.related-blogs-slider .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
  .blog-details-section .blog-details-image .img-wrap{    height: 360px;}
  .blog-title{font-size: 25px;}
  .comment-post .comment-list{width: 100%;}
}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
  .comment-add{width: 100%;padding: 30px 0;}
  .comments-section .comment-post{padding-top: 0;}
  .comment-post .comment-list{padding: 25px 0;}

  .related-section .swiper-button-prev{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
  .related-section .swiper-button-prev:before{content:'\e900';font-family:'icomoon';}
  .related-section .swiper-button-prev:after{display:none;}
  .related-section .swiper-button-next{font-size: 20px;color: var(--color03);top: unset;bottom: 0;}
  .related-section .swiper-button-next:before{content:'\e901';font-family:'icomoon';}
  .related-section .swiper-button-next:after{display:none;}
  .related-section .swiper-pagination .swiper-pagination-bullet{ width: 12px; height: 12px; margin: 0 3px !important; background-color: var(--color02);}
  .related-section .swiper-pagination.swiper-pagination-bullets{z-index: 4;display: block;}
  .related-blogs-slider .related-slider{padding: 0;}
  /* .page-banner-section .banner-section{background: transparent !important;} */
  .page-banner-section .back-action .back-btn{display: block;}
  .page-banner-section .back-action{padding-left: 30px;}
  .page-banner-section.blog-details .banner-section{display: none !important;}
  .blog-details-section{padding: 0 0 30px;}
  .blog-details-section .blog-details-image .img-wrap{height: 200px;}
  .blog-content{padding: 15px;}
  .blog-title{margin-bottom: 10px;}
  .blog-data .date .text{font-size: 14px;}
  .blog-data .author .text{font-size: 14px;}
  .blog-data .tag .tag-text{font-size: 15px;}
  .comments-section .comment-part{padding: 20px;}
	.comment{margin-left: 10px;}
  .comment-add .form-content .form-action .form-btn{font-size: 15px;}
  .comment-add .form-content .form-action{margin-top: 40px;}
  .pagenation-section{padding: 30px 0;}
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}